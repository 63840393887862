import React, { useEffect, useState } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Chart from "react-google-charts";
import { toast, ToastContainer } from "react-toastify";


const colors = [
  '#ff6384',
  '#36a2eb',
  '#ffce56',
  '#4bc0c0',
  '#9966ff',
  '#ff9f40',
  '#ff6384',
  '#36a2eb',
  '#ffce56',
  '#4bc0c0'
]

const Age = (formData) => {
  const [patientArr, setPatientArr] = useState([]);
  const [claimArr, setClaimArr] = useState([]);
  let [z, setPArr] = useState([]);
  let [x, setXArr] = useState([]);
  useEffect(() => {
    console.log(formData)
    axios
      .post(`/api/graph-age`, formData)
      .then(res => {
        let patientDataObj = []
        patientDataObj.push(['Age', 'Sales', { role: 'style' }])
        let claimDataObj = []
        claimDataObj.push(['Age', 'Count', { role: 'style' }])
        let records = res.data.records;
        records.sort((a, b) => (a.age > b.age) ? 1 : ((b.age > a.age) ? -1 : 0))
        records.map((each, i) => {
          if (each.age !== '') {
            patientDataObj.push([each.age, each.total_revenue, colors[i]])
            claimDataObj.push([each.age, each.count, colors[i]])
          }
        })
        var z = 0
        for (let k = 1; k < patientDataObj.length; k++) {
          z += (patientDataObj[k])[1]
         
        }
        z = new Intl.NumberFormat().format(z)
        var x = 0
        for (let p = 1; p < claimDataObj.length; p++) {
          x += (claimDataObj[p])[1]
        }
        x = new Intl.NumberFormat().format(x)
        // console.log(z)
        setPatientArr(patientDataObj);
        setClaimArr(claimDataObj);
        setPArr(z);
        setXArr(x);
      })
      .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
  }, [formData]);

  return (
    // console.log(x),
    <div className="row px-2 age-card">
      <Container>
        <Row>
          {patientArr.length > 1 && <Col md={6}>
            <div className="card shadow-lg">
              <div className="card-body">
                <h5 className="left">Rx Sale by Age Group</h5>
                <h5 className="right">Total: ${z} </h5>


                <div>
                  <Chart
                    width={'500px'}
                    height={'300px'}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={patientArr}
                    options={{
                      chart: {
                        title: 'Rx Sales by Age Group',

                      },
                      bubble: {
                        textStyle: {
                          fontSize: 8,
                        },
                      },
                      legend: { position: 'none' },
                      vAxis: { format: "currency" },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    formatters={[
                      {
                        type: 'NumberFormat',
                        column: 1,
                        options: {
                          prefix: '$',
                          negativeColor: 'red',
                          negativeParens: true,
                        },
                      },
                    ]}
                  />
                </div>
                {/* <h5>SF</h5> */}
              </div>
            </div>
          </Col>}
          {claimArr.length > 1 && <Col md={6}>
            <div className="card shadow-lg">
              <div className="card-body">
                <h5 className="left">Rx Claim by Age Group</h5>
                <h5 className="right">Total: {x} </h5>
                <div>
                  <Chart
                    width={'500px'}
                    height={'300px'}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={claimArr}
                    options={{
                      // Material design options
                      chart: {
                        title: 'Rx Count by Age Group',
                      },
                      bubble: {
                        textStyle: {
                          fontSize: 8,
                        },
                      },
                      legend: { position: 'none' },
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>

              </div>
            </div>
          </Col>}
        </Row>
      </Container>
      <ToastContainer/>
    </div>
  );
}
export default Age;
