import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class MedDrugLookup extends React.Component {

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://widget.ehealthinsurance.com/tools/scripts/ehth-widget.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    const iFrame = '<div class="ehth-drug-widget" data-alliance-id="psg224270001"></div><script src="https://widget.ehealthinsurance.com/tools/scripts/ehth-widget.js"></script>'
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <h2 className="title">Search Plans for Formulary Products</h2>
                <p className="help-text font-weight-bold h6">Search by medication(s) and view coverage for Part D plans.</p>
                <iframe style={{ borderWidth: 0 }} title={"badge"} srcDoc={iFrame} />

              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default MedDrugLookup;