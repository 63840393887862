import React from 'react'
import classnames from "classnames";
import isEmpty from 'is-empty';
import axios from 'axios';
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import CreditCardInput from 'react-credit-card-input';
import PulseLoader from "react-spinners/PulseLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AddCredits extends React.Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            cardNumber: "",
            expiry: "",
            cvv: "",
            _id: "",
            current_points: 0,
            records: [],
            cardError: true,
            loading: false,
            name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            points: "",
            amount: 0
        };
    }

    componentDidMount() {

    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSave = e => {
        let { errors } = this.state
        e.preventDefault();
        if (this.state.name === '') {
            errors.name = "This field is required"
        } else {
            delete errors.name
        }
        if (this.state.street === '') {
            errors.street = "This field is required"
        } else {
            delete errors.street
        }
        if (this.state.city === '') {
            errors.city = "This field is required"
        } else {
            delete errors.city
        }
        if (this.state.state === '') {
            errors.state = "This field is required"
        } else {
            delete errors.state
        }
        if (this.state.zip === '') {
            errors.zip = "This field is required"
        } else {
            delete errors.zip
        }

        if (this.state.cardError === false && isEmpty(errors)) {
            this.setState({
                loading: true
            })
            const newCard = {
                email: this.props.auth.user.final_user,
                cardNumber: this.state.cardNumber,
                expiry: this.state.expiry,
                cvc: this.state.cvc,
                points: this.state.points,
                name: this.state.name,
                street: this.state.street,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                amount: this.state.points ? (parseInt(this.state.points) / 10).toFixed(2) : 0
            };
            axios
                .post("/api/add-credit", newCard)
                .then(res => {
                    this.props.sendPoints(res.data.points);
                    $('#add-credits').modal('hide');
                    this.setState({
                        loading: false,
                        cardNumber: "",
                        expiry: "",
                        cvv: "",
                        loading: false,
                        name: "",
                        street: "",
                        city: "",
                        state: "",
                        zip: "",
                        points: "",
                        amount: 0
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                })
        } else {
            this.setState({ errors })
        }
    };

    handleCardNumberChange = (e) => {
        this.setState({
            cardNumber: e.target.value,
            cardError: false
        });
    };

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value,
            cardError: false
        });
    };

    handleCardCVCChange = (e) => {
        this.setState({
            cvc: e.target.value,
            cardError: false
        });
    };

    setError = () => {
        this.setState({
            cardError: true
        })
    }

    render() {
        const { errors } = this.state;
        const amount = this.state.points ? (parseInt(this.state.points) / 10).toFixed(2) : ""
        return (
            <div>
                <div className="modal fade" id="add-credits" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Credit</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUpload} id="add-points">
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <h5 htmlFor="name">Enter number of Credits</h5>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.points}
                                                id="points"
                                                type="text"
                                                error={errors.points}
                                                className={classnames("form-control", {
                                                    invalid: errors.points
                                                })} />
                                            <span className="text-danger">{errors.points}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="cal-price" htmlFor="name">Price: {amount ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount) : "$0"}</h5>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <h5 htmlFor="name">Card Details</h5>
                                        </div>
                                        <div className="col-md-9">
                                            <CreditCardInput
                                                onError={() => this.setError()}
                                                cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
                                                cardExpiryInputProps={{ value: this.state.expiry, onChange: this.handleCardExpiryChange }}
                                                cardCVCInputProps={{ value: this.state.cvc, onChange: this.handleCardCVCChange }}
                                                fieldClassName="input"
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="row mt-12">
                                        <div className="col-md-12">
                                            <h5 htmlFor="name">Billing Address</h5>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="street">Address Line 1</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.street}
                                                id="street"
                                                type="text"
                                                error={errors.street}
                                                className={classnames("form-control", {
                                                    invalid: errors.street
                                                })} />
                                            <span className="text-danger">{errors.street}</span>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <label htmlFor="city">City</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.city}
                                                id="city"
                                                type="text"
                                                error={errors.city}
                                                className={classnames("form-control", {
                                                    invalid: errors.city
                                                })} />
                                            <span className="text-danger">{errors.city}</span>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <label htmlFor="state">State</label>
                                            <select name="state" onChange={this.onChange} className="form-control" id="state">
                                                <option value="" selected="">Please Select</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AS">American Samoa</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="AA">Armed Forces America</option>
                                                <option value="AE">Armed Forces Europe /Canada / Middle East / Africa</option>
                                                <option value="AP">Armed Forces Pacific</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="FM">Federated States of Micronesia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="GU">Guam</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MP">Mariana Islands, Northern</option>
                                                <option value="MH">Marshall Islands</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VI">Virgin islands</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <label htmsuperadminlFor="zip">Zip</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.zip}
                                                id="zip"
                                                type="text"
                                                error={errors.zip}
                                                className={classnames("form-control", {
                                                    invalid: errors.zip
                                                })} />
                                            <span className="text-danger">{errors.zip}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button
                                    form="add-points"
                                    type="submit"
                                    onClick={this.onSave}
                                    className="btn btn-primary">
                                    Pay Now
                                </button>
                            </div>
                            <PulseLoader color="#e2e2e2" loading={this.state.loading} css={override} size={150} />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(AddCredits));
