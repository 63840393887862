import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import logo from "../../utils/tb.png";
import eigth from "../../utils/8.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faHandshake, faPiggyBank, faPrescription, faTag } from "@fortawesome/free-solid-svg-icons";

class Tabularx extends React.Component {

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} />
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-primary h4">Drug Diversion Software</h5>
                    <p className="card-text" style={{ fontSize: "18px" }}>Next-level drug diversion software solution that monitors the entire supply chain of the pharmacy, starting with the order and ending with the consumption or expiration of the drugs. Protect your business against DEA or regulatory oversight and inspections.</p>
                    <br></br>
                    <div className="row">

                      <div className="col-md-8">
                        <ul className="list-group" style={{ marginLeft: "50px" }}>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faHandshake} />{'  '}Perpetual Inventory System</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPrescription} />{'  '}Complete Reporting and monitoring</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faDollarSign} />{'  '}Clinical Insight including MME</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faTag} />{'  '}Safe , Secure, Accurate, and intuitive</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}Quality and Compliance Support</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}Email alerts and much more</li>
                        </ul>
                      </div>
                      <div className="col-md-4" style={{ "textAlign": "center", marginTop: "80px" }}><Link className="btn btn-primary" to="/support">Be the First to Know</Link></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12" style={{ "textAlign": "center" }}>
                        <img src={eigth} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default Tabularx;