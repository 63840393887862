import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from 'is-empty';



import 'react-toastify/dist/ReactToastify.css';

class DoctorAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address_city: "",
            address_fax: "",
            enumeration_date: "",
            updated_date: "",
            address_first_line: "",
            address_postal: "",
            address_second_line: "",
            address_state: "",
            address_telephone: "",
            entity_type_code: "",
            first_name: "",
            last_name: "",
            license_number: "",
            license_number_state: "",
            middle_name: "",
            name_prefix: "",
            name_suffix: "",
            npi: "",
            practice_fax: "",
            practice_postal: "",
            practice_telephone: "",
            taxonomy: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-doctor-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    setFromDate = (date) => {
        this.setState({ enumeration_date: date });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onDoctorAdd = e => {
        e.preventDefault();
        let {errors} = this.state
        if(this.state.address_city===''){
            errors.address_city = "This field is required"
        }else{
            delete errors.address_city
        }
        if(this.state.address_first_line===''){
            errors.address_first_line = "This field is required"
        }else{
            delete errors.address_first_line
        }
        if(this.state.address_postal===''){
            errors.address_postal = "This field is required"
        }else{
            delete errors.address_postal
        }
        if(this.state.address_second_line===''){
            errors.address_second_line = "This field is required"
        }else{
            delete errors.address_second_line
        }
        if(this.state.address_state===''){
            errors.address_state = "This field is required"
        }else{
            delete errors.address_state
        }
        if(this.state.address_telephone===''){
            errors.address_telephone = "This field is required"
        }else{
            delete errors.address_telephone
        }
        if(this.state.first_name===''){
            errors.first_name = "This field is required"
        }else{
            delete errors.first_name
        }
        if(this.state.last_name===''){
            errors.last_name = "This field is required"
        }else{
            delete errors.last_name
        }
        if(this.state.license_number===''){
            errors.license_number = "This field is required"
        }else{
            delete errors.license_number
        }
        if(this.state.license_number_state===''){
            errors.license_number_state = "This field is required"
        }else{
            delete errors.license_number_state
        }
        if(this.state.middle_name===''){
            errors.middle_name = "This field is required"
        }else{
            delete errors.middle_name
        }
        if(this.state.npi===''){
            errors.npi = "This field is required"
        }else{
            delete errors.npi
        }if(this.state.taxonomy===''){
            errors.taxonomy = "This field is required"
        }else{
            delete errors.taxonomy
        }
        if(isEmpty(errors)){
        const newDoctor = {
            enumeration_date: (this.state.enumeration_date),
            // updated_date: new Date(this.state.updated_date),
            address_city: this.state.address_city,
            address_fax: this.state.address_fax,
            address_first_line: this.state.address_first_line,
            address_postal: this.state.address_postal,
            address_second_line: this.state.address_second_line,
            address_state: this.state.address_state,
            address_telephone: this.state.address_telephone,
            entity_type_code: this.state.entity_type_code,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            license_number: this.state.license_number,
            license_number_state: this.state.license_number_state,
            middle_name: this.state.middle_name,
            name_prefix: this.state.name_prefix,
            name_suffix: this.state.name_suffix,
            npi: this.state.npi,
            practice_fax: this.state.practice_fax,
            practice_postal: this.state.practice_postal,
            practice_telephone: this.state.practice_telephone,
            taxonomy: this.state.taxonomy,
        };
        axios.post('/api/doctor-add', newDoctor)
            .then((response) => {
                this.props.getData();
                $('#add-doctor-modal').modal('hide');
            }, (error) => {
                console.log(error);
            });
        }else{
            this.setState({errors})
        }

        //this.props.addUser(newUser, this.props.history);
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-doctor-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add NPI</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onDoctorAdd} id="add-doctor">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="npi">NPI</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.npi}
                                                id="npi"
                                                type="text"
                                                error={errors.npi}
                                                className={classnames("form-control", {
                                                    invalid: errors.npi
                                                })} />
                                            <span className="text-danger">{errors.npi}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="entity_type_code">Entity Type Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select
                                                onChange={this.onChange}
                                                value={this.state.entity_type_code}
                                                error={errors.entity_type_code}
                                                id="entity_type_code"
                                                className={classnames("form-control", {
                                                    invalid: errors.entity_type_code
                                                })}
                                            >
                                                <option value="1">Doctor/Dentist</option>
                                                <option value="2">Pharmacy</option>
                                            </select>
                                            <span className="text-danger">{errors.entity_type_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="enumeration_date">Enumeration Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <div class="form-group col-md-9"> */}


                                            <DatePicker selected={this.state.enumeration_date} onChange={(date) => this.setFromDate(date)} className="form-control" />
                                            {/* </div> */}
                                            <span className="text-danger">{errors.enumeration_date}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="updated_date">Updated Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.updated_date}
                                                error={errors.updated_date}
                                                id="updated_date"
                                                type="datetime-local"
                                                className={classnames("form-control", {
                                                    invalid: errors.updated_date
                                                })}
                                            />
                                            <span className="text-danger">{errors.updated_date}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_name">First Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.first_name}
                                                error={errors.first_name}
                                                id="first_name"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.first_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="middle_name">Middle Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.middle_name}
                                                id="middle_name"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.middle_name
                                                })}
                                            />
                                            <span className="text-danger">{errors.middle_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="last_name">Last Name (Legal Name)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.last_name}
                                                id="last_name"
                                                type="text"
                                                error={errors.last_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.last_name
                                                })} />
                                            <span className="text-danger">{errors.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name_prefix">Name Prefix Text</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name_prefix}
                                                id="name_prefix"
                                                type="text"
                                                error={errors.name_prefix}
                                                className={classnames("form-control", {
                                                    invalid: errors.name_prefix
                                                })} />
                                            <span className="text-danger">{errors.name_prefix}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name_suffix">Name Suffix Text</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name_suffix}
                                                id="name_suffix"
                                                type="text"
                                                error={errors.upline}
                                                className={classnames("form-control", {
                                                    invalid: errors.name_suffix
                                                })} />
                                            <span className="text-danger">{errors.name_suffix}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_first_line">First Line Business Mailing Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_first_line}
                                                id="address_first_line"
                                                type="text"
                                                error={errors.address_first_line}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_first_line
                                                })} />
                                            <span className="text-danger">{errors.address_first_line}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_second_line">Second Line Business Mailing Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_second_line}
                                                id="address_second_line"
                                                type="text"
                                                error={errors.address_second_line}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_second_line
                                                })} />
                                            <span className="text-danger">{errors.address_second_line}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_city">Business Mailing Address City Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_city}
                                                id="address_city"
                                                type="text"
                                                error={errors.address_city}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_city
                                                })} />
                                            <span className="text-danger">{errors.address_city}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_state">Business Mailing Address State Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_state}
                                                id="address_state"
                                                type="text"
                                                error={errors.street}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_state
                                                })} />
                                            <span className="text-danger">{errors.address_state}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_postal"> Business Mailing Address Postal Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_postal}
                                                id="address_postal"
                                                type="text"
                                                error={errors.address_postal}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_postal
                                                })} />
                                            <span className="text-danger">{errors.address_postal}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_telephone"> Business Mailing Address Telephone Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_telephone}
                                                id="address_telephone"
                                                type="text"
                                                error={errors.state}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_telephone
                                                })} />
                                            <span className="text-danger">{errors.address_telephone}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="address_fax"> Business Mailing Address Fax Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address_fax}
                                                id="address_fax"
                                                type="text"
                                                error={errors.address_fax}
                                                className={classnames("form-control", {
                                                    invalid: errors.address_fax
                                                })} />
                                            <span className="text-danger">{errors.address_fax}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="practice_postal"> Business Practice Location Address Postal Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.practice_postal}
                                                id="practice_postal"
                                                type="text"
                                                error={errors.practice_postal}
                                                className={classnames("form-control", {
                                                    invalid: errors.practice_postal
                                                })} />
                                            <span className="text-danger">{errors.practice_postal}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="practice_telephone"> Business Practice Location Address Telephone Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.practice_telephone}
                                                id="practice_telephone"
                                                type="text"
                                                error={errors.practice_telephone}
                                                className={classnames("form-control", {
                                                    invalid: errors.practice_telephone
                                                })} />
                                            <span className="text-danger">{errors.practice_telephone}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="taxonomy">Healthcare Taxonomy Code_1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.taxonomy}
                                                id="taxonomy"
                                                type="text"
                                                error={errors.taxonomy}
                                                className={classnames("form-control", {
                                                    invalid: errors.taxonomy
                                                })} />
                                            <span className="text-danger">{errors.taxonomy}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="license_number"> License Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.license_number}
                                                id="license_number"
                                                type="text"
                                                error={errors.license_number}
                                                className={classnames("form-control", {
                                                    invalid: errors.license_number
                                                })} />
                                            <span className="text-danger">{errors.license_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="license_number_state"> License Number State Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.license_number_state}
                                                id="license_number_state"
                                                type="text"
                                                error={errors.license_number_state}
                                                className={classnames("form-control", {
                                                    invalid: errors.license_number_state
                                                })} />
                                            <span className="text-danger">{errors.license_number_state}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="practice_fax">Business Practice Location Address Fax Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.practice_fax}
                                                id="practice_fax"
                                                type="text"
                                                error={errors.practice_fax}
                                                className={classnames("form-control", {
                                                    invalid: errors.practice_fax
                                                })} />
                                            <span className="text-danger">{errors.practice_fax}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-doctor"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-doctor-modal"
                                    className="btn btn-primary">
                                    Add NPI
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DoctorAddModal;
