import React from 'react'
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import doorstep from "../../utils/DoorstepRx.png";
import { refreshUser } from "./../../actions/authActions";
class Doorstep extends React.Component {

    constructor() {
        super();
        this.state = {
            purchase_date: "",
            expiration_date: "",
            ndc: "",
            drug_name: "",
            package_size: "",
            qty_received: "",
            form_number: "",
            invoice_number: "",
            supplier: "",
            notes: "",
            lot: "",
            dea: "",
            errors: {},
            errs: {},
            invoiceimage: "",
        };
    }

    startTrail = () => {
        const newUser = {
            id: this.props.auth.user.id,
            doorstep_trial: true,
        };
        axios.post("/api/user-update", newUser).then(res => {
            this.props.refreshUser({ id: this.props.auth.user.id }, "doorstep")
            $('#doorstep-modal').modal('hide');

        }).catch(err =>
            console.log(err)
        )
    }

    render() {
        const { errors } = this.state;
        const { errs } = this.state;

        return (
            <div>
                <div className="modal fade" id="doorstep-modal" data-reset="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header" style={{ display: "block" }}>
                                <div style={{float:"left"}}>
                                    <img width="250px" src={doorstep} />
                                </div>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h3 className="modal-title" style={{clear:"both"}}>The Smart Delivery Technology for Today's Busy Pharmacy</h3>
                                
                            </div>
                            <div className="modal-body center">
                                <video width="80%" height="80%" controls>
                                    <source src="https://rxreachanalytis.s3.amazonaws.com/Final+Video+Doorstep+Rx+Positive.m4v" type="video/mp4" />
                                    <source src="https://rxreachanalytis.s3.amazonaws.com/Final+Video+Doorstep+Rx+Positive.m4v" type="video/ogg" />
                                </video>
                                <br></br>
                            </div>
                            <div className="modal-footer">
                            <button
                                    onClick={this.startTrail}
                                    type="button"
                                    className="btn btn-success btn-lg">
                                    Start your free 14 days Trial
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    { refreshUser }
)(Doorstep);
