import React from "react";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_PHARMACY,
  SET_GROUPS,
} from "./types";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const registerUser =
  ({ userData, history, hideLoading }) =>
  (dispatch) => {
    axios
      .post("/api/register", userData)
      .then((res) => {
        hideLoading();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="mt-2 text-primary">Message</h1>
                <p className="help-text font-weight-bold h6">
                  your account is created, please click to login
                </p>
                <button
                  className="btn btn-primary btn-sm registerbtn"
                  onClick={() => {
                    onClose();
                    history.push("/login");
                  }}
                >
                  Login Now
                </button>
              </div>
            );
          },
        });
      })

      .catch((err) => {
        hideLoading();
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const loginUser = (token) => (dispatch) => {
  localStorage.setItem("jwtToken", token);
  setAuthToken(token);
  const decoded = jwt_decode(token);
  dispatch(setCurrentUser(decoded));
  dispatch(setGroups());
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const setPharmacies = (pharmacy) => (dispatch) => {
  dispatch({
    type: SET_PHARMACY,
    payload: pharmacy,
  });
};

export const setGroups = () => (dispatch) => {
  axios
    .post("/api/group-data")
    .then((res) => {
      if (res.data.records !== undefined) {
        dispatch({
          type: SET_GROUPS,
          payload: res.data.records,
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          localStorage.removeItem("jwtToken");
          window.location.href = "./login?multiple=true";
        }
      }
    });
};

export const logoutUser = (history) => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  // history.push("/login")
  window.location.href = "./login";
};

export const refreshUser = (userData, type) => (dispatch) => {
  axios
    .post("/api/refreshuser", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      if (type === "tabula") {
        window.location.href =
          "https://tabula.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      } else {
        window.location.href =
          "https://doorstep.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
