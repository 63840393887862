import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';

import { connect } from "react-redux";
import axios from "axios";
import ReactAutocomplete from "react-autocomplete"
import DatePicker from "react-datepicker";
import { setPharmacies } from "../../actions/authActions";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import PulseLoader from "react-spinners/PulseLoader";
import Chart from "react-google-charts";
import moment from "moment"
import dashboard from "../../utils/dashboard.png"
import { Link } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";

class DrugReport extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "NDC",
                className: "_id",
                align: "left",
                sortable: true,
            },
            {
                key: "mdeication_label",
                text: "Medication",
                className: "mdeication_label",
                align: "left",
                sortable: true,
            },
            {
                key: "total_rx",
                text: "Claims",
                className: "total_rx",
                align: "left",
                sortable: true,
            },
            {
                key: "total_revenue",
                text: "Total Revenue($)",
                className: "total_revenue",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "website",
                text: "Coupon",
                className: "website",
                align: "left",
                sortable: true,
                coupon: true,
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "ndc", order: "desc" }
            },
            currentRecord: {
            },
            mdeication_label: undefined,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            value: undefined,
            loading: false,
            allDrugName: [],
            classTwo: 0,
            classAbove: 0,
            per: undefined,
            per2: undefined,
            recordsGraph: [],
            deaFilter: undefined,
            _id: undefined,
            rx_group_associated: undefined,

        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
        // this.getDrugFilter()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.deaFilter !== '' && { dea: this.state.deaFilter }),
            ...(this.state._id !== '' && { _id: this.state._id }),
            ...(this.state.mdeication_label !== '' && { mdeication_label: this.state.mdeication_label }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated })
        }
        if (this.state.value !== undefined && this.state.value !== "") {
            let selectedNpi = this.state.allDrugName.find(o => o.name === this.state.value);
            if (selectedNpi !== undefined) {
                filterData.search.npi = selectedNpi.id
            } else {
                filterData.search.npi = this.state.value
            }
        }
        axios
            .post("/api/drug-dispensing", filterData)
            .then(res => {
                let result = []
                let recordsGraph = []
                let drugCoupon = {}
                for (let i = 0; i < res.data.drugs.length; i++) {
                    let each = res.data.drugs[i]
                    if (each.website) {
                        drugCoupon[each.ndc] = each.website
                    }
                }
                res.data.records.sort((a, b) => Number(b.total_revenue) - Number(a.total_revenue));
                recordsGraph.push(['ID', 'Claims', 'Member Paid', "Name", 'Claims'])
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each._id != "") {
                        each.total_revenue = each.total_revenue ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.total_revenue) : ""
                        if (drugCoupon[each._id]) {
                            each.website = drugCoupon[each._id]
                        }
                        result.push(each)
                        if (i < 500) {

                            recordsGraph.push([
                                "",
                                each.total_rx,
                                Number(each.total_revenue.replace(/[^0-9.-]+/g, "")),
                                each.mdeication_label,
                                each.total_rx,
                            ])
                        }
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false,
                    recordsGraph: recordsGraph
                })

            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    getDrugFilter = () => {
        axios
            .post("/api/drug-filter",)
            .then(res => {
                let allDrugName = []
                for (let i = 0; i < res.data.drugs.length; i++) {
                    let each = res.data.drugs[i]
                    if (each.name !== undefined) {
                        allDrugName.push({ "id": each.ndc, "name": each.name })
                    }

                }
                this.setState({ allDrugName })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    changeText = (value) => {
    }

    onSearch = e => {
        this.setState({
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            }
        }, () => this.getData());
    }

    reset = () => {
        this.setState({
            value: undefined,
            from_date: undefined,
            to_date: undefined,
            deaFilter: undefined,
            rx_group_associated: undefined,
            mdeication_label: '',
            _id: '',
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData());
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    onChangeDea = (e) => {
        this.setState({ deaFilter: e.target.value });
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "ndc": "NDC",
            "claims": "Claims",
            "retail": "Total Revenue($)",
            "mdeication_label": "Medication Name",
            // "class": "DEA Class",
            // "telephone": "Telephone"
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            record.total_revenue = '"' + record.total_revenue + '"'

            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        let associatedRecords = []
        for (let i = 0; i < this.props.groups.length; i++) {
            let each = this.props.groups[i]
            if (each.rx_group_associated !== undefined) {
                associatedRecords = [...associatedRecords, ...(each.rx_group_associated.split(","))]
            }
        }
        let { rx_group_associated } = this.state
        return (
            <div className="d-flex" id="wrapper">
                {(this.props.auth.user.bonaRx || this.props.auth.user.superadmin) && (
                    <>
                        {this.state.loading &&
                            <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    </>
                )}
                <div id="page-content-wrapper">
                    {this.props.auth.user.superadmin || (this.props.auth.user.bonaRx && (this.props.auth.user.rx_group !== undefined && this.props.auth.user.rx_group !== "")) ? (
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Drug Dispensing Report</h1>
                            <div>
                                <div className="form-row">
                                    <>
                                        <div className="form-row">
                                            {(this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined) ? (
                                                <div className="form-group col-md-3">
                                                    <label>Group</label>
                                                    <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                        <option value="">All</option>
                                                        <option value={this.props.auth.user.rx_group} {...rx_group_associated === this.props.auth.user.rx_group && { "selected": true }}>{this.props.auth.user.name}--{this.props.auth.user.rx_group}</option>
                                                        {this.props.auth.user.rx_group_associated.split(",").map(each => {
                                                            let nameGroup = this.props.groups.find(i => i.rx_group === each);
                                                            return (
                                                                <option key={each} value={each} {...rx_group_associated === each && { "selected": true }}>{nameGroup ? nameGroup.name : ""}--{each}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>) : null}
                                            {this.props.auth.user.superadmin && this.props.groups.length ?
                                                (<div className="form-group col-md-3">
                                                    <label>Group</label>
                                                    <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                        <option value="">All</option>
                                                        {this.props.groups.map(each => {
                                                            return (
                                                                <>{!associatedRecords.includes(each.rx_group) && each.rx_group !== "superadmin" && each.rx_group !== undefined && <option {...rx_group_associated === each.rx_group && { "selected": true }} key={each.rx_group} value={each.rx_group}>{each.name}-{each.rx_group}</option>}</>
                                                            )
                                                        })}
                                                    </select>
                                                </div>) : null}
                                            {this.state.allDrugName.length ? (
                                                <div className="form-group col-md-3 autocomplete_box">
                                                    <label htmlFor="npi">Medication Name</label>
                                                    <ReactAutocomplete
                                                        items={this.state.allDrugName || []}
                                                        shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                        getItemValue={item => item.name}
                                                        renderItem={(item, highlighted) =>
                                                            <div
                                                                key={item.id}
                                                                style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        }
                                                        value={this.state.value}
                                                        onChange={e => this.setState({ value: e.target.value })}
                                                        onSelect={value => this.setState({ value })}
                                                    />

                                                </div>
                                            ) : ""}
                                            {/* <div className="form-group col-md-3">
                                            <label htmlFor="npi">DEA Class</label>
                                            <select onChange={this.onChangeDea} value={this.state.deaFilter} id="deaFilter" className="form-control">
                                                <option value="">Please Select</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="2N">2N</option>
                                                <option value="3N">3N</option>
                                                <option value="NC">NC</option>

                                            </select>
                                        </div> */}
                                            <div className="form-group col-md-3">
                                                <label htmlFor="npi">From Date</label>
                                                <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                                <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="first_name">To Date</label>
                                                <DatePicker selected={this.state.to_date} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                                <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="mdeication_label">Name</label>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.mdeication_label}
                                                    id="mdeication_label"
                                                    type="text"
                                                    className="form-control"
                                                />

                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="_id">NDC</label>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state._id}
                                                    id="_id"
                                                    type="text"
                                                    className="form-control"
                                                />

                                            </div>

                                        </div>
                                        <button
                                            onClick={this.onSearch}
                                            type="button"
                                            className="btn btn-primary">
                                            Search
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={this.reset}>Clear</button>
                                    </>

                                </div>
                            </div>
                            <br></br>
                            {this.state.recordsGraph.length > 0 && (
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <h5>Top {this.state.records.length > 500 ? "500" : ""} Drugs Report</h5>
                                        <Chart
                                            chartType="BubbleChart"
                                            height={'500px'}
                                            loader={<div>Loading Chart</div>}
                                            data={this.state.recordsGraph}
                                            options={{
                                                hAxis: { title: 'Claims' },
                                                vAxis: { title: 'Member Paid', format: "currency" },
                                                bubble: {
                                                    textStyle: {
                                                        fontSize: 1,
                                                        color: "transparent"
                                                    },
                                                },
                                                legend: 'none',
                                                // colorAxis: {colors: ['#007bff']}
                                            }}
                                            rootProps={{ 'data-testid': '7' }}
                                            sizeAxis={{ minValue: 0, maxSize: 10 }}
                                            formatters={[
                                                {
                                                    type: 'NumberFormat',
                                                    column: 2,
                                                    options: {
                                                        prefix: '$',
                                                        negativeColor: 'red',
                                                        negativeParens: true,
                                                    },
                                                },
                                            ]}
                                        />

                                    </div>
                                </div>
                            )}
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                        <div className="card">
                            <div className="card-body">
                                {(this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") && this.props.auth.user.termCondition == 1 ? (
                                    <h6 class="font-weight-bold text-danger">You have no data to display. Your dispensing data will automatically be displayed when your custom cards are used at any pharmacy nationwide</h6>

                                ) : (this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") ? (
                                    <h6 class="font-weight-bold text-danger">To access all analytical tools you must register on BonaRx - FREE <br></br>
                                        <button className="btn btn-primary" data-toggle="modal" data-target="#term-condition">Register here</button></h6>
                                ) : (
                                    <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="card-body">Contact</Link> Us.</h6>
                                )}
                            </div>
                        </div>
                        <img className="static-image" src={dashboard} />
                    </div>
                    )
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups
});

export default connect(
    mapStateToProps, { setPharmacies }
)(DrugReport);
