import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from 'is-empty';



class CliaPhysicianAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            clia: "",
            laboratory_type: "",
            certificate_type: "",
            laboratory_address: "",
            laboratory_name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            fax: "",
            email: "",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-waiver-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    setFromDate = (date) => {
        this.setState({ assigned_date: date });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onWaiverAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.clia === '') {
            errors.clia = "This field is required"
        } else {
            delete errors.clia
        }
        if (this.state.laboratory_type === '') {
            errors.laboratory_type = "This field is required"
        } else {
            delete errors.laboratory_type
        }
        if (this.state.certificate_type === '') {
            errors.certificate_type = "This field is required"
        } else {
            delete errors.certificate_type
        }
        if (this.state.laboratory_address === '') {
            errors.laboratory_address = "This field is required"
        } else {
            delete errors.laboratory_address
        }
        if (this.state.laboratory_name === '') {
            errors.laboratory_name = "This field is required"
        } else {
            delete errors.laboratory_name
        }
        if (this.state.address === '') {
            errors.address = "This field is required"
        } else {
            delete errors.address
        }
        if (this.state.city === '') {
            errors.city = "This field is required"
        } else {
            delete errors.city
        }
        if (this.state.zip === '') {
            errors.zip = "This field is required"
        } else {
            delete errors.zip
        }
        if (this.state.phone === '') {
            errors.phone = "This field is required"
        } else {
            delete errors.phone
        } if (this.state.fax === '') {
            errors.fax = "This field is required"
        } else {
            delete errors.fax
        }
        if (this.state.state === '') {
            errors.state = "This field is required"
        } else {
            delete errors.state
        }
        if (this.state.email === '') {
            errors.email = "This field is required"
        } else {
            delete errors.email
        }
        if (isEmpty(errors)) {
            const newWaiver = {
                clia: this.state.clia,
                laboratory_type: this.state.laboratory_type,
                certificate_type: this.state.certificate_type,
                laboratory_address: this.state.laboratory_address,
                laboratory_name: this.state.laboratory_name,
                address: this.state.address,
                email: this.state.email,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                phone: this.state.phone,
                fax: this.state.fax,
            };
            axios
                .post("/api/CLIA-add", newWaiver)
                .then(res => {
                    this.props.getData();
                    $('#add-waiver-modal').modal('hide');
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-waiver-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add CLIA Waiver Pharmacies</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onWaiverAdd} id="add-waiver">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">CLIA Waiver Pharmacies</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.clia}
                                                id="clia"
                                                type="text"
                                                required
                                                error={errors.clia}
                                                className={classnames("form-control", {
                                                    invalid: errors.clia
                                                })} />
                                            <span className="text-danger">{errors.clia}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                error={errors.email}
                                                id="email"
                                                type="email"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}
                                            />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Laboratory Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.laboratory_type}
                                                error={errors.laboratory_type}
                                                id="laboratory_type"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.laboratory_type
                                                })}
                                            />
                                            <span className="text-danger">{errors.laboratory_type}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Certificate Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.certificate_type}
                                                id="certificate_type"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.certificate_type
                                                })}
                                            />
                                            <span className="text-danger">{errors.certificate_type}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Laboratory Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.laboratory_address}
                                                id="laboratory_address"
                                                type="text"
                                                error={errors.laboratory_address}
                                                className={classnames("form-control", {
                                                    invalid: errors.laboratory_address
                                                })} />
                                            <span className="text-danger">{errors.laboratory_address}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Laboratory Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.laboratory_name}
                                                id="laboratory_name"
                                                type="text"
                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.laboratory_name
                                                })} />
                                            <span className="text-danger">{errors.laboratory_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.address}
                                                id="address"
                                                type="text"
                                                error={errors.rx_group}
                                                className={classnames("form-control", {
                                                    invalid: errors.address
                                                })} />
                                            <span className="text-danger">{errors.address}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="upline">Upline</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.upline}
                                                id="upline"
                                                type="text"
                                                error={errors.upline}
                                                className={classnames("form-control", {
                                                    invalid: errors.upline
                                                })} />
                                            <span className="text-danger">{errors.upline}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="assigned_date">Assigned Date</label>

                                        </div>


                                        <div className="col-md-9">
                                            <DatePicker selected={this.state.assigned_date} onChange={(date) => this.setFromDate(date)} className="form-control" />
                                            <span className="text-danger">{errors.assigned_date}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_name">First Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.first_name}
                                                id="first_name"
                                                type="text"
                                                error={errors.first_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.first_name
                                                })} />
                                            <span className="text-danger">{errors.first_name}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Last Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.last_name}
                                                id="last_name"
                                                type="text"
                                                error={errors.last_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.last_name
                                                })} />
                                            <span className="text-danger">{errors.last_name}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="street">Street</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.street}
                                                id="street"
                                                type="text"
                                                error={errors.street}
                                                className={classnames("form-control", {
                                                    invalid: errors.street
                                                })} />
                                            <span className="text-danger">{errors.street}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="city">City</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.city}
                                                id="city"
                                                type="text"
                                                error={errors.city}
                                                className={classnames("form-control", {
                                                    invalid: errors.city
                                                })} />
                                            <span className="text-danger">{errors.city}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="state">State</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.state}
                                                id="state"
                                                type="text"
                                                error={errors.state}
                                                className={classnames("form-control", {
                                                    invalid: errors.state
                                                })} />
                                            <span className="text-danger">{errors.state}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="zip">Zip</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.zip}
                                                id="zip"
                                                type="text"
                                                error={errors.zip}
                                                className={classnames("form-control", {
                                                    invalid: errors.zip
                                                })} />
                                            <span className="text-danger">{errors.zip}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="website">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="text"
                                                error={errors.website}
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })} />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phone">Phone</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.phone}
                                                id="phone"
                                                type="text"
                                                error={errors.phone}
                                                className={classnames("form-control", {
                                                    invalid: errors.phone
                                                })} />
                                            <span className="text-danger">{errors.phone}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fax">Fax</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fax}
                                                id="fax"
                                                type="text"
                                                error={errors.fax}
                                                className={classnames("form-control", {
                                                    invalid: errors.fax
                                                })} />
                                            <span className="text-danger">{errors.fax}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-waiver"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add CLIA Waiver Pharmacies
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (withRouter(CliaPhysicianAddModal));
