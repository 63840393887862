import React, { Component, Fragment } from "react";
import ReactDatatable from '../../../utils/ReactDatatable';

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import "react-datepicker/dist/react-datepicker.css";
import '../../../App.css';
import PulseLoader from "react-spinners/PulseLoader";

class PhysicianMedication extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            record: undefined,
            totalCount: 0,
            records:0,
            
        };

        this.columns = [
            {
                key: "_id",
                text: "NDC",
                className: "_id",
                align: "left",
                sortable: true,
                show_medication: true
            },
            {
                key: "mdeication_label",
                text: "Medication",
                className: "mdeication_label",
                align: "left",
                sortable: true,
            },
            {
                key: "claims",
                text: "Claims",
                className: "claims",
                align: "left",
                sortable: true,
            },
            {
                key: "retail",
                text: "Total Revenue($)",
                className: "retail",
                align: "left",
                sortable: true,
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };
    }

    exportToCSV = () => {
        var headers = {
            "ndc":"NDC",
            "claims":"Claims",
            "retail":"Total Revenue($)",
            "mdeication_label":"Medication"
        };
        // // add columns in sheet array
        
        var records = [
            
        ];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.props.data.records) {
            let record = this.props.data.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }
    
    render() {
        return (
            <div>
                <div className="modal" id="show-physician-medication" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        {this.props.data && 
                            <div className="modal-content">
                                <div className="modal-header">
                                    
                                    <h5 className="modal-title">NPI: {this.props.data._id}</h5><br/>
                                    <h5 className="float-right-padding">Name: {this.props.data.first_name +' '+ this.props.data.last_name}</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.props.data.records}
                                        columns={this.columns}
                                        dynamic={false}
                                        total_record={this.props.data.records.length}
                                        loading={true}
                                        exportToCSV={this.exportToCSV}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}
export default (PhysicianMedication)
