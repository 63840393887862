import React from "react";

const Otp = (props) => {
    console.log("props",props)
    return (
        <>fdsf</>
    )
}

export default Otp;
