import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import bona from "../../utils/psga.png";
var x;
class Forgot extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    toggleChange = (value) => {
        this.setState({
            isFilterOpen: value
        });
    }


    onSubmit = e => {
        e.preventDefault();
        axios({
            method: "POST",
            url: "/api/forgot-password",
            data: this.state
        }).then((res) => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
            })
            //this.props.history.push("/login");
            this.setState({
                email: '',
            })
        })

    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">

                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">

                        <div className="card-body p-1">
                            <h2 className="text-center text-primary mt-3 " style={{ marginBottom: "50px" }}><img src={bona} height={50} /></h2>

                            <div className="tab-pane fade show active" id="pills-group" role="tabpanel" aria-labelledby="pills-group-tab">
                                <form noValidate onSubmit={this.onSubmit} className="white">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        error={errors.email}
                                        id="email"
                                        type="email"
                                        className={classnames("form-control", {
                                            invalid: errors.email
                                        })}
                                    />
                                    <span className="text-danger">{errors.email}</span>
                                    <br></br>
                                    
                                    <button
                                        type="submit"
                                        className="btn  btn-large btn-primary mt-2 px-5 float-left ">
                                        Send Email
                                    </button>
                                    
                                    <Link to="/login"><button type="submit"
                                                className="btn btn-large btn-primary mt-2 px-5 float-right">Log in</button></Link>
                                </form>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Forgot);
