import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from 'jquery';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import AddCredits from "../partials/AddCredits";
import moment from "moment"
// import { faPlus, faDownload,faCog } from "@fortawesome/free-solid-svg-icons";

class Credits extends React.Component {

    constructor() {
        super();

        this.columns = [
            {
                key: "points",
                text: "Points",
                className: "points",
                align: "left",
                sortable: true,
            },
            {
                key: "type",
                text: "Type",
                className: "type",
                align: "left",
                sortable: true,
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
            },
            {
                key: "comment",
                text: "Comment",
                className: "comment",
                align: "left",
                sortable: true,
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Points Ledger",
            no_data_text: 'No Details found!',
            button: {
                excel: false,
                print: false,
                csv: false,
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            cardNumber: "",
            expiry: "",
            cvv: "",
            _id: "",
            current_points: 0,
            errors: {},
            records: [],
            totalCount: 0,
            cardError: true,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            },
        };
    }

    componentDidMount() {
        this.getData()
        this.getCurrentPoints()
        var showPopup = window.location.search.split('show-popup=')
        if(showPopup.length>1){
            $("button.btn.btn-outline-primary.float-right.mt-3.mr-2").trigger("click")
        }
    };

    getCurrentPoints = () => {
        let {filterData} = this.state
        filterData.search = {
            email: this.props.auth.user.final_user
        }
        axios
            .post("/api/user-data", filterData)
            .then(res => {
                this.setState({
                    current_points: res.data.records[0].points,
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    getData() {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "_id", order: "desc" }
        }
        filterData.search = {
            email: this.props.auth.user.final_user
        }
        axios
            .post("/api/credits", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    each.type = each.type===1?"Credit":"Debit"
                    each.date = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                    result.push(each)
                }
                this.setState({
                    loading: false,
                    records: result,
                    totalCount: res.data.count
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSave = e => {
        e.preventDefault();
        if (this.state.cardError === false) {
            const newCard = {
                email: this.props.auth.user.final_user,
                cardNumber: this.state.cardNumber,
                expiry: this.state.expiry,
                cvc: this.state.cvc,
            };
            axios
                .post("/api/add-credit", newCard)
                .then(res => {
                    this.getData();
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        }
    };

    handleCardNumberChange = (e) => {
        this.setState({
            cardNumber: e.target.value,
            cardError: false
        });
    };

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value,
            cardError: false
        });
    };

    handleCardCVCChange = (e) => {
        this.setState({
            cvc: e.target.value,
            cardError: false
        });
    };

    setError = () => {
        this.setState({
            cardError: true
        })
    }

    sendPoints = (points) => {
        // this.setState({
        //     current_points: points
        // })
        this.getData()
        this.getCurrentPoints()
    }

    render() {
        const { errors } = this.state;
        console.log(this.state.cardError)
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Credits</h1>
                        <p className="help-text font-weight-bold h6">Available Points: {this.state.current_points} </p>
                        <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-credits"><FontAwesomeIcon icon={faPlus} />Add Credit</button>
                        <AddCredits sendPoints={this.sendPoints}/>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, null
)(Credits);
