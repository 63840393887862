import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"


import { Collapse } from 'react-collapse';

class Dea extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "dea",
                text: "DEA",
                className: "dea",
                align: "left",
                sortable: true,
            },
            {
                key: "class",
                text: "Drug Schedules",
                className: "class",
                align: "left",
                sortable: true,
            },
            {
                key: "expires",
                text: "Expiration Date",
                className: "expires",
                align: "left",
                sortable: true,
            },
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "address",
                text: "Address",
                className: "address",
                align: "left",
                sortable: true,
            },
            {
                key: "city",
                text: "City",
                className: "city",
                align: "left",
                sortable: true,
            },
            {
                key: "state",
                text: "State",
                className: "state",
                align: "left",
                sortable: true,
            },
            {
                key: "zip",
                text: "Zip",
                className: "zip",
                align: "left",
                sortable: true,
            },
            {
                key: "business_activity",
                text: "Business Activity",
                className: "business_activity",
                align: "left",
                sortable: true,
            },
            {
                key: "payment_indicator",
                text: "Payment Indicator",
                className: "payment_indicator",
                align: "left",
                sortable: true,
            },
            {
                key: "state_lisence",
                text: "State License",
                className: "state_lisence",
                align: "left",
                sortable: true,
            },
            {
                key: "state_cs",
                text: "State CS License",
                className: "state_cs",
                align: "left",
                sortable: true,
            }

        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "DEA List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                // print: false,
                print: true,

                // csv: 
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            currentRecord: {
            },
            dea: undefined,
            name: undefined,
            state: undefined,
            zip: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.dea !== '' && { dea: this.state.dea }),
            ...(this.state.name !== '' && { name: this.state.name }),
            ...(this.state.state !== '' && { state: this.state.state }),
            ...(this.state.zip !== '' && { zip: this.state.zip }),
        }
        axios
            .post("/api/dea", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.expires = each.expires ? moment(each.expires).format('L') : ""
                        each.payment_indicator = each.payment_indicator === "P" ? "Paid" : "Exempt"
                        result.push(each)
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            dea: undefined,
            name: undefined,
            state: undefined,
            zip: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            records: [],
            totalCount: undefined
        })
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "dea": "DEA",
            "class": "Drug Schedules",
            "expires": "Expiration Date",
            "name": "Name",
            "additional_info": "Additional Info",
            "dba": "DBA",
            "address": "Address",
            "city": "City",
            "state": "State",

            "zip": "ZIP",
            "business_activity": "Business Activity",
            "payment_indicator": "Payment Indicator",
            "activity": "Activity",
            "state_lisence": "State License",
            "state_cs": "State CS License"
        };
        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "asc" }
        }
        filterData.search = {
            ...(this.state.dea !== '' && { dea: this.state.dea }),
            ...(this.state.name !== '' && { hcpcs: this.state.name }),
            ...(this.state.state !== '' && { state: this.state.state }),
            ...(this.state.zip !== '' && { zip: this.state.zip }),
        }
        axios
            .post("/api/dea", filterData)
            .then(res => {
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    delete record._id
                    delete record.id
                    delete record.__v

                    record.name = '"' + record.name + '"'
                    record.address = '"' + record.address + '"'
                    record.additional_info = '"' + record.additional_info + '"'
                    record.dba = '"' + record.dba + '"'
                    // record.ndc_label = '"' + record.ndc_label + '"'
                    if (record.expires !== '') {
                        record.expires = moment(record.expires).format('L');
                    }
                    // if (record.relation_end_date !== '') {
                    //     record.relation_end_date = moment(record.relation_end_date).format('L');
                    // }
                    // if (record.start_date !== '') {
                    //     record.start_date = moment(record.start_date).format('L');
                    // }
                    // if (record.end_date !== '') {
                    //     record.end_date = moment(record.end_date).format('L');
                    // }
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
                // var universalBOM = "\uFEFF";
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }
    exportToPDF = (from, to) => {
        let filterData = {
            page_number: 1,
            page_size: 2000,
            sort_order: { column: "_id", order: "asc" },
            search: {}
        }
        let sTable = "";
        sTable += "<table>";
        sTable += "<thead>";
        sTable += "<tr>";
        for (let column of this.columns) {
            sTable += "<th>" + column.text + "</th>";
        }
        sTable += "</tr>";
        sTable += "</thead>";
        sTable += "<tbody>";
        for (let i in this.state.records) {
            let record = this.state.records[i];
            sTable += "<tr>";
            for (let column of this.columns) {
                if (column.cell && typeof column.cell === "function") {
                    sTable += "<td></td>";
                } else if (record[column.key]) {
                    sTable += "<td>" + record[column.key] + "</td>";
                } else {
                    sTable += "<td></td>";
                }
            }
            sTable += "</tr>";
        }
        sTable += "</tbody>";
        sTable += "</table>";

        var style = "<style>";
        style = style + "table {width: 100%;font: 17px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;textAlign:left;}";
        style = style + "</style>";

        var win = window.open('_blank');
        win.document.write('<html><head>');
        win.document.write('<title>' + this.config.filename + '</title>');
        win.document.write(style);
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write('<h1>' + this.config.filename + '</h1>');
        win.document.write(sTable);
        win.document.write('</body></html>');
        win.print();
        win.close();
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">DEA</h1>
                        <p className="help-text font-weight-bold h6">This is the fastest, most comprehensive search tool of the DEA controlled substances act database</p>
                        <div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="dea">DEA</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.dea}
                                                id="dea"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        {this.props.auth.user.superadmin &&
                                            (<>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="name">Name</label>
                                                    <input
                                                        onChange={this.onChange}
                                                        value={this.state.name}
                                                        id="name"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="state">State</label>
                                                    <select name="state" onClick={this.onChangeSelect} className="form-control" id="state">
                                                        <option value="" defaultValue>Any</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="AA">Armed Forces America</option>
                                                        <option value="AE">Armed Forces Europe /Canada / Middle East / Africa</option>
                                                        <option value="AP">Armed Forces Pacific</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District of Columbia</option>
                                                        <option value="FM">Federated States of Micronesia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MP">Mariana Islands, Northern</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VI">Virgin islands</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>

                                                    </select>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="zip">Zip Code</label>
                                                    <input
                                                        onChange={this.onChange}
                                                        value={this.state.zip}
                                                        id="zip"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </>
                                            )}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Filter</button>
                                    <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                            exportToPDF={this.exportToPDF}

                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Dea);
