import React, { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Row, Container, Col } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Chart from "react-google-charts";
import { toast, ToastContainer } from "react-toastify";
const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },

};

const Control = (formData) => {
    const [controlArr, setControlArr] = useState({});
    const [uncArr, setUncArr] = useState({});
    const [brandArr, setBrandArr] = useState({});
    const [claimArr, setClaimArr] = useState({});
    const [payableArr, setPayableArr] = useState({});
    const [savingArr, setSavingArr] = useState({});
    let [z, setPArr] = useState([]);
    let [x, setXArr] = useState([]);
    let [y, setYArr] = useState([]);
    let [w, setwArr] = useState([]);
    let [a, setaArr] = useState([]);
    let [savingTotal, setSavingTotal] = useState([]);
    useEffect(() => {
        
        if (controlArr.length===undefined) {
            axios
                .post(`/api/graph-control`, formData)
                .then(res => {
                    let ControlArr = []
                    ControlArr.push(['Type', 'Percent'])
                    ControlArr.push(['Controlled', res.data.controlled])
                    ControlArr.push(['Non-Controlled', res.data.non_controlled])
                    var x = (res.data.controlled + res.data.non_controlled)
                    x = new Intl.NumberFormat().format(x)

                    setControlArr(ControlArr);
                    setXArr(x);
                })
                .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        }
        axios
            .post(`/api/graph-unc`, formData)
            .then(res => {
                let UncArr = []
                UncArr.push(['Type', 'Percent'])
                UncArr.push(['Yes', res.data.yes])
                UncArr.push(['No', res.data.no])
                a = res.data.yes + res.data.no
                a = new Intl.NumberFormat().format(a)

                setUncArr(UncArr);
                setaArr(a);
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        axios
            .post(`/api/graph-brand`, formData)
            .then(res => {
                let BrandArr = []
                BrandArr.push(['Type', 'Percent'])
                BrandArr.push(['Brand', res.data.brand])
                BrandArr.push(['Generic', res.data.generic])
                z = res.data.brand + res.data.generic
                z = new Intl.NumberFormat().format(z)

                setBrandArr(BrandArr);
                setPArr(z)
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        axios
            .post(`/api/graph-claim`, formData)
            .then(res => {
                let ClaimArr = []
                ClaimArr.push(['Type', 'Percent'])
                ClaimArr.push(['Claim', res.data.claim])
                ClaimArr.push(['Reversed', res.data.reversed])
                y = res.data.claim + res.data.reversed
                y = new Intl.NumberFormat().format(y)

                setClaimArr(ClaimArr);
                setYArr(y)
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        axios
            .post(`/api/graph-payable`, formData)
            .then(res => {
                let PayableArr = []
                PayableArr.push(['Type', 'Percent'])
                PayableArr.push(['Yes', res.data.yes])
                PayableArr.push(['No', res.data.no])
                w = res.data.yes + res.data.no
                w = new Intl.NumberFormat().format(w)

                setPayableArr(PayableArr);
                setwArr(w);
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })

        axios
            .post(`/api/graph-savings`, formData)
            .then(res => {
                let SavingArr = []
                SavingArr.push(['Type', 'Percent'])
                SavingArr.push(['Member Paid', res.data.records[0].member_paid])
                SavingArr.push(['Retail Price', res.data.records[0].retail_price])
                let savingTotal = Math.round(res.data.records[0].member_paid * 100 / res.data.records[0].retail_price)
                setSavingArr(SavingArr);
                setSavingTotal(savingTotal);
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }, [formData]);

    return (
        <div className="row px-2" style={{ "marginTop": "50px" }}>
            <ToastContainer/>
            <Container>
                <Row>
                    {uncArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left" >Paid at U&C</h5>
                                {/* <h5 className="right">Total: {a}</h5> */}
                                <div>
                                    {uncArr[1][1] === 0 && uncArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}

                                            data={uncArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },
                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}

                    {brandArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left">Brand vs Generic</h5>
                                {/* <h5 className="right">Total: {z}</h5> */}
                                <div>
                                    {brandArr[1][1] === 0 && brandArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={brandArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },
                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    {claimArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left">Claim vs Reversed</h5>
                                {/* <h5 className="right">Total: {y}</h5> */}
                                <div>
                                    {claimArr[1][1] === 0 && claimArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={claimArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },

                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}

                    {payableArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left">Payable Claims</h5>
                                {/* <h5 className="right">Total: {w}</h5> */}
                                <div>
                                    {payableArr[1][1] === 0 && payableArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={payableArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },
                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    {controlArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left">Control vs Non-Control</h5>
                                {/* <h5 className="right">Total: {x}</h5> */}
                                <div>
                                    {controlArr[1][1] === 0 && controlArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={controlArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },
                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    {savingArr.length && <div className="col-sm-6 p-sm-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="left">Member Paid vs Retail Price</h5>
                                <h5 className="right">Savings: {savingTotal}%</h5>
                                <div>
                                    {savingArr[1][1] === 0 && savingArr[2][1] === 0 ? (<p>Data will display automatically once the Cards are used!</p>) :
                                        <Chart
                                            width={'500px'}
                                            height={'300px'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={savingArr}
                                            options={{
                                                title: '',
                                                slices: {
                                                    1: { color: '#F75851' },
                                                    0: { color: '#3B7CB5' },
                                                },
                                                is3D: true,
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                            formatters={[
                                                {
                                                    type: 'NumberFormat',
                                                    column: 1,
                                                    options: {
                                                        prefix: '$',
                                                        negativeColor: 'red',
                                                        negativeParens: true,
                                                    },
                                                },
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                </Row>
            </Container>
        </div>
    );
}
export default Control;
