import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../utils/XPedicareRx.png";
import xpx from "../../utils/xpx.jpg";
import  XpedicareVideo from "../partials/XpedicareVideo";

class Rxreach extends React.Component {

  render() {
    return (
      <div className="section">
        <div className="container">
          <XpedicareVideo/>
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} style={{ marginLeft: "20px" }} />
                <div className="card">
                  <div className="card-body">
                    <h1 className="card-title text-primary" style={{ fontWeight: "bold", fontSize: "47px" }}>Virtual Care on Demand at Your Pharmacy</h1>
                    <div className="row">
                      <div className="col-md-8">
                        <p className="card-text" style={{ fontSize: "24px" }}>You can combine medical and prescriptions for one simple fee for conditions such as ED, LDN, Continuous BGM, Oral Contraceptives, Alopecia, Aging, UTI, Diabetes, Travel Meds, COVID-19, asthma, smoking cessations, PreP, EpiPen, cold sores, Acne, Allergies and even Compounded Medications. </p>
                        <p className="card-text" style={{ fontSize: "24px" }}>Prescriptions will be sent to your secure portal to process for your patients. Best of all if we cannot help the patient, there is absolutely NO charge.</p>
                      </div>
                      <div className="col-md-4" style={{ "textAlign": "center" }}><img style={{ width: "70%",marginTop:"12px"}} src={xpx} /></div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "24px" }}>There are no monthly fees, you simply pay for what you use.
                        
                          <br></br><br></br>
                          <p style={{marginBottom:"-8px"}}><a href="#" data-toggle="modal" data-target="#how-it-works">Watch how it works</a></p><span style={{ fontSize: "21px" }}>Available only in Florida and Texas*</span>
                          <button
                            type="button"
                            style={{ marginRight: "63px" }}
                            className="btn btn-success btn-lg float-right">
                            <a href="https://app.xpedicare.com/#/?company=psgarx" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>LOGIN OR CREATE NEW ACCOUNT</a>
                          </button>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default Rxreach;