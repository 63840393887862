import React from 'react'
import Navbar from '../../partials/Navbar';
import Sidebar from '../../partials/Sidebar';
import PulseLoader from "react-spinners/PulseLoader";
import classnames from "classnames";

import { connect } from "react-redux";
import axios from 'axios';
import { updateUser } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Table } from 'react-bootstrap';

import 'react-toastify/dist/ReactToastify.css';

class Drug extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.state = {
            record: {},
            errors: {},
        };
    }
    componentDidMount() {
        const filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "_id", order: "asc" }
            , search: { _id: this.props.match.params._id }
        }
        axios
            .post(`/api/drugs`, filterData)
            .then(res => {
                this.setState({
                    record: res.data.records[0],
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }
    goBack(){
        this.props.history.goBack();
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Values</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>NDC</td>
                            <td>{this.state.record.ndc}</td>
                        </tr>
                        <tr>
                            <td>Product Description</td>
                            <td>{this.state.record.name}</td>
                        </tr>
                        <tr>
                            <td>Primary Ingredient HIC4 Code</td>
                            <td>{this.state.record.hic_code}</td>
                        </tr>
                        <tr>
                            <td>Primary Ingredient HIC4 Desc</td>
                            <td>{this.state.record.hic_desc}</td>
                        </tr>
                        <tr>
                            <td>UOM</td>
                            <td>{this.state.record.uom}</td>
                        </tr>
                        <tr>
                            <td>Supplier Name</td>
                            <td>{this.state.record.supplier_name}</td>
                        </tr>
                        <tr>
                            <td>Route Desc</td>
                            <td>{this.state.record.route_desc}</td>
                        </tr>
                        <tr>
                            <td>AHFS Code</td>
                            <td>{this.state.record.ahfs_code}</td>
                        </tr>
                        <tr>
                            <td>AHFS Desc</td>
                            <td>{this.state.record.ahfs_desc}</td>
                        </tr>
                        <tr>
                            <td>AWP</td>
                            <td>{this.state.record.awp}</td>
                        </tr>
                        <tr>
                            <td>BU per Package</td>
                            <td>{this.state.record.bu_per_package}</td>
                        </tr>
                        <tr>
                            <td>Contract Cost</td>
                            <td>{this.state.record.contract_cost ? "$" + this.state.record.contract_cost : ""}</td>
                        </tr>
                        <tr>
                            <td>Wholesale Cost</td>
                            <td>{this.state.record.wholesale_cost ? "$" + this.state.record.wholesale_cost : ""}</td>
                        </tr>
                        <tr>
                            <td>Current Acq Cost</td>
                            <td>{this.state.record.acq_cost ? "$" + this.state.record.acq_cost : ""}</td>
                        </tr>
                        <tr>
                            <td>DEA Class</td>
                            <td>{this.state.record.dea}</td>
                        </tr>
                        <tr>
                            <td>DEA Class Desc</td>
                            <td>{this.state.record.dea_desc}</td>
                        </tr>
                        <tr>
                            <td>FDB Package Size Qty</td>
                            <td>{this.state.record.fdb_package_size}</td>
                        </tr>
                        <tr>
                            <td>Fine Line Class</td>
                            <td>{this.state.record.fine_line}</td>
                        </tr>
                        <tr>
                            <td>Fine Line Class Desc</td>
                            <td>{this.state.record.fine_line_desc}</td>
                        </tr>
                        <tr>
                            <td>Form Code</td>
                            <td>{this.state.record.form_code}</td>
                        </tr>
                        <tr>
                            <td>GCN 5 digits</td>
                            <td>{this.state.record.gcn}</td>
                        </tr>
                        <tr>
                            <td>GCN Seq # 5 digits</td>
                            <td>{this.state.record.gcn_seq}</td>
                        </tr>
                        <tr>
                            <td>Generic HICL  5 digits</td>
                            <td>{this.state.record.fine_line}</td>
                        </tr>
                        <tr>
                            <td>Generic Name</td>
                            <td>{this.state.record.generic_name}</td>
                        </tr>
                        <tr>
                            <td>GTIN #</td>
                            <td>{this.state.record.gtin}</td>
                        </tr>
                        <tr>
                            <td>Hazard Code Desc</td>
                            <td>{this.state.record.hazard_desc}</td>
                        </tr>
                        <tr>
                            <td>NIOSH Desc</td>
                            <td>{this.state.record.niosh_desc}</td>
                        </tr>
                        <tr>
                            <td>Product Category</td>
                            <td>{this.state.record.category_desc}</td>
                        </tr>
                        <tr>
                            <td>Product Group Desc</td>
                            <td>{this.state.record.group_desc}</td>
                        </tr>
                        <tr>
                            <td>Unit Size Code</td>
                            <td>{this.state.record.unit_size_code}</td>
                        </tr>
                        <tr>
                            <td>Unit Size Qty</td>
                            <td>{this.state.record.unit_size_qty}</td>
                        </tr>
                        <tr>
                            <td>Unit Strength Code</td>
                            <td>{this.state.record.unit_strength_code}</td>
                        </tr>
                        <tr>
                            <td>Unit Strength Qty</td>
                            <td>{this.state.record.unit_strength_qty}</td>
                        </tr>
                        <tr>
                            <td>UPC Barcode</td>
                            <td>{this.state.record.upc_barcode}</td>
                        </tr>
                        <tr>
                            <td>Aberrent</td>
                            <td>{this.state.record.aberrent ? this.state.record.aberrent : "No"}</td>
                        </tr>
                       
                    </tbody>
                    <button type="button" class="btn btn-dark btn-lg" onClick={this.goBack}>Go Back</button>
                </table>
                
            </div>
        )
    }
}
export default withRouter(Drug)
