import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import Forgot from "./components/auth/Forgot";
import ResetPassword from "./components/auth/ResetPassword";
import { connect } from "react-redux";
import Doorstep from "./components/welcome/Doorstep";
import Tabula from "./components/welcome/Tabula";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "jquery/dist/jquery.min";
import "popper.js/dist/popper";

import Users from "./components/pages/Users";
import User from "./components/pages/View/User";
import Report from "./components/pages/Reports";
import Drugs from "./components/pages/Drugs";
import Coupons from "./components/pages/Coupons";
import Doctors from "./components/pages/Doctors";
import support from "./components/pages/support";
import Npis from "./components/pages/View/Npis";
import Drug from "./components/pages/View/Drug";
import GroupStat from "./components/pages/GroupStat";
import PhysicianReport from "./components/pages/PhysicianReport";
import CliaPhysician from "./components/pages/CliaPhysician";
import PharmacyReport from "./components/pages/PharmacyReport";
import DrugReport from "./components/pages/DrugReport";
import ControlDrug from "./components/pages/ControlDrug";
import DeaMapping from "./components/pages/DeaMapping";
import ReportsAhfs from "./components/pages/ReportsAhfs";
import Hcpcs from "./components/pages/Hcpcs";
import Data2000 from "./components/pages/Data2000";
import AnimalDrug from "./components/pages/AnimalDrug";
import Nadac from "./components/pages/Nadac";
import DrugUtilization from "./components/pages/DrugUtilization";
import Dea from "./components/pages/Dea";
import BusinessTool from "./components/pages/BusinessTool";
import Expand from "./components/pages/Expand";
import Navbar from "./components/partials/Navbar";
import Sidebar from "./components/partials/Sidebar";
import Footer from "./components/partials/Footer";
import UserSettings from "./components/partials/UserSettings";
import MedDrugLookup from "./components/solution/MedDrugLookup";
import PrescriberRx from "./components/pages/PrescriberRx";
import Profile from "./components/pages/Profile";
import Credits from "./components/pages/Credits";
import Transaction from "./components/pages/Transaction";
import InternalDoctor from "./components/pages/InternalDoctor";
import Bonarx from "./components/solution/Bonarx";
import Alererx from "./components/solution/Alererx";
import Rxreach from "./components/solution/Rxreach";
import Tabularx from "./components/solution/Tabularx";
import Rxconnexion from "./components/solution/Rxconnexion";
import Subscribe from "./components/pages/Subscribe";
import { setGroups } from "./actions/authActions";
import { logoutUser } from "./actions/authActions";
import IdleTimer from "react-idle-timer";
import queryString from "query-string";
import ExpandRx from "./components/pages/ExpandRx";
import ExpandNew from "./components/pages/ExpandNew";
import Xpedicare from "./components/solution/Xpedicare";
import Otp from "./components/auth/Otp";
class RouteData extends Component {
  componentDidMount() {
    if (this.props.records === undefined) {
      this.props.setGroups();
    }
    if (queryString.parse(window.location.search).logout) {
      this.props.logoutUser(this.props.history);
    }
    if (
      queryString.parse(window.location.search).reference &&
      queryString.parse(window.location.search).reference.includes("Bearer")
    ) {
      localStorage.setItem(
        "jwtToken",
        queryString.parse(window.location.search).reference
      );
      window.location.href = "/dashboard";
    }
  }
  onLogoutClick = (e) => {
    this.props.logoutUser(this.props.history);
  };

  onIdle = (e) => {
    this.onLogoutClick();
  };

  render() {
    return (
      <div className="App">
        <IdleTimer onIdle={this.onIdle} debounce={250} timeout={21600000} />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/otp" component={Otp} />
          <Route
            path="/password-reset/:userId/:token"
            component={ResetPassword}
          />
          <div
            className="container-fluid"
            style={{ minHeight: "calc(100vh - 85px)" }}
          >
            <div className="row">
              {/* <div className="col-2 px-1 bg-dark position-fixed" id="sticky-sidebar"> */}
              <Sidebar />
              <Doorstep />
              <Tabula />
              {/* </div> */}
              <div className="col" id="main">
                <Navbar />
                <Switch>
                  <Route exact path="/group-stats" component={GroupStat} />
                  <Switch>
                    {this.props.auth.user.superadmin && (
                      <PrivateRoute exact path="/users" component={Users} />
                    )}
                    {this.props.auth.user.superadmin && (
                      <PrivateRoute exact path="/user/:_id" component={User} />
                    )}
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute exact path="/npis/:_id" component={Npis} />
                    <PrivateRoute exact path="/drug/:_id" component={Drug} />
                    <PrivateRoute exact path="/reports" component={Report} />
                    <PrivateRoute exact path="/support" component={support} />
                    <PrivateRoute exact path="/npis" component={Doctors} />
                    <PrivateRoute exact path="/drugs" component={Drugs} />
                    <PrivateRoute exact path="/coupons" component={Coupons} />
                    <PrivateRoute
                      exact
                      path="/animal-drug"
                      component={AnimalDrug}
                    />
                    <PrivateRoute exact path="/nadac" component={Nadac} />
                    <PrivateRoute
                      exact
                      path="/drug-utilization"
                      component={DrugUtilization}
                    />
                    <PrivateRoute
                      exact
                      path="/group-stats"
                      component={GroupStat}
                    />
                    <PrivateRoute
                      exact
                      path="/physician-report"
                      component={PhysicianReport}
                    />
                    <PrivateRoute
                      exact
                      path="/clia-physician"
                      component={CliaPhysician}
                    />
                    <PrivateRoute
                      exact
                      path="/pharmacy-report"
                      component={PharmacyReport}
                    />
                    <PrivateRoute
                      exact
                      path="/drug-report"
                      component={DrugReport}
                    />
                    <PrivateRoute
                      exact
                      path="/controlled-drug"
                      component={ControlDrug}
                    />
                    <PrivateRoute
                      exact
                      path="/dea-mapping"
                      component={DeaMapping}
                    />
                    <PrivateRoute
                      exact
                      path="/reports-ahfs"
                      component={ReportsAhfs}
                    />
                    <PrivateRoute exact path="/hcpcs" component={Hcpcs} />
                    <PrivateRoute
                      exact
                      path="/data-2000"
                      component={Data2000}
                    />
                    <PrivateRoute exact path="/dea" component={Dea} />
                    <PrivateRoute
                      exact
                      path="/internal-physician"
                      component={InternalDoctor}
                    />
                    <PrivateRoute
                      exact
                      path="/business-tool"
                      component={BusinessTool}
                    />
                    <PrivateRoute
                      exact
                      path="/user-settings"
                      component={UserSettings}
                    />
                    <PrivateRoute
                      exact
                      path="/med-drug-lookup"
                      component={MedDrugLookup}
                    />
                    <PrivateRoute
                      exact
                      path="/prescriber-rx"
                      component={PrescriberRx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-bonarx"
                      component={Bonarx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-alererx"
                      component={Alererx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-rxreach"
                      component={Rxreach}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-tabularx"
                      component={Tabularx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-rxconnexion"
                      component={Rxconnexion}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-xpedicare"
                      component={Xpedicare}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/credits" component={Credits} />
                    <PrivateRoute
                      exact
                      path="/transactions"
                      component={Transaction}
                    />
                    <PrivateRoute
                      exact
                      path="/subscribe"
                      component={Subscribe}
                    />
                    <PrivateRoute exact path="/expand" component={Expand} />
                    <PrivateRoute
                      exact
                      path="/expand-rx"
                      component={ExpandRx}
                    />
                    <PrivateRoute
                      exact
                      path="/expand-new"
                      component={ExpandNew}
                    />
                  </Switch>
                  <Route exact path="*" component={NotFound} />
                </Switch>
                <Footer />
              </div>
            </div>
          </div>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps, { setGroups, logoutUser })(RouteData);
