import React from 'react'
import classnames from "classnames";

import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import isEmpty from 'is-empty';
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";


import 'react-toastify/dist/ReactToastify.css';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            name: undefined,
            email: undefined,
            npi: undefined,
            first_name: undefined,
            last_name: undefined,
            street: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            phone: undefined,
            website: undefined,
            phone: undefined,
            fax: undefined,
            password: undefined,
            password2: undefined,
            changePassword: undefined,
            npi: undefined,
            software: undefined,
            psao: undefined,
            NABP: undefined,
            phone1: undefined,
            personal_email: undefined,
            addressline_2: undefined,
            errors: {},
        };
    }

    componentDidMount() {
        this.getData()
    };

    getData = () => {
        this.setState({ loading: true })
        let filterData = {}
        filterData.search = {
            _id: this.props.auth.user.id,
        }
        filterData.sort_order = {
            order: "asc",
            column: "_id"
        }
        filterData.find = "all"
        axios
            .post("/api/user-data", filterData)
            .then(res => {
                let result = res.data.records[0]
                this.setState({
                    id: result.id,
                    name: result.name,
                    email: result.email,
                    npi: result.npi,
                    first_name: result.first_name,
                    last_name: result.last_name,
                    street: result.street,
                    city: result.city,
                    state: result.state,
                    zip: result.zip,
                    phone: result.phone,
                    website: result.website,
                    phone: result.phone,
                    fax: result.fax,
                    npi: result.npi,
                    software: result.software,
                    psao: result.psao,
                    NABP: result.NABP,
                    phone1: result.phone1,
                    personal_email: result.personal_email,
                    addressline_2: result.addressline_2,
                    totalCount: res.data.count,
                    loading: false,
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onUserUpdate = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.changePassword) {
            if (this.state.password === '') {
                errors.password = "This field is required"
            } else {
                delete errors.password
            }
            if (this.state.password2 === '') {
                errors.password2 = "This field is required"
            } else if (this.state.password !== this.state.password2) {
                errors.password2 = "Password not match"
            } else {
                delete errors.password2
            }
        }
        if (this.state.name === '') {
            errors.name = "This field is required"
        } else {
            delete errors.name
        }
        if (this.state.email === '') {
            errors.email = "This field is required"
        } else {
            delete errors.email
        }
        if (this.state.first_name === '') {
            errors.first_name = "This field is required"
        } else {
            delete errors.first_name
        }
        if (this.state.last_name === '') {
            errors.last_name = "This field is required"
        } else {
            delete errors.last_name
        }
        if (this.state.zip === '') {
            errors.zip = "This field is required"
        } else {
            delete errors.zip
        }
        if (this.state.phone === '') {
            errors.phone = "This field is required"
        } else {
            delete errors.phone
        }
        if (isEmpty(errors)) {
            const newUser = {
                id: this.state.id,
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                rx_bin: this.state.rx_bin,
                rx_group: this.state.rx_group,
                upline: this.state.upline,
                assigned_date: this.state.assigned_date,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                street: this.state.street,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                website: this.state.website,
                phone: this.state.phone,
                fax: this.state.fax,
                npi: this.state.npi,
                software: this.state.software,
                psao: this.state.psao,
                NABP: this.state.NABP,
                phone1: this.state.phone1,
                personal_email: this.state.personal_email,
                addressline_2: this.state.addressline_2
            };

            if (this.state.changePassword) {
                newUser.password = this.state.password
            }
            axios.post("/api/user-update", newUser).then(res => {
                this.getData();
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
                this.setState({
                    password: undefined,
                    password2: undefined
                })

            }).catch(err =>
                console.log(err)
            )
        }
        else {
            this.setState({ errors })
        }

    };

    toggleChange = (e) => {
        this.setState({ [e.target.id]: !this.state[e.target.id] });
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Update Profile</h1>
                        <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                            
                            <div className="row mt-12">

                                <div className="col-md-6 col-12">
                                    <label htmlFor="name">Pharmacy Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        id="name"
                                        type="text"
                                        required
                                        error={errors.name}
                                        className={classnames("form-control", {
                                            invalid: errors.name
                                        })} />
                                    <span className="text-danger">{errors.name}</span>
                                </div>



                                <div className="col-md-6 col-12">
                                    <label htmlFor="email">Pharmacy Email(use while login)</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        error={errors.email}
                                        id="email"
                                        type="email"
                                        required
                                        className={classnames("form-control", {
                                            invalid: errors.email
                                        })}
                                    />
                                    <span className="text-danger">{errors.email}</span>
                                </div>
                            </div>
                            <div className="row mt-12">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="name">Pharmacy NPI</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.npi}
                                        id="npi"
                                        type="text"
                                        error={errors.npi}
                                        className={classnames("form-control", {
                                            invalid: errors.npi
                                        })} />
                                    <span className="text-danger">{errors.npi}</span>
                                </div>

                                <div className="col-md-6 col-12">
                                    <label htmlFor="NABP">NABP #</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.NABP}
                                        id="NABP"
                                        type="text"
                                        error={errors.NABP}
                                        className={classnames("form-control", {
                                            invalid: errors.NABP
                                        })} />
                                    <span className="text-danger">{errors.NABP}</span>
                                </div>


                            </div>




                            <div className="row mt-12">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="psao">PSAO</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.psao}
                                        id="psao"
                                        type="text"
                                        error={errors.psao}
                                        className={classnames("form-control", {
                                            invalid: errors.psao
                                        })} />
                                    <span className="text-danger">{errors.psao}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="software">Pharmacy Software</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.software}
                                        id="software"
                                        type="text"
                                        error={errors.software}
                                        className={classnames("form-control", {
                                            invalid: errors.software
                                        })} />
                                    <span className="text-danger">{errors.software}</span>
                                </div>
                            </div>
                            <div className="row mt-12">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="street">Address Line 1</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.street}
                                        id="street"
                                        type="text"
                                        error={errors.street}
                                        className={classnames("form-control", {
                                            invalid: errors.street
                                        })} />
                                    <span className="text-danger">{errors.street}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="addressline_2">Address Line 2</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.addressline_2}
                                        id="addressline_2"
                                        type="text"
                                        error={errors.addressline_2}
                                        className={classnames("form-control", {
                                            invalid: errors.addressline_2
                                        })} />
                                    <span className="text-danger">{errors.addressline_2}</span>
                                </div>
                            </div>
                            <div className="row mt-12">

                                <div className="col-md-6 col-12">
                                    <label htmlFor="city">City</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.city}
                                        id="city"
                                        type="text"
                                        error={errors.city}
                                        className={classnames("form-control", {
                                            invalid: errors.city
                                        })} />
                                    <span className="text-danger">{errors.city}</span>
                                </div>

                                <div className="col-md-6 col-12">
                                    <label htmlFor="state">State</label>
                                    <select name="state" onClick={this.onChange} className="form-control" id="state">
                                        <option value="" selected="">Please Select</option>
                                        <option selected={this.state.state==="AL"} value="AL">Alabama</option>
                                        <option selected={this.state.state==="AK"} value="AK">Alaska</option>
                                        <option selected={this.state.state==="AS"} value="AS">American Samoa</option>
                                        <option selected={this.state.state==="AZ"} value="AZ">Arizona</option>
                                        <option selected={this.state.state==="AR"} value="AR">Arkansas</option>
                                        <option selected={this.state.state==="AA"} value="AA">Armed Forces America</option>
                                        <option selected={this.state.state==="AE"} value="AE">Armed Forces Europe /Canada / Middle East / Africa</option>
                                        <option selected={this.state.state==="AP"} value="AP">Armed Forces Pacific</option>
                                        <option selected={this.state.state==="CA"} value="CA">California</option>
                                        <option selected={this.state.state==="CO"} value="CO">Colorado</option>
                                        <option selected={this.state.state==="CT"} value="CT">Connecticut</option>
                                        <option selected={this.state.state==="DE"} value="DE">Delaware</option>
                                        <option selected={this.state.state==="DC"} value="DC">District of Columbia</option>
                                        <option selected={this.state.state==="FM"} value="FM">Federated States of Micronesia</option>
                                        <option selected={this.state.state==="FL"} value="FL">Florida</option>
                                        <option selected={this.state.state==="GA"} value="GA">Georgia</option>
                                        <option selected={this.state.state==="GU"} value="GU">Guam</option>
                                        <option selected={this.state.state==="HI"} value="HI">Hawaii</option>
                                        <option selected={this.state.state==="ID"} value="ID">Idaho</option>
                                        <option selected={this.state.state==="IL"} value="IL">Illinois</option>
                                        <option selected={this.state.state==="IN"} value="IN">Indiana</option>
                                        <option selected={this.state.state==="IA"} value="IA">Iowa</option>
                                        <option selected={this.state.state==="KS"} value="KS">Kansas</option>
                                        <option selected={this.state.state==="KY"} value="KY">Kentucky</option>
                                        <option selected={this.state.state==="LA"} value="LA">Louisiana</option>
                                        <option selected={this.state.state==="ME"} value="ME">Maine</option>
                                        <option selected={this.state.state==="MP"} value="MP">Mariana Islands, Northern</option>
                                        <option selected={this.state.state==="MH"} value="MH">Marshall Islands</option>
                                        <option selected={this.state.state==="MD"} value="MD">Maryland</option>
                                        <option selected={this.state.state==="MA"} value="MA">Massachusetts</option>
                                        <option selected={this.state.state==="MI"} value="MI">Michigan</option>
                                        <option selected={this.state.state==="MN"} value="MN">Minnesota</option>
                                        <option selected={this.state.state==="MS"} value="MS">Mississippi</option>
                                        <option selected={this.state.state==="MO"} value="MO">Missouri</option>
                                        <option selected={this.state.state==="MT"} value="MT">Montana</option>
                                        <option selected={this.state.state==="NE"} value="NE">Nebraska</option>
                                        <option selected={this.state.state==="NV"} value="NV">Nevada</option>
                                        <option selected={this.state.state==="NH"} value="NH">New Hampshire</option>
                                        <option selected={this.state.state==="NJ"} value="NJ">New Jersey</option>
                                        <option selected={this.state.state==="NM"} value="NM">New Mexico</option>
                                        <option selected={this.state.state==="NY"} value="NY">New York</option>
                                        <option selected={this.state.state==="NC"} value="NC">North Carolina</option>
                                        <option selected={this.state.state==="ND"} value="ND">North Dakota</option>
                                        <option selected={this.state.state==="OH"} value="OH">Ohio</option>
                                        <option selected={this.state.state==="OK"} value="OK">Oklahoma</option>
                                        <option selected={this.state.state==="OR"} value="OR">Oregon</option>
                                        <option selected={this.state.state==="PA"} value="PA">Pennsylvania</option>
                                        <option selected={this.state.state==="PR"} value="PR">Puerto Rico</option>
                                        <option selected={this.state.state==="RI"} value="RI">Rhode Island</option>
                                        <option selected={this.state.state==="SC"} value="SC">South Carolina</option>
                                        <option selected={this.state.state==="SD"} value="SD">South Dakota</option>
                                        <option selected={this.state.state==="TN"} value="TN">Tennessee</option>
                                        <option selected={this.state.state==="TX"} value="TX">Texas</option>
                                        <option selected={this.state.state==="UT"} value="UT">Utah</option>
                                        <option selected={this.state.state==="VT"} value="VT">Vermont</option>
                                        <option selected={this.state.state==="VI"} value="VI">Virgin islands</option>
                                        <option selected={this.state.state==="VA"} value="VA">Virginia</option>
                                        <option selected={this.state.state==="WA"} value="WA">Washington</option>
                                        <option selected={this.state.state==="WV"} value="WV">West Virginia</option>
                                        <option selected={this.state.state==="WI"} value="WI">Wisconsin</option>
                                        <option selected={this.state.state==="WY"} value="WY">Wyoming</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-12">

                                <div className="col-md-6 col-12">
                                    <label htmsuperadminlFor="zip">Zip</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.zip}
                                        id="zip"
                                        type="text"
                                        error={errors.zip}
                                        className={classnames("form-control", {
                                            invalid: errors.zip
                                        })} />
                                    <span className="text-danger">{errors.zip}</span>
                                </div>


                                <div className="col-md-6 col-12">
                                    <label htmlFor="website">Website</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.website}
                                        id="website"
                                        type="text"
                                        error={errors.website}
                                        className={classnames("form-control", {
                                            invalid: errors.website
                                        })} />
                                    <span className="text-danger">{errors.website}</span>
                                </div>
                            </div>
                            <div className="row mt-12">

                                <div className="col-md-6 col-12">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.phone}
                                        id="phone"
                                        type="text"
                                        error={errors.phone}
                                        className={classnames("form-control", {
                                            invalid: errors.phone
                                        })} />
                                    <span className="text-danger">{errors.phone}</span>
                                </div>


                                <div className="col-md-6 col-12">
                                    <label htmlFor="fax">Fax</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.fax}
                                        id="fax"
                                        type="text"
                                        error={errors.fax}
                                        className={classnames("form-control", {
                                            invalid: errors.fax
                                        })} />
                                    <span className="text-danger">{errors.fax}</span>
                                </div>
                            </div>
                            <h2 className="text-left text-primary mt-3">Personal Detail</h2>
                            <div className="row mt-12">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="first_name">First Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.first_name}
                                        id="first_name"
                                        type="text"
                                        error={errors.first_name}
                                        className={classnames("form-control", {
                                            invalid: errors.first_name
                                        })} />
                                    <span className="text-danger">{errors.first_name}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.last_name}
                                        id="last_name"
                                        type="text"
                                        error={errors.last_name}
                                        className={classnames("form-control", {
                                            invalid: errors.last_name
                                        })} />
                                    <span className="text-danger">{errors.last_name}</span>
                                </div>

                            </div>
                            <div className="row mt-12">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="phone1">Mobile</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.phone1}
                                        id="phone1"
                                        type="text"
                                        error={errors.phone1}
                                        className={classnames("form-control", {
                                            invalid: errors.phone1
                                        })} />
                                    <span className="text-danger">{errors.phone1}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="email">Personal Email</label>
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.personal_email}
                                        id="personal_email"
                                        type="email"
                                        error={errors.personal_email}
                                        className={classnames("form-control", {
                                            invalid: errors.personal_email
                                        })} />
                                    <span className="text-danger">{errors.personal_email}</span>
                                </div>
                            </div>
                            <br></br>
                            <div className="row mt-2">
                                <div className="col-md-5 change-pass">
                                    <h5 htmlFor="bonarx">Change Password</h5>
                                    <input type="checkbox" class="form-check-input" defaultChecked={this.state.changePassword} onClick={this.toggleChange} id="changePassword" />
                                </div>
                                <div className="col-md-7">
                                    
                                </div>
                            </div>
                            {this.state.changePassword ? (
                                <div className="row mt-12">
                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            error={errors.password}
                                            id="password"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })}
                                        />
                                        <span className="text-danger">{errors.password}</span>
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <label htmlFor="password2">Confirm Password</label>
                                        <input
                                            autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.password2}
                                            id="password2"
                                            type="password"
                                            className={classnames("form-control", {
                                                invalid: errors.password2
                                            })}
                                        />
                                        <span className="text-danger">{errors.password2}</span>
                                    </div>
                                </div>
                            ) : ""}
                            <button
                                form="update-user"
                                type="submit"
                                className="btn btn-primary">
                                Update User
                            </button>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(Profile));
