import React, { Component, Fragment } from "react";
import { Row, Container, Col } from 'react-bootstrap';
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus, faDownload, faCog } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
// import moment from "moment"
import BusinessToolUpdateModal from "../partials/BusinessToolUpdateModal";
import Chart from "react-google-charts";
import BusinessToolAddModal from "../partials/BusinessToolAddModal";
import DatePicker from "react-datepicker";
import moment from "moment"
import ExpandGraph from "./View/ExpandGraph";
import ExportGraph from "./View/ExportGraph";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Expand extends Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);


        

        this.state = {
            // records: [],
            // totalCount: 0,
            filterData: {
                sort_order: { column: "report_date", order: "desc" }
            },
            // currentRecord: {
            // },
            revenue: undefined,
            purchase: undefined,
            ra_number: undefined,
            returns: undefined,
            new_rx: undefined,
            refill_rx: undefined,
            total_rx: undefined,
            totalNewRx: undefined,
            totalRefillRx: undefined,
            recordsGraph: [],
            rxGraph: [],
            totalRxGraph: [],
            isFilterOpen: true,
            from_date: moment().startOf('month').startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            brand_percent: 80,
            generic_percent: 15,
            otc_percent: 5,
            today: moment(),
            // expand: undefined,
            exporting: false
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        this.getUserData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            pharmacy_account: this.props.auth.user.final_user,
        }
        axios
            .post("/api/analytic-tool", filterData)
            .then(res => {
                var result = [];
                let recordsGraph = []
                var totalbrand = 0
                recordsGraph.push(['Date', 'Revenue', 'Purchase', 'Linear(Purchase)'])
                let rxGraph = []
                rxGraph.push(['Date', 'Daily RX'])
                let totalRxGraph = []
                totalRxGraph.push(['Date', 'New RX', 'Refill RX', 'Total RX'])
                var z = 0
                var totalRev = 0
                var totalRet = 0
                var totalest = 0
                var totalgen = 0
                var totalotc = 0
                var totalRx = 0
                var totalNewRx = 0
                var totalRefillRx = 0
                res.data.records.sort((a, b) => (a.report_date) > (b.report_date) ? 1 : -1);
                // add data rows in sheet array
                for (let i in res.data.records) {
                    let each = res.data.records[i]
                    //  delete record.id; delete record.__v
                    if (each.report_date !== '') {
                        each.orig_report_date = each.report_date
                        each.report_date = moment(each.report_date).format('L');

                    }
                    // var z = 0
                    // for (let k = 1; k <res.data.record.length; k++) {
                    z += parseFloat(each.purchase)
                    if (each.revenue === undefined) {
                        console.log('')
                    }
                    else {
                        totalRev += parseFloat(each.revenue)

                    }

                    totalRet += parseFloat(each.returns)
                    totalest += parseFloat(each.estimated_profit)
                    if (each.brand_percent === undefined) {
                        console.log("")
                    }
                    else {
                        totalbrand += parseFloat(each.brand_percent)
                    }
                    if (each.generic_percent === undefined) {
                        console.log('')
                    }
                    else {
                        totalgen += parseFloat(each.generic_percent)
                    }
                    if (each.otc_percent === undefined) {
                        console.log('')
                    }
                    else {
                        totalotc += parseFloat(each.otc_percent)
                    }
                    if (each.total_rx === undefined) {
                        console.log('')

                    }
                    else {
                        totalRx += parseFloat(each.total_rx)

                    }
                    totalNewRx += parseFloat(each.new_rx)
                    totalRefillRx += parseFloat(each.refill_rx)

                    // }


                    recordsGraph.push([
                        each.report_date,
                        parseFloat(each.revenue),
                        parseFloat(each.purchase),
                        parseFloat(each.purchase)
                    ])

                    rxGraph.push([
                        each.report_date,
                        parseFloat(each.total_rx)
                    ])

                    totalRxGraph.push([
                        each.report_date,
                        parseFloat(each.new_rx),
                        parseFloat(each.refill_rx),
                        parseFloat(each.total_rx)
                    ])
                    each.orig_revenue = each.revenue
                    each.orig_purchase = each.purchase
                    each.orig_returns = each.returns
                    each.revenue = each.revenue ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.revenue) : ""
                    each.purchase = each.purchase ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.purchase) : ""
                    each.returns = each.returns ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.returns) : ""
                    each.estimated_profit = each.estimated_profit ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.estimated_profit) : ""
                    each.report_date = moment(each.report_date).format('L')
                    result.push(each);


                }
                let totalavg = totalRev / (totalRx == 0 ? 1 : totalRx);
                console.log(totalavg)

                if (totalavg === 0) {
                    // console.log('')
                    totalavg = "0.00"

                }
                else {
                    totalavg = parseFloat(totalRev / totalRx).toFixed(2)
                }
                let totalgp = totalest / (totalRx == 0 ? 1 : totalRx);
                if (totalgp === 0) {
                    // console.log('')
                    totalgp = "0.00"

                }
                else {
                    totalgp = parseFloat(totalest / totalRx).toFixed(2)
                }
                let totalgpper = (totalest * 100 / (totalRx == 0 ? 1 : totalRx));
                if (totalgpper === 0) {
                    // console.log('')
                    totalgpper = "0.00"

                }
                else {
                    totalgpper = parseFloat(totalest * 100 / totalRev).toFixed(2)
                }


                z = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(z)
                totalRev = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalRev)
                totalRet = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalRet)
                totalest = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalest)
                totalbrand = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalbrand)
                totalgen = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalgen)
                totalotc = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(totalotc)

                this.setState({
                    records: result,
                    rxGraph: rxGraph,
                    totalRxGraph: totalRxGraph,
                    recordsGraph: recordsGraph,
                    totalCount: res.data.count,
                    loading: false,
                    z: z,
                    totalRev: totalRev,
                    totalRet: totalRet,
                    totalest: totalest,
                    totalbrand: totalbrand,
                    totalgen: totalgen,
                    totalotc: totalotc,
                    totalavg: totalavg,
                    totalgp: totalgp,
                    totalgpper: totalgpper,
                    totalRx: totalRx,
                    totalNewRx: totalNewRx,
                    totalRefillRx: totalRefillRx
                })

            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })

    }
    // disableFutureDt = current => {
    //     return current.isBefore(this.state.today)
    // }


    getUserData() {
        if (this.props.auth.user.rx_group !== "superadmin") {
            this.setState({ loading: true })
            let filterData = {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "report_date", order: "desc" }
            }

            filterData.search = {
                rx_group: this.props.auth.user.rx_group
            }
            axios
                .post("/api/user-data", filterData)
                .then(res => {
                    this.setState({
                        brand_percent: res.data.records[0].brand_percent ? res.data.records[0].brand_percent : this.state.brand_percent,
                        generic_percent: res.data.records[0].generic_percent ? res.data.records[0].generic_percent : this.state.generic_percent,
                        otc_percent: res.data.records[0].otc_percent ? res.data.records[0].otc_percent : this.state.otc_percent,
                        loading: false,
                    })
                })

                .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        }
    }


    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }
    deleteRecord(record) {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.finalDelete(record)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    finalDelete = (record) => {
        axios
            .post("/api/analytic-tool-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.getData();
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            from_date: undefined,
            to_date: undefined,
            filterData: {
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "report_date": "Date",
            "revenue": "Revenue",
            "purchase": "Purchase",
            "ra_number": "RA Number",
            "returns": "Return",
            "new_rx": "New RX",
            "refill_rx": "Refill RX",
            "total_rx": "Total RX",
            "estimated_profit": "Estimated Gross Profit",
            "brand_percent": "Brand",
            "generic_percent": "Generic",
            "otc_percent": "OTC",
            "rx_group": "Rx Group"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            delete record._id; delete record.id; delete record.__v

            record.revenue = '"' + record.revenue + '"'
            record.purchase = '"' + record.purchase + '"'
            record.returns = '"' + record.returns + '"'
            record.estimated_profit = '"' + record.estimated_profit + '"'
            record.brand_percent = "$" + record.brand_percent
            record.generic_percent = "$" + record.generic_percent
            record.otc_percent = "$" + record.otc_percent
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }
    goBack() {
        this.props.history.goBack();
    }

    // expand = (type) => {
    //     this.setState({ expand: type })
    // }

    exportPdf = () => {
        this.setState({
            exporting: true
        }, () => this._exportPdf())
    }

    _exportPdf = () => {
        html2canvas(document.querySelector("#graphPDF")).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
            this.setState({
                exporting: false
            })
        });

    }

    render() {
        return (
            <>
                <div className="d-flex" id="wrapper">
                
                    <div id="page-content-wrapper">
                    {/* <div >
                                {this.props.expand === "daily_report" ? (<h5>Daily Report</h5>) : null}
                                {this.props.expand === "daily_rx" ? (<h5>Total RX: {this.props.totalRx}</h5>) : null}
                                {this.props.expand === "total_rx" ? (<h5>Total RX: {this.props.totalRx}</h5>) : null}
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div> */}
                        <div className="container-fluid">
                            
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="npi">From Date</label>
                                    <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                    <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="first_name">To Date</label>
                                    <DatePicker selected={this.state.to_date} maxDate={moment().toDate()} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                    <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <button
                                    onClick={this.onSearch}
                                    type="button"
                                    className="btn btn-primary">
                                    Search
                                </button>
                                <br></br>
                                <br></br>

                    
                            </div>
                            <Container>
                                <Row>
                                    {this.state.recordsGraph.length > 1 && (
                                        <Col md={12}>
                                            <div className="card shadow-lg" >
                                                <div className="card-body">
                                                    <h5>Daily Reports
                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("daily_report")}>Expand</a> */}
                                                    </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'500px'}
                                                        chartType="ComboChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.recordsGraph}
                                                        options={{
                                                            // hAxis: { title: 'Date' },
                                                            // vAxis: { title: 'Dollars', format: "currency" },
                                                            seriesType: 'bars',
                                                            series: { 2: { type: 'line' } },
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '7' }}
                                                        formatters={[
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 2,
                                                                options: {
                                                                    prefix: '$',
                                                                    negativeColor: 'red',
                                                                    negativeParens: true,
                                                                },
                                                            },
                                                            {
                                                                type: 'NumberFormat',
                                                                column: 1,
                                                                options: {
                                                                    prefix: '$',
                                                                    negativeColor: 'red',
                                                                    negativeParens: true,
                                                                },
                                                            },
                                                        ]}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    )}

                                    {/* {this.state.rxGraph.length > 1 && (
                                        <Col md={6}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5>Daily RX: {this.state.totalRx}
                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("daily_rx")}>Expand</a> */}
                                                    {/* </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'500px'}
                                                        chartType="AreaChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.rxGraph}
                                                        options={{
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '6' }}
                                                    />

                                                </div>
                                            </div> */}
                                        {/* </Col>
                                    // )} */} 
                                    {/* {this.state.totalRxGraph.length > 1 && (
                                        <Col md={6}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5>New RX: {this.state.totalNewRx} */}
                                                        {/* <a href="#" style={{ "float": "right", "fontSize": "14px" }} data-toggle="modal" data-target="#show-graph" onClick={(e) => this.expand("total_rx")}>Expand</a> */}
                                                        {/* <span className="right">  Refill RX: {this.state.totalRefillRx}</span>
                                                    </h5>
                                                    <Chart
                                                        width={'100%'}
                                                        height={'500px'}
                                                        chartType="LineChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.totalRxGraph}
                                                        options={{
                                                            legend: { position: 'top' },
                                                        }}
                                                        rootProps={{ 'data-testid': '6' }}
                                                    />

                                                </div>
                                            </div>
                                        </Col> */}
                                    {/* )} */}
                                </Row>
                            </Container>
                            
                        </div>

                    </div>

                    {/* <ToastContainer />s */}

                </div>
                <br></br>
                <br></br>
            

                <button type="button" class="btn btn-dark " onClick={this.goBack}>Go Back</button>
                

               
            </>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Expand);
