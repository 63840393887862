import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from "react-toastify";
class support extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      message: '',
      subject: '',
      mobile: ''
    }
  }

  submitEmail(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url: "/api/support",
      data: this.state
    }).then((res) => {
      toast(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      this.setState({
        first_name: '',
        last_name: '',
        email: '',
        message: '',
        subject: '',
        mobile: ''
      })
    })
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  resetForm() {
    this.setState({
      first_name: '',
      last_name: '',
      email: '',
      message: '',
      subject: '',
      mobile: ''
    })
  }
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 center">
              <div className="section-title m-3">
                <h2 className="title">Contact Us</h2>

                <form id="contact-form" onSubmit={this.submitEmail.bind(this)}
                  method="POST">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <input placeholder="First Name" id="first_name" type="text"
                          className="form-control" required value={this.state.first_name}
                          onChange={this.onChange} />
                      </div>
                      <div className="col-md-6">
                        <input placeholder="Last Name" id="last_name" type="text"
                          className="form-control" required value={this.state.last_name} onChange=
                          {this.onChange} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <input placeholder="Email" id="email" type="email"
                          className="form-control" aria-describedby="emailHelp"
                          required value={this.state.email} onChange=
                          {this.onChange} />
                      </div>
                      <div className="col-md-6">
                        <input placeholder="Mobile Number" id="mobile" type="text"
                          className="form-control" required value={this.state.mobile} onChange=
                          {this.onChange} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea placeholder="Message" id="message"
                      className="form-control" rows="1"
                      required value={this.state.message}
                      onChange={this.onChange} />
                  </div>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>

          </div>

        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default support;