import React from "react";
import { Map, Circle, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Row, Container, Col } from 'react-bootstrap';
import Pincodes from '../../../utils/latlong.json';
import axios from "axios";
import moment from "moment"
import { toast, ToastContainer } from "react-toastify";
export class CustomMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            recordsClaim: [],
            recordsPhysician: [],
            from_date: moment().subtract(90, "days").toDate(),
            to_date: moment().toDate(),
            rx_group_associated: undefined,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            selectedPlaceSecond: {},
            activeMarkerSecond: {},
            showingInfoWindowSecond: false
        };
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(prevProps) {
        if (this.props.formData.from_date !== prevProps.formData.from_date || this.props.formData.toDate !== prevProps.formData.toDate || this.props.formData.rx_group_associated !== prevProps.formData.rx_group_associated) {
            this.setState({
                from_date: this.props.formData.from_date,
                to_date: this.props.formData.to_date,
                rx_group_associated: this.props.formData.rx_group_associated,
            }, () => this.getData())
        }
    };

    getData = () => {
        const formData = {
            from_date: this.props.formData.from_date,
            to_date: this.props.formData.to_date,
            ...(this.props.formData.rx_group_associated !== '' && { rx_group_associated: this.props.formData.rx_group_associated }),
        }
        axios
            .post("/api/physician-by-pincode", formData)
            .then(res => {
                const data = res.data.records.filter(function (element) {
                    return Pincodes[element._id] !== undefined;
                });
                let zipObj = {}
                for (let i = 0; i < res.data.doctors.length; i++) {
                    let each = res.data.doctors[i]
                    zipObj[each.npi] = each.practice_postal
                }
                let recordsPhysician = {}
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (recordsPhysician[zipObj[each.prescription_npi]] === undefined) {
                        recordsPhysician[zipObj[each.prescription_npi]] = 1
                    } else {
                        recordsPhysician[zipObj[each.prescription_npi]] = recordsPhysician[zipObj[each.prescription_npi]] + 1
                    }

                }
                console.log(recordsPhysician)
                this.setState({
                    recordsPhysician: recordsPhysician,
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })

        axios
            .post("/api/claim-by-pincode", formData)
            .then(res => {
                const data = res.data.records.filter(function (element) {
                    return Pincodes[element._id] !== undefined;
                });
                this.setState({
                    recordsClaim: data,
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMarkerClickSecond = (props, marker, e) =>
        this.setState({
            selectedPlaceSecond: props,
            activeMarkerSecond: marker,
            showingInfoWindowSecond: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                showingInfoWindowSecond: false,
                activeMarker: null,
                activeMarkerSecond: null
            })
        }
    };

    render() {
        return (
            <div className="row px-2">
                <div className="card shadow-lg" style={{ "width": "100%" }}>
                    <div className="card-body">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">MapView of Claims</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">MapView of Physician</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <Col md={12}>
                                    <div>
                                        <Map
                                            google={this.props.google}
                                            zoom={4.5}
                                            initialCenter={{ lat: 37.878, lng: -92.629 }}
                                            style={{ height: 500, position: 'relative' }}
                                            className={'map'}
                                            onClick={this.onMapClicked}
                                        >
                                            {
                                                this.state.recordsClaim.map((location, index) => {
                                                    return (
                                                        <Marker
                                                            key={index}
                                                            id={index}
                                                            position={Pincodes[location._id]}
                                                            onClick={this.onMarkerClick}
                                                            name={"ZipCode:" + location._id}
                                                            claims={"Claims:" + location.claims}
                                                            retail={"Retail:" + Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(location.retail)}
                                                        />
                                                    );
                                                })
                                            }
                                            <InfoWindow
                                                marker={this.state.activeMarker}
                                                visible={this.state.showingInfoWindow}>
                                                <div>
                                                    <div className="card text-dark bg-light mb-3" style={{ "maxWidth": "25rem" }}>
                                                        <div className="card-header">{this.state.selectedPlace.name}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">{this.state.selectedPlace.claims}</p>
                                                            <p className="card-text">{this.state.selectedPlace.retail}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        </Map >
                                    </div>
                                </Col>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <Col md={12}>
                                    <div>
                                        <Map
                                            google={this.props.google}
                                            zoom={4.5}
                                            initialCenter={{ lat: 37.878, lng: -92.629 }}
                                            style={{ height: 500, position: 'relative' }}
                                            className={'maps'}
                                            onClick={this.onMapClicked}
                                        >
                                            {
                                                Object.keys(this.state.recordsPhysician).map((each) => {
                                                    return (
                                                        <Marker
                                                            key={each}
                                                            id={each}
                                                            position={Pincodes[each]}
                                                            onClick={this.onMarkerClickSecond}
                                                            name={"Pincode:" + each}
                                                            prescription_npi={"Total Physicians:" + this.state.recordsPhysician[each]}
                                                        />
                                                    );
                                                })
                                            }
                                            <InfoWindow
                                                marker={this.state.activeMarkerSecond}
                                                visible={this.state.showingInfoWindowSecond}>
                                                <div>
                                                    <div className="card text-dark bg-light mb-3" style={{ "maxWidth": "25rem" }}>
                                                        <div className="card-header">{this.state.selectedPlaceSecond.name}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">{this.state.selectedPlaceSecond.prescription_npi}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        </Map >
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>


        );
    }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyDhjIMAgPWbXMQjetYIge4rdAdbA9o4F1I')
})(CustomMap)