import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import PrescriberRxDetail from "./View/PrescriberRxDetail"


import { Collapse } from 'react-collapse';

class PrescriberRx extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "Prscrbr_NPI",
                text: "NPI",
                className: "Prscrbr_NPI",
                align: "left",
                sortable: false,
                show_medication: true
            },
            {
                key: "Prscrbr_First_Name",
                text: "First Name",
                className: "Prscrbr_First_Name",
                align: "left",
                sortable: false,
            },
            {
                key: "Prscrbr_Last_Org_Name",
                text: "Last Name",
                className: "Prscrbr_Last_Org_Name",
                align: "left",
                sortable: false,
            },
            {
                key: "Prscrbr_Type",
                text: "Type",
                className: "Prscrbr_Type",
                align: "left",
                sortable: false,
            },
            {
                key: "Prscrbr_City",
                text: "City",
                className: "Prscrbr_City",
                align: "left",
                sortable: false,
            },
            {
                key: "Prscrbr_State_Abrvtn",
                text: "State",
                className: "Prscrbr_State_Abrvtn",
                align: "left",
                sortable: false,
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Physician List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                print: false,
                csv: false
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                limit: 20,
                sort_order: { column: "ndc", order: "asc" }
            },
            currentRecord: {
            },
            state: undefined,
            npi: undefined,
            first_name: undefined,
            last_name: undefined,
            loading: false,
            isFilterOpen: true,
            prescriberRx: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let query = ""
        if (this.state.npi !== "" && this.state.npi !== undefined) {
            query += "filter[npi][condition][path]=Prscrbr_NPI&filter[npi][condition][operator]=%3D&filter[npi][condition][value]=" + this.state.npi + "&"
        }
        if (this.state.first_name !== "" && this.state.first_name !== undefined) {
            query += "filter[firstName][condition][path]=Prscrbr_First_Name&filter[firstName][condition][operator]=CONTAINS&filter[firstName][condition][value]=" + this.state.first_name + "&"
        }
        if (this.state.last_name !== "" && this.state.last_name !== undefined) {
            query += "filter[lastName][condition][path]=Prscrbr_Last_Org_Name&filter[lastName][condition][operator]=CONTAINS&filter[lastName][condition][value]=" + this.state.last_name + "&"
        }
        if (this.state.state !== "" && this.state.state !== undefined) {
            query += "filter[state][condition][path]=Prscrbr_State_Abrvtn&filter[state][condition][operator]=%3D&filter[state][condition][value]=" + this.state.state + "&"
        }
        let offset = (parseInt(this.state.filterData.page_number) - 1) * 20
        let url = "https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer?" + query + "size=20&sort=Prscrbr_NPI&offset=" + offset + "&_table=lookup&column=Prscrbr_First_Name,Prscrbr_Last_Org_Name,Prscrbr_State_Abrvtn,Prscrbr_NPI,Prscrbr_City,Prscrbr_Type"
        axios
            .get(url)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.data.length; i++) {
                    let each = res.data.data[i]
                    each.Prscrbr_NPI = each[3]
                    each.Prscrbr_First_Name = each[0]
                    each.Prscrbr_Last_Org_Name = each[1]
                    each.Prscrbr_City = each[4]
                    each.Prscrbr_State_Abrvtn = each[2]
                    each.Prscrbr_Type = each[5]
                    result.push(each)
                }
                this.setState({
                    records: result,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })

        axios
            .get("https://data.cms.gov/data-api/v1/dataset/c1a312b1-acc6-40d0-a630-14e5ddb74fd3/data-viewer/stats?" + query + "sort=Prscrbr_Last_Org_Name,Prscrbr_First_Name,Prscrbr_State_Abrvtn&_table=lookup&column=Prscrbr_First_Name,Prscrbr_Last_Org_Name,Prscrbr_State_Abrvtn,Prscrbr_NPI,Prscrbr_City,Prscrbr_Type")
            .then(res => {
                this.setState({
                    totalCount: res.data.data.found_rows,
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            state: undefined,
            filterData: {
                page_number: 1,
                limit: 20,
                // sort_order: { column: "dea", order: "asc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    showMedication = (physicianMedication) => {
        this.setState({prescriberRx:physicianMedication.Prscrbr_NPI})
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <PrescriberRxDetail prescriberRx={this.state.prescriberRx} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Prescriber Rx</h1>
                        <p className="help-text font-weight-bold h6">Improve your marketing by viewing and downloading prescribing data for physicians in your area. The data does not represent cash and certain third party plans. </p>
                        <div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="npi">NPI</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.npi}
                                                id="npi"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="first_name">First Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.first_name}
                                                id="first_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="last_name">Last Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.last_name}
                                                id="last_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="state">State</label>
                                            <select name="state" onClick={this.onChangeSelect} className="form-control" id="state">
                                                <option value="" selected="">Please select</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="GU">Guam</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VI">Virgin islands</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                            showMedication={this.showMedication}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(PrescriberRx);
