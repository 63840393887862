import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { section } from 'react-bootstrap/Form';
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";

class Drugcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            drugname: '',
            ndc: '',
            phone:''
            
        }
    }
    submitEmail(e) {
        e.preventDefault();
        axios({
          method: "POST",
          url: "/api/supports",
          data: this.state
        }).then((res) => {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          })
          this.setState({
            name: '',
            drugname: '',
            email: '',
            ndc: '',
            phone: '',          })
        })
      }
      onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
      };
    
    
    resetForm() {
        this.setState({ name: '', email: '', drugname: '', ndc: '',phone:''})
    }
    render() {
        return (
            <div>
                <div className="modal fade" id="modal1" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title"></h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div> */}
                            <div className="modal-body">
                                <form >
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                // error={errors.name}
                                                className={classnames("form-control"
                                                    
                                                )} />
                                            {/* <span className="text-danger">{errors.name}</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                id="email"
                                                type="text"
                                                // error={errors.email}
                                                className={classnames("form-control"
                                                    
                                                )} />
                                            {/* <span className="text-danger">{errors.email}</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phone">Phone</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.phone}
                                                id="phone"
                                                type="text"
                                                // error={errors.email}
                                                className={classnames("form-control"
                                                    
                                                )} />
                                            {/* <span className="text-danger">{errors.email}</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="drugname">Drug Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.drugname}
                                                id="drugname"
                                                type="text"
                                                // error={errors.drugname}
                                                className={classnames("form-control"
                                                    // invalid: errors.drugname
                                            )} />
                                            {/* <span className="text-danger">{errors.drugname}</span> */}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ndc">NDC</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                // error={errors.ndc}
                                                className={classnames("form-control"
                                                    // invalid: errors.ndc
                                                )} />
                                            {/* <span className="text-danger">{errors.ndc}</span> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    // form="modal1"
                                    type="submit"
                                    onClick={this.submitEmail.bind(this)}
                                    data-dismiss="modal"
                                    // data-toggle="modal" data-target="#add-drug-modal"
                                    className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Drugcon;