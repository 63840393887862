import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"


import { Collapse } from 'react-collapse';

class Nadac extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc_description",
                text: "NDC Description",
                className: "ndc_description",
                align: "left",
                sortable: true,
            },
            {
                key: "nadac_per_unit",
                text: "NADAC per unit",
                className: "nadac_per_unit",
                align: "left",
                sortable: true,
            },
            {
                key: "effective_date",
                text: "Effective Date",
                className: "effective_date",
                align: "left",
                sortable: true,
            },
            {
                key: "pricing_unit",
                text: "Pricing unit",
                className: "pricing_unit",
                align: "left",
                sortable: true,
            },
            {
                key: "pharmacy_type_indicator",
                text: "Pharmacy Type Indicator",
                className: "pharmacy_type_indicator",
                align: "left",
                sortable: true,
            },
            {
                key: "otc",
                text: "OTC",
                className: "otc",
                align: "left",
                sortable: true,
            },
            // {
            //     key: "explanation_code",
            //     text: "Explanation Code",
            //     className: "explanation_code",
            //     align: "left",
            //     sortable: true,
            // },
            {
                key: "classification_for_rate_setting",
                text: "Rate Setting",
                className: "classification_for_rate_setting",
                align: "left",
                sortable: true,
            },
            {
                key: "as_of_date",
                text: "As For date",
                className: "as_of_date",
                align: "left",
                sortable: true,
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Drug List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "ndc", order: "asc" }
            },
            currentRecord: {
            },
            ndc: undefined,
            medication: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = {}
        filterData.conditions = []
        if(this.state.ndc){
            filterData.conditions.push(
                {
                    "resource": "t",
                    "property": "ndc",
                    "value": this.state.ndc,
                    "operator": "="
                }
            )
        }
        if(this.state.medication){
            filterData.conditions.push(
                {
                    "resource": "t",
                "property": "ndc_description",
                "value": "%"+this.state.medication+"%",
                "operator": "like"
                }
            )
        }
        filterData.sorts = [
            {
                property: "as_of_date",
                order: "desc",
            }
        ]
        axios
            .post("https://data.medicaid.gov/api/1/datastore/query/d5eaf378-dcef-5779-83de-acdd8347d68e/0", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.results.length; i++) {
                    let each = res.data.results[i]
                    if (each.ndc !== "") {
                        each.effective_date = each.effective_date ? moment(each.effective_date).format('L') : ""
                        each.as_of_date = each.as_of_date ? moment(each.as_of_date).format('L') : ""
                        result.push(each)
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            proprietary_name: undefined,
            ndc: undefined,
            non_proprietary_name: undefined,
            labeler: undefined,
            product_type: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "ndc_description": "NDC Description",
            "ndc": "NDC",
            "nadac_per_unit": "NADAC Per Unit",
            "effective_date": "Effective Date",
            "pricing_unit": "Pricing Unit",
            "pharmacy_type_indicator": "Pharmacy Type Indicator",
            "otc": "OTC",
            "explanation_code": "Explanation Code",
            "classification_for_rate_setting": "Rate Setting",
            "as_of_date": "As For date"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            delete record._id
            delete record.id
            delete record.__v
            if (record.effective_date !== '') {
                record.effective_date = moment(record.effective_date).format('L');
            }
            if (record.as_of_date !== '') {
                record.as_of_date = moment(record.as_of_date).format('L');
            }
            record.explanation_code = '"' + record.explanation_code + '"'

            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">NADAC</h1>
                        <div>
                            <p className="help-text font-weight-bold h6">National Average Drug Acquisition Cost (NADAC) data and comparison data are updated weekly. Each month we post new data, including findings from the previous month’s survey and weekly price changes that have occurred before the release of the next month’s NADAC data.
                            To be inserted between the NADAC and search fields</p>
                            {/* <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div> */}
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">Search By NDC</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="medication">Search By Medication</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.medication}
                                                id="medication"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            false={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Nadac);
