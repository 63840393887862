import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class DeaMapping extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: [],
            savedItems: {},
        };
    }
    componentDidMount() {
        this.getData()
    }

    getData = () => {
        axios
            .post(`/api/dea-mapping`)
            .then(res => {
                this.setState({
                    record: res.data.records,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    onChange = (e) => {
        this.setState({
            savedItems: {
                ...this.state.savedItems,
                [e.target.id]: e.target.value
            }
        });
    }

    save = () => {
        this.setState({ loading: true })
        let savedItems = this.state.savedItems
        Object.keys(savedItems).forEach((k) => savedItems[k] == "" && delete savedItems[k]);
        axios
            .post("/api/save-mapped-dea", savedItems)
            .then(res => {
                this.getData()
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div className="container-fluid">
                    
                    {this.state.loading &&
                        <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    <h1 className="mt-2 text-primary">Map DEA to NPI</h1>
                    <br></br>
                    <button type="button" onClick={this.save} className="btn btn-primary" style={{ float: 'right' }}>Save</button>
                    <table>
                        <tbody>
                            {this.state.record && this.state.record.map(each => {
                                if (each !== '') {
                                    return (
                                        <tr key={each} className="form-group col-md-6">
                                            <td><label htmlFor={each}>{each}</label> : </td>
                                            <td><input
                                                onChange={this.onChange}
                                                id={each}
                                                type="text"
                                                className="form-control"
                                            /></td>
                                            {/* <div className="form-group col-md-6" key={each}>
                                                            <label htmlFor="state">{each}</label> : 
                                                            <input
                                                                onChange={this.onChange}
                                                                id={each}
                                                                type="text"
                                                                className="form-control"
                                                            />
                                                        </div> */}
                                        </tr>
                                    )
                                }
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                <ToastContainer/>
            </div>
        )
    }
}
export default withRouter(DeaMapping)