import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from "react-collapse";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
class Users extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "name",
        text: "Account Name",
        className: "name",
        align: "left",
        sortable: true,
        link: "user",
      },
      {
        key: "npi",
        text: "NPI",
        className: "npi",
        align: "left",
        sortable: true,
      },
      {
        key: "rx_group",
        text: "RxGroup",
        className: "rx_group",
        align: "left",
        sortable: true,
      },
      {
        key: "dea",
        text: "DEA",
        className: "dea",
        align: "left",
        sortable: true,
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email Address",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        className: "phone",
        align: "left",
        sortable: false,
      },
      {
        key: "date",
        text: "Register Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "subscriptionId",
        text: "Subscription Id",
        className: "subscriptionId",
        align: "left",
        sortable: true,
      },
      // {
      //     key: "fax",
      //     text: "Fax",
      //     className: "fax",
      //     align: "left",
      //     sortable: false,
      // },
    ];
    if (this.props.auth.user.superadmin) {
      this.columns.push(
        {
          key: "rx_bin",
          text: "RxBIN",
          className: "rx_bin",
          align: "left",
          sortable: true,
        },

        {
          key: "upline",
          text: "Upline",
          className: "upline",
          align: "left",
          sortable: false,
        },
        {
          key: "disable",
          text: "Disable",
          className: "disable",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.disable}
                  icons={false}
                  onChange={() => this.toggleCheckbox("disable", record)}
                />
              </label>
            );
          },
        },
        {
          key: "bonaRx",
          text: "BRx",
          className: "bonaRx",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.bonaRx}
                  icons={false}
                  onChange={() => this.toggleCheckbox("bonaRx", record)}
                />
              </label>
            );
          },
        },
        {
          key: "tabulaRx",
          text: "TRx",
          className: "tabulaRx",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.tabulaRx}
                  icons={false}
                  onChange={() => this.toggleCheckbox("tabulaRx", record)}
                />
              </label>
            );
          },
        },
        {
          key: "rxConnexion",
          text: "RCRx",
          className: "rxConnexion",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.rxConnexion}
                  icons={false}
                  onChange={() => this.toggleCheckbox("rxConnexion", record)}
                />
              </label>
            );
          },
        },
        {
          key: "rxReach",
          text: "RxR",
          className: "rxReach",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.rxReach}
                  icons={false}
                  onChange={() => this.toggleCheckbox("rxReach", record)}
                />
              </label>
            );
          },
        },
        {
          key: "alereRx",
          text: "ALx",
          className: "alereRx",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <label>
                <Toggle
                  checked={record.alereRx}
                  icons={false}
                  onChange={() => this.toggleCheckbox("alereRx", record)}
                />
              </label>
            );
          },
        },

        {
          key: "expiration_bona_format",
          text: "BRx Exp Date",
          className: "expiration_bona_format",
          align: "left",
          sortable: false,
        },
        {
          key: "expiration_tabula_format",
          text: "TRx Exp Date",
          className: "expiration_tabula_format",
          align: "left",
          sortable: true,
        },
        {
          key: "expiration_rxconn_format",
          text: "RCRx Exp Date",
          className: "expiration_rxconn_format",
          align: "left",
          sortable: false,
        },
        // {
        //     key: "assigned_date",
        //     text: "Registration Date",
        //     className: "assigned_date",
        //     align: "left",
        //     sortable: false,
        // },
        {
          key: "street",
          text: "Street Address",
          className: "street",
          align: "left",
          sortable: false,
        },
        {
          key: "city",
          text: "City",
          className: "city",
          align: "left",
          sortable: true,
        },
        {
          key: "state",
          text: "State",
          className: "state",
          align: "left",
          sortable: true,
        },
        {
          key: "zip",
          text: "Zip",
          className: "zip",
          align: "left",
          sortable: true,
        },
        {
          key: "action",
          text: "Action",
          className: "action",
          width: 100,
          align: "left",
          sortable: false,
          cell: (record) => {
            return (
              <Fragment>
                <button
                  data-toggle="modal"
                  data-target="#update-user-modal"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => this.deleteRecord(record)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </Fragment>
            );
          },
        }
      );
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Users",
      no_data_text: "No user found!",
      button: {
        excel: false,
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      toggleNav: true,
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      name: undefined,
      rx_group: undefined,
      first_name: undefined,
      last_name: undefined,
      website: undefined,
      phone: undefined,
      fax: undefined,
      loading: true,
      isFilterOpen: true,
      state: undefined,
      bonaRx: undefined,
      tabulaRx: undefined,
      disable: undefined,
      multistore: undefined,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.name !== "" && { name: this.state.name }),
      ...(this.state.rx_group !== "" && { rx_group: this.state.rx_group }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.website !== "" && { website: this.state.website }),
      ...(this.state.phone !== "" && { phone: this.state.phone }),
      ...(this.state.fax !== "" && { fax: this.state.fax }),
      ...(this.state.state !== "" && { state: this.state.state }),
      ...(this.state.bonaRx !== "" && { bonaRx: this.state.bonaRx }),
      ...(this.state.tabulaRx !== "" && { tabulaRx: this.state.tabulaRx }),
      ...(this.state.disable !== "" && { disable: this.state.disable }),
      ...(this.state.multistore !== "" && {
        multistore: this.state.multistore,
      }),
      userlist: true,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        // let result = []
        // var a
        for (let i = 0; i < res.data.records.length; i++) {
          // if (res.data.records[i].bonaRx === true) {
          //     res.data.records[i].bonaRx = "Yes"
          // }
          // if (res.data.records[i].tabulaRx === true) {
          //     res.data.records[i].tabulaRx = "Yes"
          // }
          // if (res.data.records[i].alereRx === true) {
          //     res.data.records[i].alereRx = "Yes"
          // }
          // res.data.record[i].assigned_date = moment(res.data.records[i].assigned_date).format('l')
          res.data.records[i].expiration_bona_format = res.data.records[i]
            .expiration_bona
            ? moment(res.data.records[i].expiration_bona)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_tabula_format = res.data.records[i]
            .expiration_tabula
            ? moment(res.data.records[i].expiration_tabula)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].expiration_rxconn_format = res.data.records[i]
            .expiration_rxconn
            ? moment(res.data.records[i].expiration_rxconn)
                .add(5, "hours")
                .format("L")
            : "";
          res.data.records[i].date = res.data.records[i].date
            ? moment(res.data.records[i].date).add(5, "hours").format("L")
            : "";
        }
        this.setState({
          records: res.data.records,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  deleteRecord(record) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.finalDelete(record),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  finalDelete = (record) => {
    axios
      .post("/api/user-delete", {
        _id: record._id,
        // delete: this.props.auth.user.user_type!=="Technician"?true:false,
        // email: this.props.auth.user.email
      })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    this.getData();
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  toggleCheckbox = (type, record) => {
    const newUser = {
      id: record._id,
    };
    newUser[type] = record[type] ? false : true;
    axios
      .post("/api/user-update", newUser)
      .then((res) => {
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.getData();
      })
      .catch((err) => console.log(err));
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        name: undefined,
        rx_group: undefined,
        first_name: undefined,
        last_name: undefined,
        website: undefined,
        phone: undefined,
        fax: undefined,
        state: undefined,
        bonaRx: undefined,
        tabulaRx: undefined,
        disable: undefined,
        multistore: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  navBar = () => {
    this.setState({ toggleNav: !this.state.toggleNav });
  };

  exportToCSV = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "asc" },
      search: {},
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        toast.success("Dowloading...Please Wait!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var headers = {
          name: "Account Name",
          email: "Email",
          rx_bin: "Rx Bin",
          rx_group: "Rx Group",
          upline: "Upline",
          assigned_date: "Assigned Date",
          first_name: "First Name",
          last_name: "Last Name",
          street: "Street",
          city: "City",
          state: "State",
          zip: "Zip",
          website: "Website",
          phone: "Phone",
          date: "Date",
          npi: "NPI",
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];
          record.name = '"' + record.name + '"';
          record.street = '"' + record.street + '"';

          delete record._id;
          delete record.id;
          delete record.fax;
          delete record.__v;
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  convertToCSV(objArray) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToPDF = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 2000,
      sort_order: { column: "_id", order: "asc" },
      search: {},
    };
    axios.post("/api/user-data", filterData).then((res) => {
      this.setState({ loading: false });
      let sTable = "";
      sTable += "<table>";
      sTable += "<thead>";
      sTable += "<tr>";
      for (let column of this.columns) {
        sTable += "<th>" + column.text + "</th>";
      }
      sTable += "</tr>";
      sTable += "</thead>";
      sTable += "<tbody>";
      for (let i in res.data.records) {
        let record = res.data.records[i];
        sTable += "<tr>";
        for (let column of this.columns) {
          if (column.cell && typeof column.cell === "function") {
            sTable += "<td></td>";
          } else if (record[column.key]) {
            sTable += "<td>" + record[column.key] + "</td>";
          } else {
            sTable += "<td></td>";
          }
        }
        sTable += "</tr>";
      }
      sTable += "</tbody>";
      sTable += "</table>";

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;textAlign:left;}";
      style = style + "</style>";

      var win = window.open("", "_blank");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.config.filename + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write("<h1>" + this.config.filename + "</h1>");
      win.document.write(sTable);
      win.document.write("</body></html>");
      win.print();
      win.close();
    });
  };

  render() {
    return (
      <div className="d-flex group-list" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}

        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.props.auth.user.superadmin && (
              <button
                className="btn btn-outline-primary float-right mt-3 mr-2 a"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add User
              </button>
            )}
            <h1 className="mt-2 text-primary">Group List</h1>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row" style={{ width: "1000px" }}>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">Account Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rx_group">Rx Group</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.rx_group}
                        id="rx_group"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="rx_group">Rx Group</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.rx_group}
                        id="rx_group"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-row" style={{ width: "1000px" }}>
                    <div className="form-group col-md-3">
                      <label htmlFor="phone">Phone</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.phone}
                        id="phone"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="fax">Fax</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.fax}
                        id="fax"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="disable">Disable</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="disable"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="bonaRx">BRx</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="bonaRx"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="tabulaRx">TRx</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="tabulaRx"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="tabulaRx">MustiStore Owner</label>
                      <select
                        className="form-control"
                        onChange={this.onChangeSelect}
                        id="multistore"
                      >
                        <option value="">Any</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Any
                        </option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AS">American Samoa</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="AA">Armed Forces America</option>
                        <option value="AE">
                          Armed Forces Europe /Canada / Middle East / Africa
                        </option>
                        <option value="AP">Armed Forces Pacific</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District of Columbia</option>
                        <option value="FM">
                          Federated States of Micronesia
                        </option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="GU">Guam</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MP">Mariana Islands, Northern</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VI">Virgin islands</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(Users);
