import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faWrench,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import moment from "moment";

class Navbar extends Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  };

  Subscribe = () => {};

  render() {
    const { user } = this.props.auth;
    return (
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          {/* <a className="navbar-brand" href="/">BonaRx Analytics</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            {!["/landing"].includes(window.location.pathname) && (
              <button type="button" class="btn btn-warning text-dark">
                <a
                  className="text-dark"
                  href="https://tabula.psgarx.com/landing"
                >
                  <FontAwesomeIcon icon={faBackward} />
                  {"  "}
                  <b>Go to Main Menu</b>
                </a>
              </button>
            )}
            {this.props.auth.user.expiration_tabula &&
              !this.props.auth.user.subscriptionId &&
              !this.props.auth.user.multistore && (
                <Link to="/subscribe">
                  <a className="nav-link" href="#" style={{ color: "#fff" }}>
                    Your Subscription will expire on{" "}
                    {moment(this.props.auth.user.expiration_tabula)
                      .add(5, "hours")
                      .format("L")}{" "}
                    <button
                      className="btn btn-success"
                      onClick={this.Subscribe}
                    >
                      Subscribe Now
                    </button>
                  </a>
                </Link>
              )}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="lookups"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faWrench} />
                  {"  "}My Account
                </a>
                <ul className="dropdown-menu" aria-labelledby="lookups">
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/profile"
                      className="list-group-item"
                    >
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/credits"
                      className="list-group-item"
                    >
                      Credits
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/transactions"
                      className="list-group-item"
                    >
                      Transactions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/subscribe"
                      className="list-group-item"
                    >
                      Subscription
                    </NavLink>
                  </li>
                  {/* <a href={"http://analytics.psgarx.com?reference="+localStorage.getItem("jwtToken")} target="_blank" className="list-group-item" style={{border:"none"}}>BonaRx</a>
                                    <a href={"https://admin.rxreach.com/user/login"} target="_blank" className="list-group-item" style={{ border: "none" }}>RxReach</a>
                                    <a href={"https://app.xpedicare.com/#/?company=psgarx"} target="_blank" className="list-group-item" style={{ border: "none" }}>XPedicareRx</a> */}
                </ul>
              </li>
              <li className="nav-item dropdown active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="solutions"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <ul className="dropdown-menu" aria-labelledby="solutions">
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-xpedicare"
                      className="list-group-item "
                    >
                      XPediCareRx®
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-bonarx"
                      className="list-group-item "
                    >
                      BonaRx®
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-alererx"
                      className="list-group-item "
                    >
                      AlereRx®
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-rxreach"
                      className="list-group-item "
                    >
                      RxReach®
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-tabularx"
                      className="list-group-item "
                    >
                      TabulaRx®
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-rxconnexion"
                      className="list-group-item "
                    >
                      RxConnexion®
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://landing.rphally.com/about-rphally"
                      target="_blank"
                      className="list-group-item "
                    >
                      RPhAlly
                    </a>
                  </li>
                  {/* <a href={"http://analytics.psgarx.com?reference="+localStorage.getItem("jwtToken")} target="_blank" className="list-group-item" style={{border:"none"}}>BonaRx</a>
                                    <a href={"https://admin.rxreach.com/user/login"} target="_blank" className="list-group-item" style={{ border: "none" }}>RxReach</a>
                                    <a href={"https://app.xpedicare.com/#/?company=psgarx"} target="_blank" className="list-group-item" style={{ border: "none" }}>XPedicareRx</a> */}
                </ul>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#" onClick={this.onLogoutClick}>
                  Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
