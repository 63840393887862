import React from 'react'
import classnames from "classnames";

import axios from 'axios';
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class CsvUploadModal extends React.Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            selectedFile: null,
            loading: false,
            lastDate: undefined
        };
    }

    componentDidMount() {
        axios.post("/api/lastdate")
            .then(res => {
                this.setState({ lastDate: res.data.lastDate })
            })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-user-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({
            selectedFile: e.target.files[0],
            loaded: 0,
        })
    };

    onUpload = e => {
        e.preventDefault();
        const data = new FormData()
        data.append('file', this.state.selectedFile)
        this.setState({ loading: true })
        axios.post("/api/upload-records", data, { // receive two parameter endpoint url ,form data 
        })
            .then(res => { // then print response status
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
                $('#add-user-modal').modal('hide');
                this.setState({ loading: false })
                this.props.getData();
            })
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload File</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUpload} id="add-user" encType="multipart/form-data">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">File</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="file"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <p className="float-left">Last Updated Date : {this.state.lastDate}</p>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Upload
                                </button>
                            </div>
                            <ClipLoader color="#e2e2e2" loading={this.state.loading} css={override} size={150} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(CsvUploadModal));
