import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from "react-collapse";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
class InternalDoctor extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "speciality",
        text: "Speciality",
        className: "speciality",
        align: "left",
        sortable: true,
      },
      {
        key: "email_def",
        text: "Email Address",
        className: "email email-blurr",
        align: "left",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        className: "phone",
        align: "left",
        sortable: true,
      },
      {
        key: "address",
        text: "Address",
        className: "address",
        align: "left",
        sortable: true,
      },
      {
        key: "city",
        text: "City",
        className: "city",
        align: "left",
        sortable: true,
      },
      {
        key: "state",
        text: "State",
        className: "state",
        align: "left",
        sortable: true,
      },
      {
        key: "zip",
        text: "Zip",
        className: "zip",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Physician",
      no_data_text: "No Physician found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      toggleNav: true,
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "asc" },
      },
      currentRecord: {},
      first_name: undefined,
      last_name: undefined,
      speciality: undefined,
      phone: undefined,
      zip: undefined,
      loading: true,
      isFilterOpen: true,
      state: undefined,
      current_points: 0,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getCurrentPoints();
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.first_name !== "" &&
        this.state.first_name !== undefined && {
          first_name: this.state.first_name,
        }),
      ...(this.state.last_name !== "" &&
        this.state.last_name !== undefined && {
          last_name: this.state.last_name,
        }),
      ...(this.state.speciality !== "" && {
        speciality: this.state.speciality,
      }),
      ...(this.state.phone !== "" && { phone: this.state.phone }),
      ...(this.state.zip !== "" && { zip: this.state.zip }),
      ...(this.state.state !== "" && { state: this.state.state }),
    };
    axios
      .post("/api/internal-doctor", filterData)
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          each.email_def = "****************";
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  getCurrentPoints = () => {
    let { filterData } = this.state;
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState({
          current_points: res.data.records[0].points,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        first_name: undefined,
        last_name: undefined,
        speciality: undefined,
        phone: undefined,
        zip: undefined,
        state: undefined,
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  exportToCSVLocal = () => {
    this.setState({ loading: true });
    let filterData = {
      points: this.state.totalCount,
      email: this.props.auth.user.final_user,
    };
    axios.post("/api/deduct-points", filterData).then((res) => {
      toast.success("Dowloading...Please Wait!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      var headers = {
        first_name: "First Name",
        last_name: "Last Name",
        speciality: "Speciality",
        email: "Email Address",
        phone: "Phone",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip",
      };
      // // add columns in sheet array
      // for (let column of this.props.columns) {
      //     headers[column.key] = '"' + column.text + '"';
      // }
      let filterData = {
        page_number: 1,
        page_size: 2000000,
        sort_order: { column: "_id", order: "asc" },
      };
      filterData.search = {
        ...(this.state.first_name !== "" &&
          this.state.first_name !== undefined && {
            first_name: this.state.first_name,
          }),
        ...(this.state.last_name !== "" &&
          this.state.last_name !== undefined && {
            last_name: this.state.last_name,
          }),
        ...(this.state.speciality !== "" && {
          speciality: this.state.speciality,
        }),
        ...(this.state.phone !== "" && { phone: this.state.phone }),
        ...(this.state.zip !== "" && { zip: this.state.zip }),
        ...(this.state.state !== "" && { state: this.state.state }),
      };
      axios.post("/api/internal-doctor-email", filterData).then((res) => {
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false });
        for (let i in res.data.records) {
          let record = res.data.records[i];
          delete record._id;
          delete record.id;
          delete record.payment_type;
          delete record.user_initial;
          delete record.__v;
          delete record.notes;
          delete record.doctor_npi;
          delete record.patient_zip;
          delete record.pharmacy_account;
          delete record.user_created;
          records.push(record);
        }
        if (headers) {
          records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.getCurrentPoints();
          }
        }
      });
    });
  };

  goToCredit = () => {
    this.props.history.push("/credits?show-popup=1");
  };

  exportToCSV = (from, to) => {
    if (parseInt(this.state.current_points) < parseInt(this.state.totalCount)) {
      confirmAlert({
        title: "Low Credit Notification",
        message: `You need to purchase addditional credit to download`,
        buttons: [
          {
            label: "Add",
            onClick: () => this.goToCredit(),
          },
          {
            label: "I will do later.",
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Credit Usage Notification",
        message:
          this.state.totalCount + " credits will be deducted for your account.",
        buttons: [
          {
            label: "Confirm",
            onClick: () => this.exportToCSVLocal(),
          },
          {
            label: "Cancel",
            onClick: () => {},
          },
        ],
      });
    }
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : array[i][keys[index]] + ",";
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  render() {
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}

        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.props.auth.user.superadmin && (
              <button
                className="btn btn-outline-primary float-right mt-3 mr-2 a"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Records
              </button>
            )}
            <h1 className="mt-2 text-primary">Physicians Email Database</h1>
            <p className="help-text font-weight-bold h6">
              Internal Medicine/Family physician email database is available for
              purchase on a per credit basis.
            </p>
            <button className="btn btn-outline-primary float-right mt-3 mr-2">
              <Link to="/credits?show-popup=1">
                <FontAwesomeIcon icon={faPlus} /> Buy Credit
              </Link>
            </button>
            <button className="btn btn-outline-primary mt-3 mr-2">
              <p
                className="help-text font-weight-bold h6"
                style={{ marginBottom: "0" }}
              >
                Credit Available: {this.state.current_points}
              </p>
            </button>
            <br></br>
            <br></br>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row" style={{ width: "1000px" }}>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="speciality">Speciality</label>
                      <select
                        name="speciality"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="speciality"
                      >
                        <option value="" selected="">
                          Any
                        </option>
                        <option value="Family Medicine">Family Medicine</option>
                        <option value="Internal Medicine">
                          Internal Medicine{" "}
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="zip">Zip Code</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.zip}
                        id="zip"
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Any
                        </option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AS">American Samoa</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="AA">Armed Forces America</option>
                        <option value="AE">
                          Armed Forces Europe /Canada / Middle East / Africa
                        </option>
                        <option value="AP">Armed Forces Pacific</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District of Columbia</option>
                        <option value="FM">
                          Federated States of Micronesia
                        </option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="GU">Guam</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MP">Mariana Islands, Northern</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VI">Virgin islands</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Filter
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              // exportToPDF={this.exportToPDF}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps, { logoutUser })(InternalDoctor);
