import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

class FilterDate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            from_date:  moment().subtract(90, "days").toDate(),
            to_date: moment().toDate(),
            rx_group_associated: undefined
        };
    }

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }

    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    onSearch = () => {
        this.setState({
            formData: {
                from_date: this.state.from_date,
                to_date: this.state.to_date,
                ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated }),
            }
        }, () => this.props.onSearch(this.state.from_date, this.state.to_date, this.state.rx_group_associated))
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    render() {
        let associatedRecords = []
        for (let i = 0; i < this.props.groups.length; i++) {
            let each = this.props.groups[i]
            if (each.rx_group_associated !== undefined) {
                associatedRecords = [...associatedRecords,...(each.rx_group_associated.split(","))]
            }
        }
        let {rx_group_associated} = this.state
        return (
            <div className="form-row">
                {(this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined && this.props.auth.user.rx_group_associated !== "")?(
                    <div className="form-group col-md-3">
                        <label>Group</label>
                        <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                            <option value="">All</option>
                            <option key="group" value={this.props.auth.user.rx_group} {...rx_group_associated===this.props.auth.user.rx_group && {"selected":true}}>{this.props.auth.user.name}--{this.props.auth.user.rx_group}</option>
                            {this.props.auth.user.rx_group_associated.split(",").map(each => {
                                let nameGroup = this.props.groups.find( i => i.rx_group===each);
                                return (
                                    <option key={each} value={each} {...rx_group_associated===each && {"selected":true}}>{nameGroup?nameGroup.name:""}--{each}</option>
                                )
                            })}
                        </select>
                    </div>):null}
                {this.props.auth.user.superadmin && this.props.groups.length?
                    (<div className="form-group col-md-3">
                        <label>Group</label>
                        <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                            <option key="group" value="">All</option>
                            {this.props.groups.map(each => {
                                return (
                                    <>{!associatedRecords.includes(each.rx_group) && each.rx_group!=="superadmin" && each.rx_group!==undefined && <option {...rx_group_associated===each.rx_group && {"selected":true}} key={each.rx_group}  value={each.rx_group}>{each.name}-{each.rx_group}</option>}</>
                                )
                            })}
                        </select>
                    </div>):null}
                <div className="form-group col-md-3">
                    <label >From Date</label>
                    <DatePicker selected={this.state.from_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setFromDate(date)} className="form-control"  ref={(c) => this.fromDatePicker = c}/>
                    <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                </div>
                <div className="form-group col-md-3">
                    <label>To Date</label>
                    <DatePicker selected={this.state.to_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setToDate(date)} className="form-control"  ref={(c) => this.toDatePicker = c}/>
                    <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                </div>
                <div className="form-group col-md-4">
                    <button
                        onClick={this.onSearch}
                        type="button"
                        style={{ "marginTop": "28px" }}
                        className="btn btn-primary">
                        Search
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    groups: state.groups,
});

export default connect(
    mapStateToProps
)(FilterDate);