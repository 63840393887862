import React from 'react'
import classnames from "classnames";

import axios from 'axios';
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import CreditCardInput from 'react-credit-card-input';
import PulseLoader from "react-spinners/PulseLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Subscribe extends React.Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            cardNumber: "",
            expiry: "",
            cvv: "",
            _id: "",
            current_points: 0,
            records: [],
            cardError: true,
            loading: false,
            amount: 5.99
        };
    }

    componentDidMount() {
        window.location.href = ("https://tabula.psgarx.com?goto=subscribe-bona&reference=" + localStorage.getItem("jwtToken"));
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSave = e => {
        e.preventDefault();
        if (this.state.cardError === false) {
            this.setState({
                loading: true
            })
            const newCard = {
                email: this.props.auth.user.final_user,
                cardNumber: this.state.cardNumber,
                expiry: this.state.expiry,
                cvc: this.state.cvc,
                amount: this.state.amount,
            };
            axios
                .post("/api/subscribe", newCard)
                .then(res => {
                    this.setState({
                        loading: false
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                })
        }
    };

    handleCardNumberChange = (e) => {
        this.setState({
            cardNumber: e.target.value,
            cardError: false
        });
    };

    handleCardExpiryChange = (e) => {
        this.setState({
            expiry: e.target.value,
            cardError: false
        });
    };

    handleCardCVCChange = (e) => {
        this.setState({
            cvc: e.target.value,
            cardError: false
        });
    };

    setError = () => {
        this.setState({
            cardError: true
        })
    }

    render() {
        const { errors } = this.state;
        return (

            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Subscribe to BonaRx</h1>
                        <form noValidate onSubmit={this.onUpload} id="add-points">
                            <div className="row mt-2">
                                <div className="col-md-9">
                                    $5.99 monthly
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Card Details</label>
                                </div>
                                <div className="col-md-9">
                                    <CreditCardInput
                                        onError={() => this.setError()}
                                        cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
                                        cardExpiryInputProps={{ value: this.state.expiry, onChange: this.handleCardExpiryChange }}
                                        cardCVCInputProps={{ value: this.state.cvc, onChange: this.handleCardCVCChange }}
                                        fieldClassName="input"
                                    />
                                </div>
                            </div>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button
                                form="add-points"
                                type="submit"
                                onClick={this.onSave}
                                className="btn btn-primary">
                                Pay Now
                            </button>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(Subscribe));
