import React from "react";
import Chart from "react-google-charts";
import { Row, Container, Col } from 'react-bootstrap';

export class ExportGraph extends React.Component {

    render() {
        return (
            <>
                <div className="d-flex" id="wrapper">
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <h1 className="mt-2 text-primary">Business Analytics Report</h1> */}
                            <div className="row px-2">
                                {/* <div className="col-sm-8 p-sm-2">
                                    <Row>
                                        <Col md={4}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5 className="card-title">Total Purchase</h5>
                                                    <p className="card-text">${this.props.z}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5 className="card-title">Total Revenue</h5>
                                                    <p className="card-text">${this.props.totalRev}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5 className="card-title">Total Return</h5>
                                                    <p className="card-text">${this.props.totalRet}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5 className="card-title">Total Gross Profit</h5>
                                                    <p className="card-text">${this.props.totalest}</p>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h5 className="card-title">Total RX</h5>
                                                    <p className="card-text">{this.props.totalRx}</p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                                <div className="col-sm-8 p-sm-2">
                                    <div className="card" style={{ border: "none" }}>
                                        <div className="card-body">
                                            <h5>Daily Reports
                                            </h5>
                                            <Chart
                                                chartType="ComboChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.recordsGraph}
                                                options={{
                                                    seriesType: 'bars',
                                                    series: { 2: { type: 'line' } },
                                                    legend: { position: 'top' },
                                                }}
                                                rootProps={{ 'data-testid': '74' }}
                                                formatters={[
                                                    {
                                                        type: 'NumberFormat',
                                                        column: 2,
                                                        options: {
                                                            prefix: '$',
                                                            negativeColor: 'red',
                                                            negativeParens: true,
                                                        },
                                                    },
                                                    {
                                                        type: 'NumberFormat',
                                                        column: 1,
                                                        options: {
                                                            prefix: '$',
                                                            negativeColor: 'red',
                                                            negativeParens: true,
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 p-sm-2">
                                    <div className="card" style={{ border: "none" }}>
                                        <div className="card-body">
                                            <h5>Daily RX: {this.props.totalRx}
                                            </h5>
                                            <Chart
                                                width={'100%'}
                                                height={'400px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.rxGraph}
                                                options={{
                                                    legend: { position: 'top' },
                                                }}
                                                rootProps={{ 'data-testid': '63' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 p-sm-2">
                                    <div className="card" style={{ border: "none" }}>
                                        <div className="card-body">
                                            <h5>Total RX: {this.props.totalRx}
                                            </h5>
                                            <Chart
                                                // width={800}
                                                // height={'400px'}
                                                chartType="LineChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.props.totalRxGraph}
                                                options={{
                                                    legend: { position: 'top' },
                                                    // chartArea: { width: '90%', height: '70%' },
                                                }}
                                                rootProps={{ 'data-testid': '62' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ExportGraph