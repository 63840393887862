import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"


import { Collapse } from 'react-collapse';

class DrugUtilization extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true,
            },
            {
                key: "utilization_type",
                text: "Utilization Type",
                className: "utilization_type",
                align: "left",
                sortable: true,
            },
            {
                key: "year",
                text: "Year",
                className: "year",
                align: "left",
                sortable: true,
            },
            {
                key: "quarter",
                text: "Quarter",
                className: "quarter",
                align: "left",
                sortable: true,
            },
            {
                key: "product_name",
                text: "Product Name",
                className: "product_name",
                align: "left",
                sortable: true,
            },
            {
                key: "state",
                text: "State",
                className: "state",
                align: "left",
                sortable: true,
            },
            {
                key: "units_reimbursed",
                text: "Unit Reimbursed",
                className: "units_reimbursed",
                align: "left",
                sortable: true,
            },
            {
                key: "number_of_prescriptions",
                text: "Number of Prescription",
                className: "number_of_prescriptions",
                align: "left",
                sortable: true,
            },
            {
                key: "total_amount_reimbursed",
                text: "Amount Reimbursed",
                className: "total_amount_reimbursed",
                align: "left",
                // sortable: true,
            },
            {
                key: "medicaid_amount_reimbursed",
                text: "Medicaid Reimbursed",
                className: "medicaid_amount_reimbursed",
                align: "left",
                // sortable: true,
            },
            {
                key: "non_medicaid_amount_reimbursed",
                text: "Non-Medicaid Reimbursed",
                className: "non_medicaid_amount_reimbursed",
                align: "left",
                // sortable: true,
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Drug List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "total_amount_reimbursed", order: "desc" }
            },
            currentRecord: {
            },
            state: undefined,
            ndc: undefined,
            loading: false,
            isFilterOpen: true,
            dynamic: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = {}
        filterData.conditions = []
        if (this.state.state) {
            filterData.conditions.push({
                "resource": "t",
                "property": "state",
                "value": this.state.state,
                "operator": "="
            })
        }
        filterData.offset = (parseInt(this.state.filterData.page_number) - 1) * this.state.filterData.page_size
        filterData.limit = this.state.filterData.page_size
        filterData.sorts = [
            {
                property: this.state.filterData.sort_order.column,
                order: this.state.filterData.sort_order.order,
            }
        ]
        if (this.state.ndc) {
            let drugFilterData = {
                search: {
                    ndc: this.state.ndc
                }
            }
            axios
                .post("/api/same-gcn-drugs", drugFilterData)
                .then(res => {
                    let currState = this.state.state
                    let currRecords = []
                    for (let i = 0; i < res.data.records.length; i++) {
                        let each = res.data.records[i]
                        if (each.ndc != "") {
                            let conditions = []
                            console.log(each)
                            conditions.push({
                                "resource": "t",
                                "property": "ndc",
                                "value": each.ndc,
                                "operator": "="
                            })
                            if (currState) {
                                conditions.push({
                                    "resource": "t",
                                    "property": "state",
                                    "value": currState,
                                    "operator": "="
                                })
                            }
                            console.log(conditions)
                            axios
                                .post("https://data.medicaid.gov/api/1/datastore/query/eec7fbe6-c4c4-5915-b3d0-be5828ef4e9d/0", { ...filterData, conditions: conditions })
                                .then(res => {
                                    let result = currRecords
                                    for (let i = 0; i < res.data.results.length; i++) {
                                        let each = res.data.results[i]
                                        if (each.ndc !== "" && each.units_reimbursed > 0) {
                                            each.effective_date = each.effective_date ? moment(each.effective_date).format('L') : ""
                                            each.as_of_date = each.as_of_date ? moment(each.as_of_date).format('L') : ""
                                            each.total_amount_reimbursed = each.total_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.total_amount_reimbursed) : ""
                                            each.medicaid_amount_reimbursed = each.medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.medicaid_amount_reimbursed) : ""
                                            each.non_medicaid_amount_reimbursed = each.non_medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.non_medicaid_amount_reimbursed) : ""
                                            result.push(each)
                                        }
                                    }
                                    this.setState({
                                        records: result,
                                        totalCount: this.state.totalCount + res.data.count,
                                        loading: false,
                                        dynamic: false
                                    })
                                })
                                .catch( (error) => {
                                    this.setState({
                                        loading: false
                                    })
                                    if (error.response) {
                                        toast.error(error.response.data.message, {
                                            position: toast.POSITION.TOP_CENTER,
                                        })
                                    }
                                })


                        }
                    }
                })
        } else {
            axios
                .post("https://data.medicaid.gov/api/1/datastore/query/eec7fbe6-c4c4-5915-b3d0-be5828ef4e9d/0", filterData)
                .then(res => {
                    let result = []
                    for (let i = 0; i < res.data.results.length; i++) {
                        let each = res.data.results[i]
                        if (each.ndc !== "" && each.units_reimbursed > 0) {
                            each.effective_date = each.effective_date ? moment(each.effective_date).format('L') : ""
                            each.as_of_date = each.as_of_date ? moment(each.as_of_date).format('L') : ""
                            each.total_amount_reimbursed = each.total_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.total_amount_reimbursed) : ""
                            each.medicaid_amount_reimbursed = each.medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.medicaid_amount_reimbursed) : ""
                            each.non_medicaid_amount_reimbursed = each.non_medicaid_amount_reimbursed ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.non_medicaid_amount_reimbursed) : ""
                            result.push(each)
                        }
                    }
                    this.setState({
                        records: result,
                        totalCount: res.data.count,
                        loading: false,
                        dynamic: true
                    })
                })
                .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        }
    }

    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            state: undefined,
            filterData: {
                page_number: 1,
                limit: 20,
                sort_order: { column: "total_amount_reimbursed", order: "desc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = async () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "utilization_type": "Utilization Type",
            "state": "State",
            "ndc": "NDC",
            "package_size": "Package Size",
            "year": "Year",
            "quarter": "Quarter",
            "suppression_used": "Suppression Used",
            "product_name": "Product Name",
            "units_reimbursed": "Unit Reimburesed",
            "number_of_prescriptions": "No. of Prescriptions",
            "total_amount_reimbursed": "Amount Reimburesed",
            "medicaid_amount_reimbursed": "Medicaid Reimburesed",
            "non_medicaid_amount_reimbursed": "Non Medicaid Reimburesed",
        };
        let filterData = {}
        filterData.conditions = [{
            "resource": "t",
            "property": "state",
            "value": this.state.state,
            "operator": "="
        }]
        filterData.sorts = [
            {
                property: this.state.filterData.sort_order.column,
                order: this.state.filterData.sort_order.order,
            }
        ]

        filterData.limit = 9999
        if (this.state.ndc) {
            var records = [];
            // add data rows in sheet array
            for (let i in this.state.records) {
                let record = this.state.records[i]
                delete record.labeler_code
                delete record.product_code
                delete record.labeler_code
                record.effective_date = record.effective_date ? moment(record.effective_date).format('L') : ""
                record.as_of_date = record.as_of_date ? moment(record.as_of_date).format('L') : ""
                records.push(record);
            }
            if (headers) {
                records.unshift(headers);
            }
            let jsonObject = JSON.stringify(records);
            let csv = this.convertToCSV(jsonObject);
            let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
            // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
            var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                let link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } else if (this.state.totalCount > 10000) {
            var records = [];
            this.setState({ loading: true })
            let loopArr = []
            for (var i = 0; i < (this.state.totalCount / 10000); i++) {
                loopArr.push(i)
            }
            for (const each of loopArr) {
                let offset = each * 10000
                filterData.offset = each * 10000
                axios
                    .post("https://data.medicaid.gov/api/1/datastore/query/eec7fbe6-c4c4-5915-b3d0-be5828ef4e9d/0", { ...filterData, offset: offset })
                    .then(res => {
                        for (let i in res.data.results) {
                            let record = res.data.results[i]
                            delete record.labeler_code
                            delete record.product_code
                            delete record.labeler_code
                            record.effective_date = record.effective_date ? moment(record.effective_date).format('L') : ""
                            record.as_of_date = record.as_of_date ? moment(record.as_of_date).format('L') : ""
                            records.push(record);

                        }
                        if (Math.ceil(this.state.totalCount / 10000) === each + 1) {
                            this.setState({ loading: false })

                            if (headers) {
                                records.unshift(headers);
                            }
                            let jsonObject = JSON.stringify(records);
                            let csv = this.convertToCSV(jsonObject);
                            let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                            // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                            var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                            if (navigator.msSaveBlob) { // IE 10+
                                navigator.msSaveBlob(blob, exportedFilenmae);
                            } else {
                                let link = document.createElement("a");
                                if (link.download !== undefined) { // feature detection
                                    // Browsers that support HTML5 download attribute
                                    let url = URL.createObjectURL(blob);
                                    link.setAttribute("href", url);
                                    link.setAttribute("download", exportedFilenmae);
                                    link.style.visibility = 'hidden';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }
                            }
                        }

                    })
            }

            // add data rows in sheet array

        } else {
            await axios
                .post("https://data.medicaid.gov/api/1/datastore/query/eec7fbe6-c4c4-5915-b3d0-be5828ef4e9d/0", filterData)
                .then(res => {
                    var records = [];
                    // add data rows in sheet array
                    this.setState({ loading: false })
                    for (let i in res.data.results) {
                        let record = res.data.results[i]
                        delete record.labeler_code
                        delete record.product_code
                        delete record.labeler_code
                        record.effective_date = record.effective_date ? moment(record.effective_date).format('L') : ""
                        record.as_of_date = record.as_of_date ? moment(record.as_of_date).format('L') : ""
                        records.push(record);
                        console.log(records.length)
                    }
                    if (headers) {
                        records.unshift(headers);
                    }
                    let jsonObject = JSON.stringify(records);
                    let csv = this.convertToCSV(jsonObject);
                    let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                    // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                    var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
                    if (navigator.msSaveBlob) { // IE 10+
                        navigator.msSaveBlob(blob, exportedFilenmae);
                    } else {
                        let link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            let url = URL.createObjectURL(blob);
                            link.setAttribute("href", url);
                            link.setAttribute("download", exportedFilenmae);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                })
        }

    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Drug Utilization by State</h1>
                        <div>
                            <p className="help-text font-weight-bold h6">State Drug Utilization Data has been reported by states since the start of the Medicaid Drug Rebate Program for covered outpatient drugs paid for by state Medicaid agencies.The FFSU Record ID indicates that the information for this NDC represents a Fee-for-Service Utilization record. The MCOU Record ID indicates that the information for this NDC represents a Managed Care Organization Utilization record.</p>
                            {/* <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div> */}
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">NDC</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="state">State</label>
                                            <select name="state" onClick={this.onChangeSelect} className="form-control" id="state">
                                                <option value="" selected="">Please select</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="GU">Guam</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VI">Virgin islands</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <p style={{fontSize:"20px"}}>
                            <b>MCOU</b> - Managed Care Organization Utilization
                            <br></br>
                            <b>FFSU</b> - Fee For Service Utilization
                        </p>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={this.state.dynamic}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(DrugUtilization);
