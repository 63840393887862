import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "react-datepicker/dist/react-datepicker.css";
import Age from './dashboard/Age';
import CustomMap from './dashboard/CustomMap';
import Control from './dashboard/Control';
import FilterDate from './dashboard/FilterDate';
import TermCondition from './../partials/TermCondition';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment"
import { Link } from "react-router-dom";

import dashboard from "../../utils/dashboard.png"
class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            search: {
                from_date: moment().subtract(90, "days").startOf('day').toDate(),
                to_date: moment().endOf('day').toDate(),
            }
        };
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    onSearch = (from_date, to_date, rx_group_associated) => {
        this.setState({
            search: {
                from_date: from_date,
                to_date: to_date,
                ...(rx_group_associated !== '' && { rx_group_associated: rx_group_associated }),
            }
        })
    }

    Subscribe = () => {

    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                <div id="page-content-wrapper">
                    {this.props.auth.user.superadmin || (this.props.auth.user.bonaRx && (this.props.auth.user.rx_group !== undefined && this.props.auth.user.rx_group !== "")) ? (
                        <div className="container-fluid">

                            <h1 className="mt-2 text-primary">Dashboard</h1>
                            <FilterDate onSearch={this.onSearch} />
                            <Age formData={this.state.search} />
                            <Control formData={this.state.search} />
                            <CustomMap formData={this.state.search} />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {(this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") && this.props.auth.user.termCondition == 1 ? (
                                        <h6 class="font-weight-bold text-danger">You have no data to display. Your dispensing data will automatically be displayed when your custom cards are used at any pharmacy nationwide</h6>

                                    ) : (this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") ? (
                                        <h6 class="font-weight-bold text-danger">To access all analytical tools you must register on BonaRx - FREE <br></br>
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#term-condition">Register here</button></h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="card-body">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>
                <TermCondition />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);
