import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../utils/RxReach.png";
import rxr from "../../utils/rxr.jpg";

class Rxreach extends React.Component {

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
                <img src={logo} height={60} />
                <div className="card">
                  <div className="card-body">
                    <h1 className="card-title text-primary" style={{ fontWeight: "bold", fontSize: "47px" }}>Automated Patient Communication Software to Help Improve Engagement</h1>
                    <div className="row">
                      <div className="col-md-9">
                        <p className="card-text" style={{ fontSize: "27px" }}>RxReach’s outbound patient communications system helps your pharmacy improve patient engagement and streamline workflow through automated communications. Our software provides real-time relevant messaging that helps increase prescription sales, driving profitability of patient satisfaction.</p>
                      </div>
                      <div className="col-md-3" style={{ "textAlign": "center" }}><img style={{ width: "90%" }} src={rxr} /></div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "27px" }}>RxReach’s outbound patient communications can also aid in improving medication adherence and reducing return-to-stock rates.<br></br>RxReach is your complete custom communication platform that includes SMS, MMS, Voice, Fax and email broadcast for growing pharmacy with no monthly or setup fees. Pay only for what you use as low as 5 Cents.
                          <br></br><br></br><span  style={{ fontSize: "21px" }}>Select Promo plan and Promo code "Free" to get started for free</span>
                          <button
                            type="button"
                            style={{ marginRight: "63px" }}
                            className="btn btn-success btn-lg float-right">
                            <a href="https://admin.rxreach.com/account" target="_blank" style={{color:"#fff",textDecoration:"none"}}>GET STARTED NOW</a>
                          </button>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default Rxreach;