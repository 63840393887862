import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Collapse } from 'react-collapse';
import CliaPhysicianUpdateModal from "../partials/CliaPhysicianUpdateModal";

import CliaPhysicianAddModal from "../partials/CliaPhysicianAddModal";

class CliaPhysician extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "clia",
                text: "CLIA",
                className: "clia",
                align: "left",
                sortable: true,
            },
            {
                key: "certificate_type",
                text: "Certificate Type",
                className: "certificate_type",
                align: "left",
                sortable: true,
            },
            {
                key: "laboratory_name",
                text: "Lab Name",
                className: "laboratory_name",
                align: "left",
                sortable: true,
            },
            {
                key: "address",
                text: "Address",
                className: "address",
                align: "left",
                sortable: true,
            },
            {
                key: "city",
                text: "City",
                className: "city",
                align: "left",
                sortable: true,
            },
            {
                key: "state",
                text: "State",
                className: "state",
                align: "left",
                sortable: true,
            },
            {
                key: "zip",
                text: "Zip",
                className: "zip",
                align: "left",
                sortable: true,
            },
            {
                key: "phone",
                text: "Phone",
                className: "phone",
                align: "left",
                sortable: true,
            },
            {
                key: "fax",
                text: "Fax",
                className: "fax",
                align: "left",
                sortable: true,
            }

        ];
        if (this.props.auth.user.superadmin) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-waiver-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })
        }

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Waivers List",
            no_data_text: 'No Pharmacy found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "clia", order: "asc" }
            },
            currentRecord: {
            },
            clia: undefined,
            laboratory_name: undefined,
            state: undefined,
            zip: undefined,
            phone: undefined,
            city: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.clia !== '' && { clia: this.state.clia }),
            ...(this.state.laboratory_name !== '' && { laboratory_name: this.state.laboratory_name }),
            ...(this.state.state !== '' && { state: this.state.state }),
            ...(this.state.zip !== '' && { zip: this.state.zip }),
            ...(this.state.phone !== '' && { phone: this.state.phone }),
            ...(this.state.city !== '' && { city: this.state.city }),
        }
        axios
            .post("/api/waivers", filterData)
            .then(res => {
                this.setState({
                    records: res.data.records,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }
    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    deleteRecord(record) {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.finalDelete(record)
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
        
    }

    finalDelete = (record) => {
        axios
            .post("/api/CLIA-delete", 
                { 
                    _id: record._id, 
                    // delete: this.props.auth.user.user_type!=="Technician"?true:false,
                    // email: this.props.auth.user.email
                }
            )
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            });
        this.getData();
    }
    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            clia: undefined,
            laboratory_name: undefined,
            state: undefined,
            zip: undefined,
            phone: undefined,
            city: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    
        var headers = {
            "clia": "CLIA",
            "laboratory_type": "Laboratory Type",
            "certificate_type": "Certificate Type",
            "laboratory_address": "Laboratory Address",
            "laboratory_name": "Laboratory Name",
            "address": "Address",
            "city": "City",
            "state": "State",
            "zip": "Zip",
            "phone": "Phone",
            "fax": "Fax",
            "email": "Email"
        };
        // // add columns in sheet array
        // for (let column of this.props.columns) {
        //     headers[column.key] = '"' + column.text + '"';
        // }

        let filterData = {
            page_number: 1,
            page_size: 20000,
            sort_order: { column: "_id", order: "asc" }
        }
        filterData.search = {
            ...(this.state.clia !== '' && { clia: this.state.clia }),
            ...(this.state.laboratory_name !== '' && { laboratory_name: this.state.laboratory_name }),
            ...(this.state.state !== '' && { state: this.state.state }),
            ...(this.state.zip !== '' && { zip: this.state.zip }),
            ...(this.state.phone !== '' && { phone: this.state.phone }),
            ...(this.state.city !== '' && { city: this.state.city }),
        }
        axios
            .post("/api/waivers", filterData)
            .then(res => {
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    delete record._id
                    delete record.id
                    delete record.__v
                
                    record.laboratory_address = '"' + record.laboratory_address + '"'
                    record.laboratory_name = '"' + record.laboratory_name + '"'
                    record.address = '"' + record.address + '"'


                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
                // var universalBOM = "\uFEFF";
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
                var blob = new Blob([new Uint8Array([0xEF,0xBB,0xBF]),csv],{type:'application/csv;charset=UTF-8'});
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                {this.props.auth.user.superadmin &&
                    <CliaPhysicianAddModal getData={this.getData} />

                }
                <CliaPhysicianUpdateModal record={this.state.currentRecord} getData={this.getData} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        {this.props.auth.user.superadmin &&
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-waiver-modal"><FontAwesomeIcon icon={faPlus} /> Add CLIA Waiver Pharmacies</button>
                        }
                        <h1 className="mt-2 text-primary">CLIA Waiver Pharmacies</h1>
                        <div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Show Filter
                                </label>
                            </div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="clia">CLIA</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.clia}
                                                id="clia"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ndc">Laboratory Name</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.laboratory_name}
                                                id="laboratory_name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label htmlFor="ahfs_desc">City</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.city}
                                                id="city"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="state">State</label>
                                            <select name="state" onClick={this.onChangeSelect} className="form-control" id="state">
                                                <option value="" selected="">Any</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AS">American Samoa</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="AA">Armed Forces America</option>
                                                <option value="AE">Armed Forces Europe /Canada / Middle East / Africa</option>
                                                <option value="AP">Armed Forces Pacific</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="FM">Federated States of Micronesia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="GU">Guam</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MP">Mariana Islands, Northern</option>
                                                <option value="MH">Marshall Islands</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VI">Virgin islands</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="gcn_seq">Zip</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.zip}
                                                id="zip"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ahfs_desc">Phone</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.phone}
                                                id="phone"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Filter</button>
                                    <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(CliaPhysician);
