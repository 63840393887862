import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment"
import { Collapse } from 'react-collapse';

class Coupons extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "brand",
                text: "Brand",
                className: "brand",
                align: "left",
                sortable: true,
                openLink: true
            },
            {
                key: "copay",
                text: "Copay",
                className: "copay",
                align: "left",
                sortable: true,
            },

            {
                key: "bin",
                text: "BIN",
                className: "bin",
                align: "left",
                sortable: true,
            },
            {
                key: "rxpcn",
                text: "RXPCN",
                className: "rxpcn",
                align: "left",
                sortable: true,
            },
            {
                key: "copay_max",
                text: "Copay Max",
                className: "copay_max",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "lifetime_uses",
                text: "Lifetime Uses",
                className: "lifetime_uses",
                align: "left",
                sortable: true,
            },
            {
                key: "max_saving_transaction",
                text: "Maximum Saving",
                className: "max_saving_transaction",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "description",
                text: "Description",
                className: "description",
                align: "left",
                sortable: true,
            }

        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Coupon List",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                // print: false,
                print: false,
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        if (this.props.auth.user.superadmin) {
            this.config.button.csv = true
        }

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            currentRecord: {
            },
            brand: undefined,
            loading: false,
            isFilterOpen: true
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData()
    };

    getData = () => {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.brand !== '' && { brand: this.state.brand }),
        }
        axios
            .post("/api/coupons", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.link !== "") {
                        each.max_saving_transaction_orig = each.max_saving_transaction
                        each.copay_max_orig = each.copay_max
                        each.max_saving_transaction = each.max_saving_transaction ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.max_saving_transaction) : ""
                        each.copay_max = each.copay_max ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.copay_max) : ""
                        result.push(each)
                    }
                }
                this.setState({
                    records: result,
                    totalCount: res.data.count,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }


    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            brand: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "dea", order: "asc" }
            },
            records: [],
            totalCount: undefined
        })
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "brand": "Brand",
            "copay": "copay",
            "bin": "BIN",
            "rxpcn": "RXPCN",
            "copay_max_orig": "Copay Max",
            "lifetime_uses": "Lifetime Uses",
            "max_saving_transaction_orig": "Max Transaction",
            "description": "Description",
        };
        let records = []
        for (let i in this.state.records) {
            let record = this.state.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject,headers);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray, headers) {
        const keys = Object.keys(headers);
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in keys) {
                line += array[i][keys[index]] === undefined ? "," : array[i][keys[index]] + ",";
                line.trim(",")


            }
            str += line + '\r\n';
        }
        return str;
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Brand Coupons</h1>
                        <p className="help-text font-weight-bold h6">You can search and find the coupons offered by different brand.</p>
                        <div>
                            <Collapse isOpened={this.state.isFilterOpen}>
                                <form noValidate onSubmit={this.onSearch}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3"></div>
                                        <div className="form-group col-md-5">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.brand}
                                                id="brand"
                                                type="text"
                                                className="form-control"
                                                placeholder="Search By Brand"
                                            />
                                        </div>

                                        <div className="form-group col-md-1">
                                            <button type="submit" className="btn btn-primary">Search</button>
                                        </div>
                                        <div className="form-group col-md-3"></div>
                                    </div>
                                </form>
                            </Collapse>
                        </div>
                        <br></br>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                            exportToCSV={this.exportToCSV}

                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Coupons);
