import React from 'react'
import classnames from "classnames";
import { setCurrentUser } from "../../actions/authActions";
import axios from 'axios';
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class XpedicareVideo extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="how-it-works" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Watch How XPedicare works</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body center">
                                <video width="90%" height="90%" controls>
                                    <source src="https://www.psgarx.com/wp-content/uploads/2022/04/single-use-token.mp4" type="video/mp4" />
                                    <source src="https://www.psgarx.com/wp-content/uploads/2022/04/single-use-token.mp4" type="video/ogg" />
                                </video>
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { setCurrentUser }
)(XpedicareVideo);
