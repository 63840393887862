import React from 'react'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import moment from "moment"
// import { faPlus, faDownload,faCog } from "@fortawesome/free-solid-svg-icons";

class Transaction extends React.Component {

    constructor() {
        super();

        this.columns = [
            {
                key: "transaction_id",
                text: "Transaction Id",
                className: "transaction_id",
                align: "left",
                sortable: true,
            },
            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
            },
            {
                key: "type",
                text: "Type",
                className: "type",
                align: "left",
                sortable: true,
            },
            {
                key: "message",
                text: "Message",
                className: "message",
                align: "left",
                sortable: true,
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Transaction Ledger",
            no_data_text: 'No Transaction found!',
            button: {
                excel: false,
                print: false,
                csv: true,
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            _id: "",
            errors: {},
            records: [],
            totalCount: 0,
            cardError: true,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            },
        };
    }

    componentDidMount() {
        this.getData()
    };
    
    getData() {
        this.setState({ loading: true })
        let filterData = {
            page_number: 1,
            page_size: 20,
            sort_order: { column: "_id", order: "desc" }
        }
        filterData.search = {
            email: this.props.auth.user.final_user
        }
        axios
            .post("/api/transactions", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    each.date = each.date ? moment(each.date).add(5, 'hours').format('L') : ""
                    each.amount = each.amount ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.amount) : ""
                    result.push(each)
                }
                this.setState({
                    loading: false,
                    records: result,
                    totalCount: res.data.count
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        return (
            <div className="d-flex" id="wrapper">
                {this.state.loading &&
                    <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">Transactions</h1>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.filter}
                            dynamic={true}
                            total_record={this.state.totalCount}
                            loading={true}
                        />
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, null
)(Transaction);
