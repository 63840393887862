import React, { Component } from "react";
import ReactDatatable from '../../utils/ReactDatatable';

import { connect } from "react-redux";
import axios from "axios";
import ReactAutocomplete from "react-autocomplete"
import DatePicker from "react-datepicker";
import { setPharmacies } from "../../actions/authActions";
import "react-datepicker/dist/react-datepicker.css";
import '../../App.css';
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify";
import PhysicianMedication from "./View/PhysicianMedication"
import Chart from "react-google-charts";
import moment from "moment"
import dashboard from "../../utils/dashboard.png"
import { Link } from "react-router-dom";

class PhysicianReport extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "NPI",
                className: "_id",
                align: "left",
                sortable: true,
                show_medication: true
            },
            {
                key: "first_name",
                text: "First Name",
                className: "first_name",
                align: "left",
                sortable: true,
            },
            {
                key: "last_name",
                text: "Last Name",
                className: "last_name",
                align: "left",
                sortable: true,
            },
            {
                key: "address_telephone",
                text: "Telephone",
                className: "address_telephone",
                align: "left",
                sortable: true,
            },
            {
                key: "fax",
                text: "Fax",
                className: "fax",
                align: "left",
                sortable: true,
            },
            {
                key: "total_rx",
                text: "Total Rx",
                className: "total_rx",
                align: "left",
                sortable: true,
            },
            {
                key: "total_revenue",
                text: "Total Revenue",
                className: "total_revenue",
                align: "left",
                sortable: true,
                currency: true
            }
        ];


        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Stats",
            no_data_text: 'No result found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "desc" }
            },
            currentRecord: {
            },
            name: undefined,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            value: undefined,
            selectedNpi: undefined,
            allDoctorName: [],
            loading: false,
            npi: undefined,
            physicianMedication: undefined,
            rx_group_associated: undefined,
            recordsGraph: []
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
        // this.getSuggested()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.npi !== '' && { npi: this.state.npi }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated })
        }
        if (this.state.value !== undefined && this.state.value !== "") {
            let selectedNpi = this.state.allDoctorName.find(o => o.name === this.state.value);
            if (selectedNpi !== undefined) {
                filterData.search.npi = selectedNpi.id
            } else {
                filterData.search.npi = this.state.value
            }
        }
        axios
            .post("/api/physician-report", filterData)
            .then(res => {
                let result = []
                let doctorsArr = {}
                let recordsGraph = []
                let allDoctorName = []
                res.data.records.sort((a, b) => Number(b.total_rx) - Number(a.total_rx));
                recordsGraph.push(['ID', 'Claims', 'Member Paid', "Name", 'Claims'])
                for (let i = 0; i < res.data.doctors.length; i++) {
                    doctorsArr[res.data.doctors[i].npi] = {
                        first_name: res.data.doctors[i].first_name,
                        last_name: res.data.doctors[i].last_name,
                        fax: res.data.doctors[i].fax,
                        address_telephone: res.data.doctors[i].address_telephone
                    }
                    if (res.data.doctors[i].first_name !== undefined && res.data.doctors[i].last_name !== undefined) {
                        allDoctorName.push({ "id": res.data.doctors[i].npi, "name": res.data.doctors[i].first_name + " " + res.data.doctors[i].last_name })
                    }
                }
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (doctorsArr[each._id] !== undefined) {
                        each.total_revenue = each.total_revenue ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.total_revenue) : ""
                        each.total_rx = each.total_rx !== null ? each.total_rx : 0
                        each.first_name = doctorsArr[each._id] !== undefined ? doctorsArr[each._id].first_name : ""
                        each.last_name = doctorsArr[each._id] !== undefined ? doctorsArr[each._id].last_name : ""
                        each.fax = doctorsArr[each._id] !== undefined ? doctorsArr[each._id].fax : ""
                        each.address_telephone = doctorsArr[each._id] !== undefined ? doctorsArr[each._id].address_telephone : ""
                        result.push(each)
                        if (i < 500) {
                            recordsGraph.push([
                                "",
                                each.total_rx,
                                Number(each.total_revenue.replace(/[^0-9.-]+/g, "")),
                                each.first_name + ' ' + each.last_name,
                                each.total_rx,
                            ])
                        }
                    }
                }
                this.setState({
                    records: result,
                    recordsGraph: recordsGraph,
                    totalCount: res.data.count,
                    // allDoctorName: allDoctorName,
                    loading: false
                })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }

    getSuggested = () => {
        if (this.props.pharmacy === undefined || !this.props.pharmacy.length) {
            axios
                .post("/api/get-pharmacy",)
                .then(res => {
                    this.props.setPharmacies(res.data)
                })
                .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        }
    }

    changeText = (value) => {
    }

    onSearch = e => {
        this.setState({
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "retail", order: "desc" }
            }
        }, () => this.getData());
    }

    reset = () => {
        this.setState({
            value: undefined,
            from_date: undefined,
            to_date: undefined,
            rx_group_associated: undefined,
            npi: '',
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData());
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    setReactAutocomplete = (e) => {
        let selectedNpi = this.state.records.find(o => o.name === 'string 1');
        this.setState({ value: e.target.value, selectedNpi: selectedNpi._id })
    }

    shouldItemRender = (item, value) => {
        return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    }

    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "npi": "NPI",
            "total_rx": "Total Rx",
            "total_revenue": "Total Revenue",
            "first_name": "First Name",
            "last_name": "Last Name",
            "telephone": "Telephone"
        };
        // // add columns in sheet array

        var records = [

        ];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    showMedication = (physicianMedication) => {
        axios
            .post("/api/get-physician-medication", { npi: physicianMedication._id })
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    each.retail = each.retail ? "$" + parseFloat(each.retail.toFixed(2)) : ""
                    result.push(each)
                }
                physicianMedication.records = result
                this.setState({ physicianMedication: physicianMedication })
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })

    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        let associatedRecords = []
        for (let i = 0; i < this.props.groups.length; i++) {
            let each = this.props.groups[i]
            if (each.rx_group_associated !== undefined) {
                associatedRecords = [...associatedRecords, ...(each.rx_group_associated.split(","))]
            }
        }
        let { rx_group_associated } = this.state
        return (
            <div className="d-flex" id="wrapper">
                {(this.props.auth.user.bonaRx || this.props.auth.user.superadmin) && (
                    <>
                        {this.state.loading &&
                            <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    </>
                )}
                <PhysicianMedication data={this.state.physicianMedication} />
                <div id="page-content-wrapper">
                    {this.props.auth.user.superadmin || (this.props.auth.user.bonaRx && (this.props.auth.user.rx_group !== undefined && this.props.auth.user.rx_group !== "")) ? (
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Physician Report</h1>
                            <div>
                                <form noValidate >
                                    <div className="form-row">
                                        {(this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined && this.props.auth.user.rx_group_associated !== "") ? (
                                            <div className="form-group col-md-3">
                                                <label>Group</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                    <option value="">All</option>
                                                    <option value={this.props.auth.user.rx_group} {...rx_group_associated === this.props.auth.user.rx_group && { "selected": true }}>{this.props.auth.user.name}--{this.props.auth.user.rx_group}</option>
                                                    {this.props.auth.user.rx_group_associated.split(",").map(each => {
                                                        let nameGroup = this.props.groups.find(i => i.rx_group === each);
                                                        return (
                                                            <option key={each} value={each} {...rx_group_associated === each && { "selected": true }}>{nameGroup ? nameGroup.name : ""}--{each}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>) : null}
                                        {this.props.auth.user.superadmin && this.props.groups.length ?
                                            (<div className="form-group col-md-3">
                                                <label>Group</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                    <option value="">All</option>
                                                    {this.props.groups.map(each => {
                                                        return (
                                                            <>{!associatedRecords.includes(each.rx_group) && each.rx_group !== "superadmin" && each.rx_group !== undefined && <option {...rx_group_associated === each.rx_group && { "selected": true }} key={each.rx_group} value={each.rx_group}>{each.name}-{each.rx_group}</option>}</>
                                                        )
                                                    })}
                                                </select>
                                            </div>) : null}
                                        {this.state.allDoctorName.length ? (
                                            <div className="form-group col-md-3 autocomplete_box">

                                                <label htmlFor="npi">Physician Name</label>
                                                <ReactAutocomplete
                                                    items={this.state.allDoctorName || []}

                                                    shouldItemRender={this.shouldItemRender}
                                                    getItemValue={item => item.name}
                                                    renderItem={(item, highlighted) =>
                                                        <div
                                                            key={item.id}

                                                            style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
                                                        >

                                                            {item.name}

                                                        </div>

                                                    }
                                                    value={this.state.value}
                                                    onChange={e => this.setState({ value: e.target.value })}
                                                    onSelect={value => this.setState({ value })}
                                                />

                                            </div>
                                        ) : ""}
                                        <div className="form-group col-md-3">
                                            <label htmlFor="npi">NPI</label>
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.npi}
                                                id="npi"
                                                type="text"
                                                className="form-control"
                                            />

                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="npi">From Date</label>
                                            <DatePicker selected={this.state.from_date} onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="first_name">To Date</label>
                                            <DatePicker selected={this.state.to_date} onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                        </div>

                                    </div>
                                    <button
                                        onClick={this.onSearch}
                                        type="button"
                                        className="btn btn-primary">
                                        Search
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={this.reset}>Clear</button>
                                </form>
                            </div>
                            <br></br>


                            {this.state.recordsGraph.length > 0 && (
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        {this.state.records.length === 0 ? (<h1>No Record To Display</h1>) : <h5>Top {this.state.records.length > 500 ? "500" : ""} Physician Report</h5>}


                                        <Chart
                                            chartType="BubbleChart"
                                            height={'500px'}
                                            loader={<div>Loading Chart</div>}
                                            data={this.state.recordsGraph}
                                            options={{
                                                hAxis: { title: 'Claims' },
                                                vAxis: { title: 'Member Paid', format: "currency" },
                                                bubble: {
                                                    textStyle: {
                                                        fontSize: 8,
                                                        color: "transparent"
                                                    },
                                                },
                                                legend: 'none'
                                            }}
                                            rootProps={{ 'data-testid': '7' }}
                                            sizeAxis={{ minValue: 0, maxSize: 5 }}
                                            formatters={[
                                                {
                                                    type: 'NumberFormat',
                                                    column: 2,
                                                    options: {
                                                        prefix: '$',
                                                        negativeColor: 'red',
                                                        negativeParens: true,
                                                    },
                                                },
                                            ]}
                                        />

                                    </div>
                                </div>
                            )}

                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                                showMedication={this.showMedication}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {(this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") && this.props.auth.user.termCondition == 1 ? (
                                        <h6 class="font-weight-bold text-danger">You have no data to display. Your dispensing data will automatically be displayed when your custom cards are used at any pharmacy nationwide</h6>

                                    ) : (this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") ? (
                                        <h6 class="font-weight-bold text-danger">To access all analytical tools you must register on BonaRx - FREE <br></br>
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#term-condition">Register here</button></h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="card-body">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    pharmacy: state.pharmacy,
    groups: state.groups,
});

export default connect(
    mapStateToProps, { setPharmacies }
)(PhysicianReport);
