import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { toast } from 'react-toastify';
import $ from 'jquery';
import isEmpty from 'is-empty';


import 'react-toastify/dist/ReactToastify.css';

class DrugsAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            awp: "",
            acq_cost: "",
            ahfs_code: "",
            bu_per_package: "",
            category: "",
            category_desc: "",
            contract_cost: "",
            dea: "",
            dea_desc: "",
            fdb_package_size: "",
            fine_line: "",
            fine_line_desc: "",
            form_code: "",
            gcn: "",
            gcn_seq: "",
            generic_hicl: "",
            generic_name: "",
            group: "",
            group_desc: "",
            gtin: "",
            hazard: "",
            hazard_desc: "",
            hcpcs: "",
            hic_code: "",
            hic_desc: "",
            hri: "",
            name: "",
            ndc: "",
            niosh_code: "",
            niosh_desc: "",
            omp: "",
            omp_desc: "",
            omp_indicator: "",
            orange_code: "",
            package_awp: "",
            price_per_dose: "",
            retail_price: "",
            route_code: "",
            route_desc: "",
            source: "",
            supplier_name: "",
            unit_dose_code: "",
            unit_dose_product: "",
            unit_size_code: "",
            unit_size_qty: "",
            unit_strength_code: "",
            unit_strength_qty: "",
            uom: "",
            upc_barcode: "",
            ahfs_desc: "",
            website: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.record) {
        //     this.setState({
        //         ...nextProps.record
        //     })
        // }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#update-drug-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onDrugUpdate = e => {
        e.preventDefault();
        let {errors} = this.state
        if(this.state.acq_cost===''){
            errors.acq_cost = "This field is required"
        }else{
            delete errors.acq_cost
        }
        if(this.state.ahfs_desc===''){
            errors.ahfs_desc = "This field is required"
        }else{
            delete errors.ahfs_desc
        }
        if(this.state.form_code===''){
            errors.form_code = "This field is required"
        }else{
            delete errors.form_code
        }
        if(this.state.dea===''){
            errors.dea = "This field is required"
        }else{
            delete errors.dea
        }
        if(this.state.dea_desc===''){
            errors.dea_desc = "This field is required"
        }else{
            delete errors.dea_desc
        }
        if(this.state.gcn===''){
            errors.gcn = "This field is required"
        }else{
            delete errors.gcn
        }
        if(this.state.gcn_seq===''){
            errors.gcn_seq = "This field is required"
        }else{
            delete errors.gcn_seq
        }
        if(this.state.group===''){
            errors.group = "This field is required"
        }else{
            delete errors.group
        }
        if(this.state.group_desc===''){
            errors.group_desc = "This field is required"
        }else{
            delete errors.group_desc
        }if(this.state.name===''){
            errors.name = "This field is required"
        }else{
            delete errors.name
        }if(this.state.ndc===''){
            errors.ndc = "This field is required"
        }else{
            delete errors.ndc
        }if(this.state.package_awp===''){
            errors.package_awp = "This field is required"
        }else{
            delete errors.package_awp
        }if(this.state.unit_size_qty===''){
            errors.unit_size_qty = "This field is required"
        }else{
            delete errors.unit_size_qty
        }
        if(isEmpty(errors)) {
        const newDrug = {
            acq_cost: this.state.acq_cost,
            ahfs_code: this.state.ahfs_code,
            ahfs_desc: this.state.ahfs_desc,
            awp: this.state.awp,
            bu_per_package: this.state.bu_per_package,
            category: this.state.category,
            category_desc: this.state.category_desc,
            contract_cost: this.state.contract_cost,
            dea: this.state.dea,
            dea_desc: this.state.dea_desc,
            fdb_package_size: this.state.fdb_package_size,
            fine_line: this.state.fine_line,
            fine_line_desc: this.state.fine_line_desc,
            form_code: this.state.form_code,
            gcn: this.state.gcn,
            gcn_seq: this.state.gcn_seq,
            generic_hicl: this.state.generic_hicl,
            generic_name: this.state.generic_name,
            group: this.state.group,
            group_desc: this.state.group_desc,
            gtin: this.state.gtin,
            hazard: this.state.hazard,
            hazard_desc: this.state.hazard_desc,
            hcpcs: this.state.hcpcs,
            hic_code: this.state.hic_code,
            hic_desc: this.state.hic_desc,
            hri: this.state.hri,
            name: this.state.name,
            ndc: this.state.ndc,
            niosh_code: this.state.niosh_code,
            niosh_desc: this.state.niosh_desc,
            omp: this.state.omp,
            omp_desc: this.state.omp_desc,
            omp_indicator: this.state.omp_indicator,
            orange_code: this.state.orange_code,
            package_awp: this.state.package_awp,
            price_per_dose: this.state.price_per_dose,
            retail_price: this.state.retail_price,
            route_code: this.state.route_code,
            route_desc: this.state.route_desc,
            source: this.state.source,
            supplier_name: this.state.supplier_name,
            unit_dose_code: this.state.unit_dose_code,
            unit_dose_product: this.state.unit_dose_product,
            unit_size_code: this.state.unit_size_code,
            unit_size_qty: this.state.unit_size_qty,
            unit_strength_code: this.state.unit_strength_code,
            unit_strength_qty: this.state.unit_strength_qty,
            uom: this.state.uom,
            upc_barcode: this.state.upc_barcode,
            website: this.state.website,
        
        };
        axios.post('/api/drug-add', newDrug)
            .then((response) => {
                this.props.getData();
                $('#add-drug-modal').modal('hide');
            }, (error) => {
                console.log(error);
            });

        }else{
            this.setState({errors})
        }

        //this.props.addUser(newUser, this.props.history);
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-drug-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Drug</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onDrugUpdate} id="add-drug">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Product Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="acq_cost">Current Acq Cost($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.acq_cost}
                                                id="acq_cost"
                                                type="text"
                                                error={errors.acq_cost}
                                                className={classnames("form-control", {
                                                    invalid: errors.acq_cost
                                                })} />
                                            <span className="text-danger">{errors.acq_cost}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ahfs_code">AHFS Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ahfs_code}
                                                id="ahfs_code"
                                                type="text"
                                                error={errors.ahfs_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.ahfs_code
                                                })} />
                                            <span className="text-danger">{errors.ahfs_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ahfs_desc">AHFS Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ahfs_desc}
                                                id="ahfs_desc"
                                                type="text"
                                                error={errors.ahfs_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.ahfs_desc
                                                })} />
                                            <span className="text-danger">{errors.ahfs_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="awp">AWP</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.awp}
                                                id="awp"
                                                type="text"
                                                error={errors.awp}
                                                className={classnames("form-control", {
                                                    invalid: errors.awp
                                                })} />
                                            <span className="text-danger">{errors.awp}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="bu_per_package">Bu per Package</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.bu_per_package}
                                                id="bu_per_package"
                                                type="text"
                                                error={errors.bu_per_package}
                                                className={classnames("form-control", {
                                                    invalid: errors.bu_per_package
                                                })} />
                                            <span className="text-danger">{errors.bu_per_package}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="category">Product Category</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category}
                                                id="category"
                                                type="text"
                                                error={errors.category}
                                                className={classnames("form-control", {
                                                    invalid: errors.category
                                                })} />
                                            <span className="text-danger">{errors.category}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="category_desc">Product Category Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.category_desc}
                                                id="category_desc"
                                                type="text"
                                                error={errors.category_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.category_desc
                                                })} />
                                            <span className="text-danger">{errors.category_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="contract_cost">Contract Cost($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contract_cost}
                                                id="contract_cost"
                                                type="text"
                                                error={errors.contract_cost}
                                                className={classnames("form-control", {
                                                    invalid: errors.contract_cost
                                                })} />
                                            <span className="text-danger">{errors.contract_cost}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dea">DEA Class</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.dea} error={errors.dea} id="dea" className={classnames("form-control", { invalid: errors.dea })}>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="2N">2N</option>
                                                <option value="3N">3N</option>
                                                <option value="NC">NC</option>
                                                
                                            </select>


                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dea_desc">DEA Class Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.dea_desc}
                                                id="dea_desc"
                                                type="text"
                                                error={errors.dea_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.dea_desc
                                                })} />
                                            <span className="text-danger">{errors.dea_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fdb_package_size">FDB Package Size Qty</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fdb_package_size}
                                                id="fdb_package_size"
                                                type="text"
                                                error={errors.fdb_package_size}
                                                className={classnames("form-control", {
                                                    invalid: errors.fdb_package_size
                                                })} />
                                            <span className="text-danger">{errors.fdb_package_size}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fine_line">Fine Line Class</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fine_line}
                                                id="fine_line"
                                                type="text"
                                                error={errors.fine_line}
                                                className={classnames("form-control", {
                                                    invalid: errors.fine_line
                                                })} />
                                            <span className="text-danger">{errors.fine_line}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="fine_line_desc">Fine Line Class Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.fine_line_desc}
                                                id="fine_line_desc"
                                                type="text"
                                                error={errors.fine_line_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.fine_line_desc
                                                })} />
                                            <span className="text-danger">{errors.fine_line_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="form_code">Form Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.form_code}
                                                id="form_code"
                                                type="text"
                                                error={errors.form_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.form_code
                                                })} />
                                            <span className="text-danger">{errors.form_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="gcn">GCN 5 digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.gcn}
                                                id="gcn"
                                                type="text"
                                                error={errors.gcn}
                                                className={classnames("form-control", {
                                                    invalid: errors.gcn
                                                })} />
                                            <span className="text-danger">{errors.gcn}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="gcn_seq">GCN Seq # 5 digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.gcn_seq}
                                                id="gcn_seq"
                                                type="text"
                                                error={errors.gcn_seq}
                                                className={classnames("form-control", {
                                                    invalid: errors.gcn_seq
                                                })} />
                                            <span className="text-danger">{errors.gcn_seq}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="generic_hicl">Generic HICL 5 digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.generic_hicl}
                                                id="generic_hicl"
                                                type="text"
                                                error={errors.generic_hicl}
                                                className={classnames("form-control", {
                                                    invalid: errors.generic_hicl
                                                })} />
                                            <span className="text-danger">{errors.generic_hicl}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="generic_name">Generic Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.generic_name}
                                                id="generic_name"
                                                type="text"
                                                error={errors.generic_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.generic_name
                                                })} />
                                            <span className="text-danger">{errors.generic_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="group">Product Group</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.group}
                                                id="group"
                                                type="text"
                                                error={errors.group}
                                                className={classnames("form-control", {
                                                    invalid: errors.group
                                                })} />
                                            <span className="text-danger">{errors.group}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="group_desc">Product Group Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.group_desc} error={errors.group_desc} id="group_desc" className={classnames("form-control", { invalid: errors.group_desc })}>
                                                <option value="Branded OTC">Branded OTC</option>
                                                <option value="Branded RX">Branded RX</option>
                                                <option value="Non Material">Non Material</option>
                                                <option value="Medical Supplies">Medical Supplies</option>
                                                <option value="Branded HBC">Branded HBC</option>
                                                <option value="Stores Supplies">Stores Supplies</option>
                                                <option value="Home Health Care">Home Health Care</option>
                                                <option value="General Merchandise">General Merchandise</option>
                                                <option value="BRX">BRX</option>
                                                <option value="GRX">GRX</option>
                                                <option value="General Merchandise">Generic RX</option>
                                                <option value="Generic HBC">Generic HBC</option>
                                                <option value="Generic OTC">Generic OTC</option>
                                                <option value="Generic RX">Generic RX</option>
                                                

                                            </select>


                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="gtin">GTIN #</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.gtin}
                                                id="gtin"
                                                type="text"
                                                error={errors.gtin}
                                                className={classnames("form-control", {
                                                    invalid: errors.gtin
                                                })} />
                                            <span className="text-danger">{errors.gtin}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hazard">Hazard Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hazard}
                                                id="hazard"
                                                type="text"
                                                error={errors.hazard}
                                                className={classnames("form-control", {
                                                    invalid: errors.hazard
                                                })} />
                                            <span className="text-danger">{errors.hazard}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hazard_desc">Hazard Code Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hazard_desc}
                                                id="hazard_desc"
                                                type="text"
                                                error={errors.hazard_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.hazard_desc
                                                })} />
                                            <span className="text-danger">{errors.hazard_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hcpcs">HCPCS Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hcpcs}
                                                id="hcpcs"
                                                type="text"
                                                error={errors.hcpcs}
                                                className={classnames("form-control", {
                                                    invalid: errors.hcpcs
                                                })} />
                                            <span className="text-danger">{errors.hcpcs}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hic_code">Primary Ingredient HIC4 Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hic_code}
                                                id="hic_code"
                                                type="text"
                                                error={errors.hic_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.hic_code
                                                })} />
                                            <span className="text-danger">{errors.hic_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hic_desc">Primary Ingredient HIC4 Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hic_desc}
                                                id="hic_desc"
                                                type="text"
                                                error={errors.hic_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.hic_desc
                                                })} />
                                            <span className="text-danger">{errors.hic_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="hri">HRI #</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.hri}
                                                id="hri"
                                                type="text"
                                                error={errors.hri}
                                                className={classnames("form-control", {
                                                    invalid: errors.hri
                                                })} />
                                            <span className="text-danger">{errors.hri}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ndc">NDC 11 digits</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.ndc}
                                                id="ndc"
                                                type="text"
                                                error={errors.ndc}
                                                className={classnames("form-control", {
                                                    invalid: errors.ndc
                                                })} />
                                            <span className="text-danger">{errors.ndc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="niosh_code">NIOSH Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.niosh_code}
                                                id="niosh_code"
                                                type="text"
                                                error={errors.niosh_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.niosh_code
                                                })} />
                                            <span className="text-danger">{errors.niosh_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="niosh_code">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="text"
                                                error={errors.website}
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })} />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="niosh_desc">NIOSH Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.niosh_desc}
                                                id="niosh_desc"
                                                type="text"
                                                error={errors.niosh_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.niosh_desc
                                                })} />
                                            <span className="text-danger">{errors.niosh_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="omp">OMP Item Family</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.omp}
                                                id="omp"
                                                type="text"
                                                error={errors.omp}
                                                className={classnames("form-control", {
                                                    invalid: errors.omp
                                                })} />
                                            <span className="text-danger">{errors.omp}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="omp_desc">OMP Item Family Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.omp_desc}
                                                id="omp_desc"
                                                type="text"
                                                error={errors.omp_desc}
                                                className={classnames("form-control", {
                                                    invalid: errors.omp_desc
                                                })} />
                                            <span className="text-danger">{errors.omp_desc}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="omp_indicator">OMP Item Indicator</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.omp_indicator}
                                                id="omp_indicator"
                                                type="text"
                                                error={errors.omp_indicator}
                                                className={classnames("form-control", {
                                                    invalid: errors.omp_indicator
                                                })} />
                                            <span className="text-danger">{errors.omp_indicator}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="orange_code">Orange Book Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.orange_code}
                                                id="orange_code"
                                                type="text"
                                                error={errors.orange_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.orange_code
                                                })} />
                                            <span className="text-danger">{errors.orange_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="package_awp">Package AWP</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.package_awp}
                                                id="package_awp"
                                                type="text"
                                                error={errors.package_awp}
                                                className={classnames("form-control", {
                                                    invalid: errors.package_awp
                                                })} />
                                            <span className="text-danger">{errors.package_awp}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price_per_dose">Price Per Dose($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price_per_dose}
                                                id="price_per_dose"
                                                type="text"
                                                error={errors.price_per_dose}
                                                className={classnames("form-control", {
                                                    invalid: errors.price_per_dose
                                                })} />
                                            <span className="text-danger">{errors.price_per_dose}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="retail_price">Retail Price($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.retail_price}
                                                id="retail_price"
                                                type="text"
                                                error={errors.retail_price}
                                                className={classnames("form-control", {
                                                    invalid: errors.retail_price
                                                })} />
                                            <span className="text-danger">{errors.retail_price}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="route_code">Route Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.route_code}
                                                id="route_code"
                                                type="text"
                                                error={errors.route_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.route_code
                                                })} />
                                            <span className="text-danger">{errors.route_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="route_desc">Route Desc</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.route_desc} error={errors.route_desc} id="route_desc" className={classnames("form-control", { invalid: errors.route_desc })}>
                    
                                                
                                                <option value="BUCCAL">BUCCAL</option>
                                                <option value="DENTAL">DENTAL</option>
                                                <option value="EPIDURAL">EPIDURAL</option>
                                                <option value="IMPLANTATION">IMPLANTATION</option>
                                                <option value="INHALATION">INHALATION</option>
                                                <option value="INJECTION">INJECTION</option>
                                                <option value="INTRAARTERIAL">INTRAARTERIAL</option>
                                                <option value="INTRAARTICULAR">INTRAARTICULAR</option>
                                                <option value="INTRACAVERNOSAL">INTRACAVERNOSAL</option>
                                                <option value="INTRADERMAL">INTRADERMAL</option>
                                                <option value="INTRAMUSCULAR">INTRAMUSCULAR</option>
                                                <option value="INTRAOCULAR">INTRAOCULAR</option>
                                                <option value="INTRATHECAL">INTRATHECAL</option>
                                                <option value="INTRAUTERINE">INTRAUTERINE</option>
                                                <option value="INTRAVENOUS">INTRAVENOUS</option>
                                                <option value="INTRAVESICAL">INTRAVESICAL</option>
                                                <option value="IRRIGATION">IRRIGATION</option>
                                                <option value="MISCELLANEOUS">MISCELLANEOUS</option>
                                                <option value="MUCOUS MEMBRANE">MUCOUS MEMBRANE</option>
                                                <option value="NASAL">NASAL</option>
                                                <option value="OPHTHALMIC">OPHTHALMIC</option>
                                                <option value="ORAL">ORAL</option>
                                                <option value="OTIC(EAR)">OTIC(EAR)</option>
                                                <option value="PERFUSION">PERFUSION</option>
                                                <option value="RECTAL">RECTAL</option>
                                                <option value="SUBCUTANEOUS">SUBCUTANEOUS</option>
                                                <option value="SUBLINGUAL">SUBLINGUAL</option>
                                                <option value="TOPICAL">TOPICAL</option>
                                                <option value="TRANSLINGUAL">TRANSLINGUAL</option>
                                                <option value="TRANSDERMAL">TRANSDERMAL</option>
                                                <option value="URETHRAL">URETHRAL</option>
                                                <option value="VAGINAL">VAGINAL</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="source">Single/Multi Source</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.source}
                                                id="source"
                                                type="text"
                                                error={errors.source}
                                                className={classnames("form-control", {
                                                    invalid: errors.source
                                                })} />
                                            <span className="text-danger">{errors.source}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="supplier_name">Supplier Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.supplier_name}
                                                id="supplier_name"
                                                type="text"
                                                error={errors.supplier_name}
                                                className={classnames("form-control", {
                                                    invalid: errors.supplier_name
                                                })} />
                                            <span className="text-danger">{errors.supplier_name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_dose_code">Unit Dose Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_dose_code}
                                                id="unit_dose_code"
                                                type="text"
                                                error={errors.unit_dose_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_dose_code
                                                })} />
                                            <span className="text-danger">{errors.unit_dose_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_dose_product">Unit Dose Product</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_dose_product}
                                                id="unit_dose_product"
                                                type="text"
                                                error={errors.unit_dose_product}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_dose_product
                                                })} />
                                            <span className="text-danger">{errors.unit_dose_product}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_size_code">Unit Size Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_size_code}
                                                id="unit_size_code"
                                                type="text"
                                                error={errors.unit_size_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_size_code
                                                })} />
                                            <span className="text-danger">{errors.unit_size_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_size_qty">Unit Size Qty</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_size_qty}
                                                id="unit_size_qty"
                                                type="text"
                                                error={errors.unit_size_qty}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_size_qty
                                                })} />
                                            <span className="text-danger">{errors.unit_size_qty}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_strength_code">Unit Strength Code</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_strength_code}
                                                id="unit_strength_code"
                                                type="text"
                                                error={errors.unit_strength_code}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_strength_code
                                                })} />
                                            <span className="text-danger">{errors.unit_strength_code}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="unit_strength_qty">Unit Strength Qty</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.unit_strength_qty}
                                                id="unit_strength_qty"
                                                type="text"
                                                error={errors.unit_strength_qty}
                                                className={classnames("form-control", {
                                                    invalid: errors.unit_strength_qty
                                                })} />
                                            <span className="text-danger">{errors.unit_strength_qty}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="uom">UOM</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.uom}
                                                id="uom"
                                                type="text"
                                                error={errors.uom}
                                                className={classnames("form-control", {
                                                    invalid: errors.uom
                                                })} />
                                            <span className="text-danger">{errors.uom}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="upc_barcode">UPC Barcode</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.upc_barcode}
                                                id="upc_barcode"
                                                type="text"
                                                error={errors.upc_barcode}
                                                className={classnames("form-control", {
                                                    invalid: errors.upc_barcode
                                                })} />
                                            <span className="text-danger">{errors.upc_barcode}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-drug"
                                    type="submit"
                                    // data-toggle="modal" data-target="#add-drug-modal"
                                    className="btn btn-primary">
                                    Add Drug
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DrugsAddModal;
