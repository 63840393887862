import React from "react";
import classnames from "classnames";
import { setCurrentUser } from "../../actions/authActions";
import axios from "axios";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class TermCondition extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      selectedFile: null,
      loading: false,
      termscondition: false,
    };
  }

  componentDidMount() {
    axios.post("/api/lastdate").then((res) => {
      this.setState({ lastDate: res.data.lastDate });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#add-user-modal").modal("hide");
      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
      loaded: 0,
    });
  };

  onUpload = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    this.setState({ loading: true });
    axios
      .post("/api/upload-records", data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        $("#add-user-modal").modal("hide");
        this.setState({ loading: false });
        this.props.getData();
      });
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  acceptTerms = () => {
    axios
      .post("/api/user-update", {
        id: this.props.auth.user.id,
        termCondition: 1,
      })
      .then((res) => {
        // then print response status
        let currentUser = this.props.auth.user;
        currentUser.termCondition = 1;
        this.props.setCurrentUser(this.props.auth.user);
        $("#term-condition").modal("hide");
        window.open(
          "https://therx.net/EnterRegCode?regCode=HW-BONARXNP-2024&rxGroup=HWBBVV",
          "_blank"
        );
      });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="term-condition" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Accept Terms and Conditions</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="term-condition">
                  <p>
                    <strong>License</strong>
                  </p>

                  <p>
                    We, PSGA, Inc., Inc grants to Clients a non-transferable,
                    non-exclusive right to use the PSGA, Inc. license subject to
                    the Terms and Conditions set forth herein.
                  </p>

                  <p>
                    <strong>Fees</strong>
                  </p>

                  <p>
                    Clients to pay PSGA, Inc. support fees each month beginning
                    the month the System is installed for Customer&rsquo;s use
                    and continuing throughout the term hereof; provided however,
                    beginning one (1) year after the Effective Date hereof,
                    PSGA, Inc. shall have the right to increase the monthly
                    support fee once every 12 months by five percent or the CPI
                    (all items), whichever is greater. All invoices are Due Upon
                    Receipt.
                  </p>

                  <p>
                    <strong>Services</strong>
                  </p>

                  <p>
                    PSGA, Inc. shall have the right to add or change
                    functionality to the System at times determined by PSGA,
                    Inc.
                  </p>

                  <p>
                    <strong>Term</strong>
                  </p>

                  <p>
                    The initial term of the license under this Agreement shall
                    be twelve (12) months commencing on the Effective Date and
                    ending on the last day of the 12th month thereafter. Upon
                    the expiration of the initial term, this Agreement shall
                    automatically renew for successive twelve (12) month terms
                    unless either party gives the other party written notice of
                    its intent not to renew at least sixty (60) days prior to
                    the renewal date.
                  </p>

                  <p>
                    <strong>Termination</strong>
                  </p>

                  <p>
                    PSGA, Inc. may terminate this Agreement and declare all
                    amounts then due hereunder for the remaining term,
                    immediately due and payable in the event of any of the
                    following:
                  </p>

                  <p>
                    (a) failure of Customer to pay any fee or other payment
                    hereunder when due, which failure continues for seven (7)
                    days after written notice to Customer of such default.
                  </p>

                  <p>
                    (b) any breach by Customer of any other covenant, warranty,
                    or other term hereunder, which breach continues for thirty
                    (30) days after written notice to Customer thereof; or
                  </p>

                  <p>
                    (c) Any bankruptcy or insolvency proceedings for Customer.
                  </p>

                  <p>
                    In the event Customer is in default hereunder, PSGA, Inc.
                    shall not be required to provide Customer with any services
                    in connection with the System. Upon termination of this
                    Agreement, the license to use the System shall terminate,
                    and Customer and its customers shall no longer have access
                    to the System website. PSGA, Inc. and Customer acknowledge
                    and agree that the nature of the services provided for
                    herein may now or hereafter necessitate the storage of data
                    and other information relating to the Customer&rsquo;s
                    customers. To the extent PSGA, Inc. is required to convert
                    or store any data or information of Customer&rsquo;s
                    customers upon termination hereof, Customer agrees to
                    reimburse PSGA, Inc. for all costs and expenses incurred in
                    connection any such conversion or storage.
                  </p>

                  <p>
                    <strong>Warranty</strong>
                  </p>

                  <p>
                    PSGA, Inc. will use due care in performing all services
                    required hereunder and agrees that it will, at its expense,
                    use its best efforts to correct any errors to the extent
                    that such errors are due to the malfunction of PSGA,
                    Inc.&rsquo;s server, operating systems, or programs, or to
                    errors by PSGA, Inc.&rsquo;s employees or agents.
                    Corrections shall be limited to recreation of data or
                    program files. This Limited Warranty is exclusive and is in
                    lieu of all other warranties, and Customer hereby waives all
                    other warranties, expressed, implied, or statutory,
                    including any implied warranties of merchantability or
                    fitness for a particular purpose, relating to the System and
                    any required services hereunder.
                  </p>

                  <p>
                    <strong>Liability Limit</strong>
                  </p>

                  <p>
                    In no event shall PSGA, Inc. be liable to Customer for
                    indirect, special, punitive, or consequential damages
                    (including loss of profits, loss of data, or damage to
                    business reputation), even if PSGA, Inc. has been advised of
                    the possibility of certain damages. Due to the nature of the
                    services being provided by PSGA, Inc., it is agreed that in
                    no event shall PSGA, Inc. be liable for any claim, loss,
                    correction, damage, or expense caused by PSGA, Inc.&rsquo;s
                    performance or failure to perform hereunder, which is not
                    reported by Customer within thirty (30) days of such
                    performance or failure to perform. Neither party shall be
                    deemed to be in default of any provisions of this Agreement
                    or be liable to the other party or to any third party for
                    any delay, failure in performance, or interruption of
                    performance resulting directly or indirectly from acts of
                    God, war, insurrection, riot, strikes, civil disturbance,
                    interruption of electrical power or communications, or other
                    technical causes beyond the control and without the fault or
                    negligence of a party. Liability of PSGA, Inc. in any and
                    all categories and for any and all causes arising out of
                    this Agreement shall not in the aggregate exceed the total
                    monthly service charges paid by Customer to PSGA, Inc.
                    during the preceding twelve (12) month period.
                  </p>

                  <p>
                    <strong>Use of Customer Data</strong>
                  </p>

                  <p>
                    Customer hereby stipulates and agrees that PSGA, Inc. may
                    use and disclose, and may authorize its agents, affiliates,
                    subcontractors (including any downstream third party service
                    provider) to use and disclose, except as otherwise
                    prohibited by applicable law, any and all data and
                    information, including, but not limited to, protected health
                    information and other information of Customer&rsquo;s
                    customer(s), created or received by, disclosed, or otherwise
                    made available to, PSGA, INC. or any agent, affiliate or
                    subcontractor of PSGA, Inc. in connection with this
                    Agreement, or any other arrangement or agreement between
                    PSGA, Inc. and Customer. Without limitation, such use and
                    disclosures shall include: (a) using data and information to
                    provide data aggregation services as permitted by 45 CFR
                    &sect; 164.504(e)(2)(i)(B); and (b) using data and
                    information to create information that is not individually
                    identifiable health information under applicable federal
                    law, and (c) disclosure to any third party not otherwise
                    prohibited by law. Customer warrants that it has obtained or
                    will obtain such authorization from its customers as may be
                    required under applicable law to permit the Customer and/or
                    PSGA, INC. to use and disclose such information.
                  </p>

                  <p>
                    <strong>Excessive Use of the Service</strong>
                  </p>

                  <p>
                    As the Service is a multi-tenant service offering on shared
                    databases, Customer may not use the Service in excess of
                    limits or thresholds that PSGA, Inc. considers commercially
                    reasonable for the Service. If PSGA, Inc. reasonably
                    concludes that a Customer&rsquo;s use is excessive and/or
                    will cause immediate or ongoing performance issues for one
                    or more of PSGA, Inc.&rsquo; other customers, PSGA, Inc. may
                    slow down or throttle Customer&rsquo;s excess use until such
                    time that Customer&rsquo;s use stays within reasonable
                    limits. If Customer&rsquo;s particular usage pattern
                    requires a higher limit or threshold, then the Customer
                    should procure a subscription to the Service that
                    accommodates a higher limit and/or threshold that more
                    effectively aligns with the Customer&rsquo;s actual usage
                    pattern.
                  </p>

                  <p>
                    <strong>HIPAA</strong>
                  </p>

                  <p>
                    To the extent the nature of the relationship between
                    Customer and PSGA, Inc. involves the use and disclosure of
                    Protected Health Information (as that term is defined in 45
                    C.F.R. Section 160.103), each party agrees to execute a
                    mutually agreeable Business Associate Agreement.
                  </p>

                  <p>
                    <strong>Indemnification</strong>
                  </p>

                  <p>
                    Customer assumes liability for and hereby agrees to
                    indemnify and hold PSGA, Inc. harmless for any and all
                    claims, actions, losses, damages, including reasonable
                    attorneys&rsquo; fees, obligations, liabilities and liens
                    arising out of any act or omissions of Customer its
                    employees or agents or customers. Furthermore, Customer
                    hereby releases and discharges PSGA, Inc. from and against
                    any and all claims, actions, losses, damages, obligations,
                    and liabilities, including without limitation, reasonable
                    attorneys&rsquo; fees, now existing or hereafter arising,
                    that arise out of or are related to the System, regardless
                    of whether such claims, actions, losses, damages,
                    obligations, or liabilities are caused or alleged to be
                    caused by the sole or concurrent negligence of PSGA, Inc.,
                    its employees or agents, unless occasioned solely by the
                    gross negligence or willful misconduct of PSGA, Inc.. These
                    provisions shall survive termination of this Agreement with
                    respect to events occurring prior to such termination.
                  </p>

                  <p>
                    <strong>Compliance With Laws</strong>
                  </p>

                  <p>
                    Customer and its agent(s) shall always during the term of
                    this Agreement strictly adhere to, and comply fully with,
                    all applicable federal, state and local rules, regulations
                    and laws, Executive Orders and implementing regulations as
                    they currently exist and may hereafter be amended,
                    including, but not limited to, those pertaining to
                    Customer&rsquo;s customers&rsquo; rights to, and privacy and
                    security of, any and all data and other information about
                    such customers.
                  </p>

                  <p>
                    <strong>Relationship of Parties</strong>
                  </p>

                  <p>
                    Nothing contained in this Agreement shall be construed as
                    creating a joint venture, partnership or employment
                    arrangement between the parties hereto, nor shall either
                    party have the right, power or authority to create any
                    obligation or duty, expressed or implied, on behalf of the
                    other party hereto.
                  </p>

                  <p>
                    <strong>Notices</strong>
                  </p>

                  <p>
                    Notices required to be given pursuant to this Agreement
                    shall be in writing and shall be deemed to have been duly
                    given if delivered personally, transmitted by confirmed
                    facsimile, or sent by a nationally recognized overnight
                    courier service, or by registered or certified mail, postage
                    prepaid, to the addresses set forth above.
                  </p>

                  <p>
                    <strong>Waiver</strong>
                  </p>

                  <p>
                    No failure on the part of PSGA, Inc. to exercise and no
                    delay in exercising any right or remedy hereunder shall
                    operate as a waiver thereof or modify the terms of this
                    Agreement. The exercise of any one remedy shall not be
                    deemed to waive or preclude the exercise of any other
                    remedy.
                  </p>

                  <p>
                    <strong>Law</strong>
                  </p>

                  <p>
                    This Agreement shall be governed by and construed in
                    accordance with the laws of the State of Florida, without
                    regard to any choice of law provisions thereof, or any other
                    principle that could require the application of the law of
                    any other state. This Agreement is performable in Orange
                    County, Florida, which shall be the exclusive venue for any
                    actions or proceedings relating to this Agreement.
                  </p>

                  <p>
                    <strong>Data from Third Party Sources</strong>
                  </p>

                  <p>
                    PSGA, Inc. uses data sources, both public and commercial, in
                    some PSGA, Inc. products such as RxConnexion, BonaRx and
                    TabulaRx.to provide Customer with enhanced services. This
                    data is to be used by the Customer in delivering
                    pharmaceutical care such as Pharmacy Collaborative Practice,
                    Medication Therapy Monitoring (&ldquo;MTM&rdquo;) or
                    business analytics.
                  </p>

                  <p>
                    <strong>Severability</strong>
                  </p>

                  <p>
                    If any provision hereof is found to be invalid or
                    unenforceable pursuant to any judicial, administrative, or
                    other governmental decree or decision, rule or law, the
                    remainder of this Agreement shall remain valid and
                    enforceable according to its terms. It is expressly
                    understood and agreed that each provision of this Agreement
                    that provides for a disclaimer of warranties, limitation on
                    liability, or exclusion of damages is intended by the
                    parties to be severable and independent of any other
                    provision and to be enforced as such.
                  </p>

                  <p>
                    <strong>Confidentiality</strong>
                  </p>

                  <p>
                    Customer shall not divulge or make available to any party
                    other than PSGA, Inc. or use for their own purposes, any
                    documents or Confidential Information or other business data
                    of PSGA, Inc. furnished to Customer under this Agreement
                    without the prior written consent of PSGA, Inc. and shall
                    keep the same in strictest confidence, except insofar as
                    such documents and material, or any part thereof, have been
                    previously published, become part of the public domain, or
                    have been provided under an obligation created by court or
                    governmental action. &ldquo;Confidential Information&rdquo;
                    as that phrase is used herein, means and includes, (whether
                    or not identified as confidential, and whether or not in
                    writing and whether disclosed to the other party before or
                    after the effective date of the Agreement, any information
                    disclosed by PSGA, Inc. to Customer either directly or
                    indirectly in writing, orally, or by inspection of tangible
                    objects (i) that PSGA, INC. identifies as confidential or
                    proprietary; or (ii) that reasonably appears to be
                    confidential or proprietary because of legends or other
                    markings, the circumstances of disclosure, or the nature of
                    the information itself including, but not limited to:
                    intellectual property, technology and/or business of PSGA,
                    Inc. including, but not limited to know-how, inventions,
                    discoveries, formulae, ideas, designs, drawings, plans,
                    reports, business, marketing, sales or financial information
                    which PSGA, INC. maintains as confidential and proprietary.
                  </p>

                  <p>
                    <strong>Proprietary Rights</strong>
                  </p>

                  <p>
                    Customer agrees that the System and all licensed materials
                    (including, without limitation, databases, program
                    statements, systems, products, services, layouts, designs,
                    formats, developments, changes, documentation, software,
                    websites, algorithms, report formats, modifications or new
                    features relating thereto and know-how), as delivered by
                    PSGA, Inc. or as subsequently updated, merged, substituted,
                    or modified by PSGA, Inc. (other than Customer&rsquo;s
                    customer data or Customer&rsquo;s proprietary marks included
                    on the website), is, and shall remain, the confidential and
                    proprietary property of PSGA, Inc. regardless of whether
                    this information is marked as confidential or proprietary.
                    Proprietary information shall include (a) the terms of this
                    Agreement (but not the existence thereof) and all
                    information disclosed by PSGA, Inc. to the Customer pursuant
                    to this Agreement; (b) any information or material that
                    would give a third party some competitive business advantage
                    or the opportunity of obtaining such advantage, or the
                    disclosure of which could be detrimental to the interests of
                    PSGA, Inc. to this Agreement and (c) any information or
                    material known by Customer to be confidential or
                    proprietary; or (d) any information or material which should
                    be known or understood to be confidential or proprietary by
                    an individual exercising reasonable commercial judgment in
                    the circumstances. Customer acknowledges and agrees that
                    PSGA, Inc.&rsquo; Proprietary Information constitutes
                    confidential material and trade secrets of PSGA, Inc. PSGA,
                    Inc. claims and reserves all rights and interest in and to
                    the System and licensed material and all related
                    documentation and Customer shall not take any action
                    inconsistent with such ownership. PSGA, Inc. claims and
                    reserves all benefits afforded under federal and
                    international copyright law in all programming and
                    documentation comprising the System as copyrighted works. No
                    title or other ownership interest in the System and/or the
                    licensed materials is now or hereafter transferred to
                    Customer and Customer acknowledges and agrees that PSGA,
                    Inc. owns and will continue to own all right, title and
                    interest in and to the System. Customer will not contest the
                    ownership of the System and licensed materials and will
                    cooperate with PSGA, Inc. in defending PSGA, Inc.&rsquo;s
                    ownership rights to the System and Licensed Materials. The
                    restrictions in this Agreement shall not be construed to
                    supersede or eliminate any rights which PSGA, Inc. may have
                    under applicable laws pertaining to trade secrets. Customer
                    may not recreate, copy, duplicate or use the System, in
                    whole or in part, except in compliance with this Agreement.
                    Any and all use of the System by Customer shall always
                    include all applicable PSGA, Inc. notices. Customer shall
                    not remove or alter PSGA, Inc. proprietary notices and
                    legends in and on the System and/or on any licensed
                    materials. Customer may not copy or duplicate the System or
                    licensed material, in whole or in part. Notwithstanding the
                    foregoing, Customer acknowledges and agrees that PSGA, Inc.
                    may apply copy-protection routines or devices to the System
                    and/or licensed material. Customer shall not: (a)decompile,
                    reverse compile, reverse assemble, reverse translate or
                    otherwise reverse engineer the System and or licensed
                    material; (b) circumvent any technological measure that
                    controls access to the System or licensed materials; or (c)
                    use the System and licensed material other than pursuant to
                    the terms of this Agreement. Customer further agrees to keep
                    the System and/or Licensed Materials free and clear of all
                    claims, liens and encumbrances, and any act of Customer
                    purporting to establish any claim, lien or encumbrance shall
                    be void. In the event of a breach of any of the provisions
                    of this Agreement, PSGA, Inc. shall be entitled, in addition
                    to any other available legal or equitable remedies, to
                    exparte injunctive relief without any requirement to post
                    bond as a condition of such relief.
                  </p>

                  <p>
                    <strong>Maintenance of Records</strong>
                  </p>

                  <p>
                    The parties agree that PSGA, Inc. can maintain a copy of
                    this Agreement and any books, documents, records and other
                    data of Customer as may be required to be maintained by
                    PSGA, Inc. by applicable law, for such periods as such laws
                    may require.
                  </p>

                  <p>
                    <strong>Assignment</strong>
                  </p>

                  <p>
                    The parties agree that either may assign this Agreement to
                    any parent, subsidiary, affiliate, or successor in interest
                    (including a successor in interest to substantially all the
                    assets of the assigning party). Except as noted, neither
                    party may assign this Agreement, absent written consent of
                    the other party, which will not be unreasonably withheld,
                    conditioned, or delayed. Any attempted assignment without
                    such consent shall be void.
                  </p>

                  <p>
                    <strong>Entire Agreement, Amendments</strong>
                  </p>

                  <p>
                    This Agreement consists of the entire Agreement,
                    understanding, and representations, express or implied,
                    between PSGA, Inc. and Customer regarding the subject matter
                    hereof and supersedes all prior and contemporaneous
                    communications between the parties including all oral or
                    written proposals. No representation, warranty, promise,
                    inducement, or statement of intention has been made by
                    either party which is not embodied in this Agreement, and
                    neither PSGA, Inc., on the one hand, or Customer, on the
                    other hand, shall be bound by, or be liable for, any alleged
                    representation, warranty, promise, inducement, or statement
                    of intention not embodied herein. Any amendments to this
                    Agreement must be in writing signed by both parties hereto.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <input
                  type="checkbox"
                  class="form-check float-left"
                  Checked={this.state.termscondition}
                  onClick={this.toggleChange}
                  id="termscondition"
                />
                <b>
                  <p className="float-left">Accept Terms & Conditions</p>
                </b>
                <div className="float-right">
                  {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button> */}
                  <button
                    type="button"
                    onClick={this.acceptTerms}
                    disabled={!this.state.termscondition}
                    className="btn btn-primary"
                  >
                    Continue
                  </button>
                </div>

                {/* <a target="_blank"  className="btn btn-primary" href="https://therx.net/EnterRegCode?regCode=HW-BONARXNP-2021&rxGroup=HWBBVV">Register</a> */}
              </div>
              <ClipLoader
                color="#e2e2e2"
                loading={this.state.loading}
                css={override}
                size={150}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setCurrentUser })(TermCondition);
