import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import $ from 'jquery';
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"



class BusinessToolUpdateModal extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            report_date: "",
            revenue: "",
            purchase: "",
            returns: "",
            ra_number: "",
            estimated_profit: "",
            total_rx: "",
            new_rx: "",
            refill_rx: "",
            errors: {},
        };
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.record.report_date!==undefined){
            nextProps.record.report_date = nextProps.record.orig_report_date
        }
        nextProps.record.revenue = nextProps.record.orig_revenue
        nextProps.record.purchase = nextProps.record.orig_purchase
        nextProps.record.returns = nextProps.record.orig_returns
        console.log(nextProps.record)
        if (nextProps.record) {
            this.setState({
                ...nextProps.record,
                report_date: moment(nextProps.record.report_date).toDate()
            });
            // console.log(nextProps.record.report_date)
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#update-report-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    setFromDate = (date) => {
        this.setState({ assigned_date: date });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value },
            () => this.updateCalculation());
    };
    updateCalculation = () => {
        if(this.state.revenue!=="" && this.state.purchase!=="" && this.state.returns!==""){
            this.setState({
                estimated_profit: parseFloat(this.state.revenue)-parseFloat(this.state.purchase)+parseFloat(this.state.returns)
            })
        }
        if(this.state.new_rx!=="" && this.state.refill_rx!==""){
            this.setState({
                total_rx: parseFloat(this.state.new_rx)+parseFloat(this.state.refill_rx)
            })
        }
    }

    onReportUpdate = e => {
        e.preventDefault();
        const newBusinessReport = {
            report_date: this.state.report_date,
            revenue: this.state.revenue,
            purchase: this.state.purchase,
            ra_number: this.state.ra_number,
            returns: this.state.returns,
            new_rx: this.state.new_rx,
            refill_rx: this.state.refill_rx,
            total_rx: this.state.total_rx,
            estimated_profit: this.state.estimated_profit,
            };
            axios
                .post('/api/analytic-tool-update', newBusinessReport)
                .then(res => {
                    this.props.getData();
                    $('#update-report-modal').modal('hide');
                }).catch(err =>
                    console.log(err)
                )
        
    };
    setReportDate = (date) => {
        this.setState({ report_date: date });
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }
    render() {
        const { errors } = this.state;
        console.log(this.state.report_date)

        return (
            <div>
            <div className="modal fade" id="update-report-modal" data-reset="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Pharmacy Business Analytics Tool</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form noValidate onSubmit={this.onReportUpdate} id="update-report">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Date</label>
                                    </div>
                                    <div className="col-md-9">
                                        <DatePicker selected={this.state.report_date} maxDate={moment().toDate()} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                        <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                        <span className="text-danger">{errors.report_date}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="revenue">Revenue($)</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.revenue}
                                            error={errors.revenue}
                                            id="revenue"
                                            type="revenue"
                                            required
                                            className={classnames("form-control", {
                                                invalid: errors.revenue
                                            })}
                                        />
                                        <span className="text-danger">{errors.revenue}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="purchase">Purchases($)</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.purchase}
                                            error={errors.purchase}
                                            id="purchase"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.purchase
                                            })}
                                        />
                                        <span className="text-danger">{errors.purchase}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="returns">Returns($)</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            // autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.returns}
                                            id="returns"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.returns
                                            })}
                                        />
                                        <span className="text-danger">{errors.returns}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="ra_number">RA Number</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            // autoComplete={''}
                                            onChange={this.onChange}
                                            value={this.state.ra_number}
                                            id="ra_number"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.ra_number
                                            })}
                                        />
                                        <span className="text-danger">{errors.ra_number}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="ra_number">Estimated Gross Profit</label>
                                    </div>
                                    <div className="col-md-9">
                                        {this.state.estimated_profit?Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(this.state.estimated_profit):""}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="new_rx">New RX</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.new_rx}
                                            id="new_rx"
                                            type="text"
                                            error={errors.new_rx}
                                            className={classnames("form-control", {
                                                invalid: errors.new_rx
                                            })} />
                                        <span className="text-danger">{errors.new_rx}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="refill_rx">Refill RX </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.refill_rx}
                                            id="refill_rx"
                                            type="text"
                                            error={errors.rx_bin}
                                            className={classnames("form-control", {
                                                invalid: errors.refill_rx
                                            })} />
                                        <span className="text-danger">{errors.refill_rx}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="total_rx">Total Rx</label>
                                    </div>
                                    <div className="col-md-9">
                                        {this.state.total_rx}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-report"
                                type="submit"
                                data-toggle="modal" data-target="#update-report-modal"
                                className="btn btn-primary">
                            
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default (withRouter(BusinessToolUpdateModal));
