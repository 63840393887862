import React, { Component } from "react";

import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { NavLink, Link } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bo from "../../utils/bo.png";
import { faHospital, faUser, faChartBar, faCapsules, faUserMd, faUserFriends, faUserNurse, faPrescriptionBottle, faChartLine, faClinicMedical, faMapSigns, faChartPie, faLaptopMedical, faCog, faTools, faDatabase, faBars, faEnvelope, faBriefcase, faWallet, faClipboardList, faDollarSign } from "@fortawesome/free-solid-svg-icons";

const css = `
    .offset-2 {
        margin-left: 60px;
    }`

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    };

    handleToggleSidebar = () => {
        this.setState({ collapsed: !this.state.collapsed })
    }

    render() {
        //const { user } = this.props.auth;
        return (
            <ProSidebar collapsed={this.state.collapsed}>
                {this.state.collapsed && <style>{css}</style>}
                <SidebarHeader>
                    {!this.state.collapsed && <NavLink activeClassName="is-active" to="/dashboard"><img src={bo} /></NavLink>}
                    <FontAwesomeIcon icon={faBars} onClick={this.handleToggleSidebar} />
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem icon={<FontAwesomeIcon icon={faClinicMedical} />}><NavLink activeClassName="is-active" to="/dashboard" className="list-group-item list-group-item-action">Dashboard</NavLink></MenuItem>
                        <SubMenu title="Card Reports" icon={<FontAwesomeIcon icon={faChartBar} />}>
                            <MenuItem><NavLink activeClassName="is-active" to="/reports" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartBar} />{"  "}Detailed Reports</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/group-stats" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserFriends} />{"  "}Group Stats</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/physician-report" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserNurse} />{"  "}Physician Report</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/drug-report" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faPrescriptionBottle} />{"  "}Drug Dispense Report</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/controlled-drug" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartLine} />{"  "}Control Drug Report</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/reports-ahfs" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartPie} />{"  "}Therapeutic Class Reports</NavLink></MenuItem>
                        </SubMenu>
                        <SubMenu title="Lookup" icon={<FontAwesomeIcon icon={faDatabase} />}>
                            <SubMenu title="Drugs" icon={<FontAwesomeIcon icon={faCapsules} />}>
                                <MenuItem><NavLink activeClassName="is-active" to="/coupons" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faDollarSign} />{"  "}Coupons</NavLink></MenuItem>
                                <MenuItem><NavLink activeClassName="is-active" to="/drugs" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faCapsules} />{"  "}Drug List</NavLink></MenuItem>
                                <MenuItem><NavLink activeClassName="is-active" to="/animal-drug" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartLine} />{"  "}Animal Drug List</NavLink></MenuItem>
                                <MenuItem><NavLink activeClassName="is-active" to="/nadac" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}NADAC</NavLink></MenuItem>
                                <MenuItem><NavLink activeClassName="is-active" to="/drug-utilization" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}Drug Utilization</NavLink></MenuItem>
                            </SubMenu>
                            <MenuItem><NavLink activeClassName="is-active" to="/npis" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}NPI</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/hcpcs" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartLine} />{"  "}HCPCS</NavLink></MenuItem>
                            {/* <MenuItem><NavLink activeClassName="is-active" to="/dea" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}DEA</NavLink></MenuItem> */}
                        </SubMenu>
                        <SubMenu title="Tools" icon={<FontAwesomeIcon icon={faTools} />}>
                            <MenuItem><NavLink activeClassName="is-active" to="/business-tool" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartBar} />{"  "}Business Anayltics</NavLink></MenuItem>
                            <MenuItem><a href="https://www.ehealthmedicareplans.com/ehi/medicare/mini-site-v2?allid=rxr15916000&amp;msType=msp-
rxreach&amp;partnerAllid=true&amp;planYear=2022#/quote-plans/MA/32707/10580/SEMINOLE/ssd90232537/1/p1" target="_blank" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faCapsules} />{"  "}AEP Med D Plans</a></MenuItem>
                            <MenuItem><a href="https://www.rxreach.com/lookup" target="_blank" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faChartBar} />{"  "}Med D Drug Lookup</a></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/prescriber-rx" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}Prescriber Rx</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/data-2000" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}DATA 2000 Physician</NavLink></MenuItem>
                            <MenuItem><NavLink activeClassName="is-active" to="/internal-physician" className="list-group-item list-group-item-action"><FontAwesomeIcon icon={faUserMd} />{"  "}Physicians Database</NavLink></MenuItem>
                        </SubMenu>
                        {this.props.auth.user.superadmin &&
                            <MenuItem icon={<FontAwesomeIcon icon={faLaptopMedical} />}><NavLink activeClassName="is-active" to="/clia-physician" className="list-group-item list-group-item-action">CLIA Waiver Pharmacies</NavLink></MenuItem>}
                        {this.props.auth.user.superadmin &&
                            <MenuItem icon={<FontAwesomeIcon icon={faMapSigns} />}><NavLink activeClassName="is-active" to="/dea-mapping" className="list-group-item list-group-item-action">Map DEA to NPI</NavLink></MenuItem>}

                    </Menu>
                </SidebarContent>
                <SidebarFooter style={{ textAlign: 'center' }}>
                    <Menu iconShape="square">
                        {!this.props.auth.user.superadmin &&
                            <MenuItem icon={<FontAwesomeIcon icon={faEnvelope} />}><NavLink activeClassName="is-active" to="/support" className="list-group-item list-group-item-action left">Support</NavLink></MenuItem>
                        }
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);
