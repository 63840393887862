import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import logo from "../../utils/rc.png";
import ninth from "../../utils/9.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faHandshake, faPiggyBank, faPrescription, faTag } from "@fortawesome/free-solid-svg-icons";

class RxConnexion extends React.Component {

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title m-3">
              <img src={logo} height={60}/>

                <div className="card">
                <div className="card-body">
                    <h5 className="card-title text-primary h4">Intelligent reports on Demand</h5>
                    <p className="card-text" style={{ fontSize: "18px" }}>Robust pharmacy software providing business intelligence data and analytical reporting to help grow and manage the pharmacy business. Optimizes profitability and business growth while enhancing Star Ratings performance through elevated medication adherence, refill frequency, and quality performance.</p>
                    <br></br>
                    <div className="row">
                      <div className="col-md-8">
                        <ul className="list-group" style={{ marginLeft: "50px" }}>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faHandshake} />{'  '}Built in Audit software</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPrescription} />{'  '}Improve onsite audit outcome – Recall system</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faDollarSign} />{'  '}Identify opportunities and improve profits</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faTag} />{'  '}Copay voucher module</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}Robust demographic</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}Clinical and outcomes improvement</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}Regulatory and QC assistance</li>
                          <li className="list-group-item" style={{ fontSize: "17px" }}><FontAwesomeIcon icon={faPiggyBank} />{'  '}And much more</li>
                        </ul>
                      </div>
                      <div className="col-md-4" style={{ "textAlign": "center", marginTop: "150px" }}><Link className="btn btn-primary" to="/support">Be the First to Know</Link></div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="row">
                      <div className="col-md-12" style={{ "textAlign": "center"}}>
                        <img src={ninth}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default RxConnexion;