import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../utils/BonaRx.png";
import rxr from "../../utils/brx.jpg";
import TermCondition from "../partials/TermCondition";

class Rxreach extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <TermCondition />
              <div className="section-title m-3">
                <img src={logo} height={60} />
                <div className="card">
                  <div className="card-body">
                    <h1
                      className="card-title text-primary"
                      style={{ fontWeight: "bold", fontSize: "47px" }}
                    >
                      Custom Medication Savings Card
                    </h1>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "27px" }}>
                          In today’s pharmacy business environment, it is
                          necessary to have all the tools to help you grow your
                          business. BonaRx provides custom
                        </p>
                      </div>
                      <div className="col-md-7">
                        <p className="card-text" style={{ fontSize: "27px" }}>
                          {" "}
                          medication savings card with a kick. Each time your
                          card is used at any pharmacy nationwide you will
                          receive $1.50 in admin fees plus all the dispensing
                          data which can help you market your pharmacy to local
                          physicians and organizations.
                        </p>
                      </div>
                      <div className="col-md-5" style={{ textAlign: "center" }}>
                        <img style={{ width: "90%" }} src={rxr} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-text" style={{ fontSize: "27px" }}>
                          Get your custom savings card and URL for your website
                          and start growing your business. This program is
                          offered at no cost and we even provide you with your
                          first set of card at no cost.
                          <br></br>
                          {/* <button style={{ marginRight: "63px" }}
                            className="btn btn-success btn-lg float-right" data-toggle="modal" data-target="#term-condition">
                            GET YOUR CUSTOM CARDS - FREE
                          </button> */}
                          <button
                            type="button"
                            style={{ marginRight: "63px" }}
                            className="btn btn-success btn-lg float-right"
                          >
                            <a
                              href="https://therx.net/EnterRegCode?regCode=HW-BONARXNP-2024&rxGroup=HWBBVV"
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              GET STARTED NOW
                            </a>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rxreach;
