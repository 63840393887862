import React from 'react'
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import $ from 'jquery';
import isEmpty from 'is-empty';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment"


class BusinessToolAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            report_date: "",
            revenue: "",
            purchase: "",
            returns: "",
            ra_number: "",
            estimated_profit: "",
            total_rx: "",
            new_rx: "",
            refill_rx: "",
            errors: {},
            update: false
        };
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value },
            () => this.updateCalculation());
    };

    updateCalculation = () => {
        if (this.state.revenue !== "" && this.state.purchase !== "" && this.state.returns !== "") {
            this.setState({
                estimated_profit: parseFloat(this.state.revenue) - parseFloat(this.state.purchase) + parseFloat(this.state.returns)
            })
        }
        if (this.state.new_rx !== "" && this.state.refill_rx !== "") {
            this.setState({
                total_rx: parseFloat(this.state.new_rx) + parseFloat(this.state.refill_rx)
            })
        }
    }

    onReportAdd = e => {
        e.preventDefault();
        let { errors } = this.state
        if (this.state.report_date === '') {
            errors.report_date = "This field is required"
        } else {
            delete errors.report_date
        }
        if (this.state.revenue === '') {
            errors.revenue = "This field is required"
        } else if (isNaN(this.state.revenue)) {
            errors.revenue = "This field should be number"
        } else {
            delete errors.revenue
        }
        if (this.state.purchase === '') {
            errors.purchase = "This field is required"
        } else if (isNaN(this.state.purchase)) {
            errors.purchase = "This field should be number"
        } else {
            delete errors.purchase
        }
        if (this.state.returns === '') {
            errors.returns = "This field is required"
        } else if (isNaN(this.state.returns)) {
            errors.returns = "This field should be number"
        } else {
            delete errors.returns
        }
        if (this.state.new_rx === '') {
            errors.new_rx = "This field is required"
        } else if (isNaN(this.state.new_rx)) {
            errors.new_rx = "This field should be number"
        } else {
            delete errors.new_rx
        }
        if (this.state.refill_rx === '') {
            errors.refill_rx = "This field is required"
        } else if (isNaN(this.state.refill_rx)) {
            errors.refill_rx = "This field should be number"
        } else {
            delete errors.refill_rx
        }
        if (isEmpty(errors)) {
            this.setState({ errors })
            const newWaiver = {
                report_date: this.state.report_date,
                revenue: this.state.revenue,
                purchase: this.state.purchase,
                ra_number: this.state.ra_number,
                returns: this.state.returns,
                new_rx: this.state.new_rx,
                refill_rx: this.state.refill_rx,
                total_rx: this.state.total_rx,
                estimated_profit: this.state.estimated_profit,
                brand_percent: this.props.brand_percent * this.state.purchase / 100,
                generic_percent: this.props.generic_percent * this.state.purchase / 100,
                otc_percent: this.props.otc_percent * this.state.purchase / 100,
                rx_group: this.props.auth.user.rx_group,
                pharmacy_account: this.props.auth.user.final_user,
            };
            let url
            if(this.state.update){
                url = "/api/analytic-tool-update"
            }else{
                url = "/api/analytic-tool-add"
            }
            axios
                .post(url, newWaiver)
                .then(res => {
                    this.props.getData();
                    $('#add-report-modal').modal('hide');
                    this.setState({
                        report_date: "",
                        revenue: "",
                        purchase: "",
                        returns: "",
                        ra_number: "",
                        estimated_profit: "",
                        total_rx: "",
                        new_rx: "",
                        refill_rx: "",
                    })
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }).catch(err =>
                    console.log(err)
                )
        } else {
            this.setState({ errors })
        }
    };

    setReportDate = (date) => {
        this.setState({ report_date: date, update: false });
        let filterData = {sort_order: { column: "report_date", order: "desc" }}
        filterData.search = {
            from_date: date,
            to_date: date,
            pharmacy_account: this.props.auth.user.final_user,
        }
        axios
            .post("/api/analytic-tool", filterData)
            .then(res => {
                if(res.data.records.length>0){

                    this.setState({
                        update: true,
                        revenue: res.data.records[0].revenue,
                        purchase: res.data.records[0].purchase,
                        returns: res.data.records[0].returns,
                        ra_number: res.data.records[0].ra_number,
                        estimated_profit: res.data.records[0].estimated_profit,
                        total_rx: res.data.records[0].total_rx,
                        new_rx: res.data.records[0].new_rx,
                        refill_rx: res.data.records[0].refill_rx,
                    })
                }
            })
    }

    openDatepicker = (e) => {
        this.toDatePicker.setOpen(true);
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-report-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Pharmacy Business Analytics Tool</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onReportAdd} id="add-report">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label>Branded:{'  '}{this.props.brand_percent}%</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Generic:{'  '}{this.props.generic_percent}%</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>OTC:{'  '}{this.props.otc_percent}%</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label><Link to="/setting">Change Setting</Link></label>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker maxDate={moment().toDate()} selected={this.state.report_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setReportDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                            <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            <span className="text-danger">{errors.report_date}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="revenue">Revenue($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.revenue}
                                                error={errors.revenue}
                                                id="revenue"
                                                type="revenue"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.revenue
                                                })}
                                            />
                                            <span className="text-danger">{errors.revenue}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="purchase">Purchases($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.purchase}
                                                error={errors.purchase}
                                                id="purchase"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.purchase
                                                })}
                                            />
                                            <span className="text-danger">{errors.purchase}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="returns">Returns($)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.returns}
                                                id="returns"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.returns
                                                })}
                                            />
                                            <span className="text-danger">{errors.returns}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ra_number">RA Number</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.ra_number}
                                                id="ra_number"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.ra_number
                                                })}
                                            />
                                            <span className="text-danger">{errors.ra_number}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="ra_number">Estimated Gross Profit</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.estimated_profit ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(this.state.estimated_profit) : ""}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="new_rx">New RX</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.new_rx}
                                                id="new_rx"
                                                type="text"
                                                error={errors.new_rx}
                                                className={classnames("form-control", {
                                                    invalid: errors.new_rx
                                                })} />
                                            <span className="text-danger">{errors.new_rx}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="refill_rx">Refill RX </label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.refill_rx}
                                                id="refill_rx"
                                                type="text"
                                                error={errors.rx_bin}
                                                className={classnames("form-control", {
                                                    invalid: errors.refill_rx
                                                })} />
                                            <span className="text-danger">{errors.refill_rx}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="total_rx">Total Rx</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.total_rx}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-report"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(BusinessToolAddModal);
