import React, { Component, Fragment } from "react";
import ReactDatatable from '../../utils/ReactDatatable';
import dashboard from "../../utils/dashboard.png"
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import Chart from "react-google-charts";
import { Link } from "react-router-dom";
import moment from "moment"


class ReportsAhfs extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "ahfs_code",
                text: "Therapeutic Class",
                className: "ahfs_code",
                align: "left",
                sortable: true,
            },
            {
                key: "claims",
                text: "Claims",
                className: "claims",
                align: "left",
                sortable: true,
            },
            {
                key: "member_paid",
                text: "Member Paid($)",
                className: "member_paid",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "retail",
                text: "Retail Price($)",
                className: "retail",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "savings",
                text: "Savings %",
                className: "savings",
                align: "left",
                sortable: true,
            },
            {
                key: "saving_dollar",
                text: "Average Saving($)",
                className: "saving_dollar",
                align: "left",
                sortable: true,
                currency: true
            }

        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Therapeutic Class Report",
            no_data_text: 'No Record found!',
            button: {
                excel: false,
                print: false,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            recordsGraph: [],
            totalCount: 0,
            currentRecord: {
            },
            ahfs_code: undefined,
            loading: false,
            isFilterOpen: false,
            rx_group_associated: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = {}
        filterData.search = {
            ...(this.state.ahfs_code !== '' && { clia: this.state.ahfs_code }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated }),
        }
        axios
            .post("/api/ahfs-report", filterData)
            .then(res => {
                let result = []
                let recordsGraph = []
                let uniqueClass = []
                res.data.sort((a, b) => Number(b.claims) - Number(a.claims));
                let count = 0;
                recordsGraph.push(["ID", 'Claims', 'Member Paid', "Therapeutic Class", "Claims"])
                for (let i = 0; i < res.data.length; i++) {
                    let each = res.data[i]
                    if (uniqueClass[each.ahfs_code] === undefined) {
                        uniqueClass.push(each.ahfs_code)
                        each.retail = each.retail ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.retail) : ""
                        each.member_paid = each.member_paid ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.member_paid) : ""
                        each.savings = each.savings !== null ? each.savings.toFixed(2) + "%" : ""

                        each.saving_dollar = each.saving_dollar ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.saving_dollar) : ""
                        result.push(each)
                        if (i < 100) {
                            recordsGraph.push([
                                '',
                                each.claims,
                                Number(each.member_paid.replace(/[^0-9.-]+/g, "")),
                                each.ahfs_code,
                                each.claims,
                            ])

                            count++
                        }
                    }
                    this.setState({
                        records: result,
                        recordsGraph: recordsGraph,
                        totalCount: result.length,
                        loading: false
                    })
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    resetSearch = () => {
        this.setState({
            ahfs_code: undefined,
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }
    exportToCSV = () => {
        toast.success('Dowloading...Please Wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        var headers = {
            "claims": "Claims",
            "retail": "Retail Price($)",
            "member_paid": "Member Paid($)",
            "savings": "Savings(%)",
            "saving_dollar": "Average Saving($)",
            "ahfs_code": "Therapeutic Class",
            // "refill_rx": "Refill RX",
            // "total_rx": "Total RX",
            // "estimated_profit": "Estimated Gross Profit",
            // "brand_percent": "Brand",
            // "generic_percent": "Generic",
            // "otc_percent": "OTC",
            // "rx_group": "Rx Group"
        };
        var records = [];
        // add data rows in sheet array
        this.setState({ loading: false })
        for (let i in this.state.records) {
            let record = this.state.records[i]
            delete record._id; delete record.id; delete record.__v
            record.member_paid = '"' + record.member_paid + '"'
            record.retail = '"' + record.retail + '"'
            record.saving_dollar = '"' + record.saving_dollar + '"'
            record.ahfs_code = '"' + record.ahfs_code + '"'


            records.push(record);
        }
        if (headers) {
            records.unshift(headers);
        }
        // Convert Object to JSON
        // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö,Â';
        // var universalBOM = "\uFEFF";
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
        // let blob = new Blob([csv], { type: 'text/csv;charset=utf-8' +  encodeURIComponent(universalBOM+csvString) });
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'application/csv;charset=UTF-8' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        let associatedRecords = []
        for (let i = 0; i < this.props.groups.length; i++) {
            let each = this.props.groups[i]
            if (each.rx_group_associated !== undefined) {
                associatedRecords = [...associatedRecords, ...(each.rx_group_associated.split(","))]
            }
        }
        let { rx_group_associated } = this.state
        return (
            <div className="d-flex" id="wrapper">
                {(this.props.auth.user.bonaRx || this.props.auth.user.superadmin) && (
                    <>
                        {this.state.loading &&
                            <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                    </>
                )}
                <div id="page-content-wrapper">
                    {this.props.auth.user.superadmin || (this.props.auth.user.bonaRx && (this.props.auth.user.rx_group !== undefined && this.props.auth.user.rx_group !== "")) ? (
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Therapeutic Class Report</h1>
                            <div>
                                <div className="">
                                    <div className="form-row">
                                        {(this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined && this.props.auth.user.rx_group_associated !== "") ? (
                                            <div className="form-group col-md-3">
                                                <label>Group</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                    <option value="">All</option>
                                                    <option value={this.props.auth.user.rx_group} {...rx_group_associated === this.props.auth.user.rx_group && { "selected": true }}>{this.props.auth.user.name}--{this.props.auth.user.rx_group}</option>
                                                    {this.props.auth.user.rx_group_associated.split(",").map(each => {
                                                        let nameGroup = this.props.groups.find(i => i.rx_group === each);
                                                        return (
                                                            <option key={each} value={each} {...rx_group_associated === each && { "selected": true }}>{nameGroup ? nameGroup.name : ""}--{each}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>) : null}
                                        {this.props.auth.user.superadmin && this.props.groups.length ?
                                            (<div className="form-group col-md-3">
                                                <label>Group</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                    <option value="">All</option>
                                                    {this.props.groups.map(each => {
                                                        return (
                                                            <>{!associatedRecords.includes(each.rx_group) && each.rx_group !== "superadmin" && each.rx_group !== undefined && <option {...rx_group_associated === each.rx_group && { "selected": true }} key={each.rx_group} value={each.rx_group}>{each.name}-{each.rx_group}</option>}</>
                                                        )
                                                    })}
                                                </select>
                                            </div>) : null}
                                    </div>
                                    {((this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined) || (this.props.auth.user.superadmin && this.props.groups.length)) &&
                                        (<><button
                                            onClick={this.onSearch}
                                            type="button"
                                            className="btn btn-primary">
                                            Search
                                        </button>
                                            <button type="button" className="btn btn-secondary" onClick={this.reset}>Clear</button></>)
                                    }
                                </div>
                            </div>
                            <br></br>
                            {this.state.recordsGraph.length > 0 && (
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <h5>Top {this.state.totalCount > 100 ? "100" : ""} Therapeutic Class Graphical Representation</h5>
                                        <Chart
                                            chartType="BubbleChart"
                                            height={'1000px'}
                                            loader={<div>Loading Chart</div>}
                                            data={this.state.recordsGraph}
                                            options={{
                                                hAxis: { title: 'Claims' },
                                                vAxis: { title: 'Member Paid', format: "currency" },
                                                bubble: {
                                                    textStyle: {
                                                        fontSize: 8,
                                                        color: "transparent"
                                                    },
                                                },
                                                legend: 'none'
                                            }}
                                            rootProps={{ 'data-testid': '7' }}
                                            formatters={[
                                                {
                                                    type: 'NumberFormat',
                                                    column: 2,
                                                    options: {
                                                        prefix: '$',
                                                        negativeColor: 'red',
                                                        negativeParens: true,
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}

                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {(this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") && this.props.auth.user.termCondition == 1 ? (
                                        <h6 class="font-weight-bold text-danger">You have no data to display. Your dispensing data will automatically be displayed when your custom cards are used at any pharmacy nationwide</h6>

                                    ) : (this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") ? (
                                        <h6 class="font-weight-bold text-danger">To access all analytical tools you must register on BonaRx - FREE <br></br>
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#term-condition">Register here</button></h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="card-body">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records,
    groups: state.groups,
});

export default connect(
    mapStateToProps
)(ReportsAhfs);
