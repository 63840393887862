import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import isEmpty from "is-empty";
import "react-datepicker/dist/react-datepicker.css";
import bona from "../../utils/psga_new.png";
import doctor from "../../utils/doctor.jpg";
import queryString from "query-string";
import $ from "jquery";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import Footer from "../partials/Footer";
import TermRegister from "../partials/TermRegister";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import usState from "../../utils/state.json";
const softwares = [
  "AbacusRx",
  "AdvanceNet",
  "BestRx",
  "CarePoint",
  "Cerner",
  "ComputerRx",
  "DigitalRx",
  "DRx",
  "ePostRx",
  "Framework LTC",
  "FSI Foundation",
  "Integra",
  "Intelligent Pharmacy Software(IPS)",
  "Liberty",
  "Micromerchant(PrimeRx)",
  "PioneerRx",
  "QS1",
  "Rx30",
  "RxBlu",
  "RxDispense",
  "RxSystem",
  "ScriptPro",
  "SRS System",
  "SuiteRx-IPS",
  "Visual Superscript",
  "VIP Cloud PMS",
  "Others- Type Response",
];

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      rx_bin: "",
      rx_group: "",
      upline: "",
      assigned_date: "",
      first_name: "",
      last_name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      phone: "",
      fax: "",
      npi: "",
      software: "",
      psao: "",
      NABP: "",
      personal_email: "",
      phone1: "",
      addressline_2: "",
      dea: "",
      other_software: "",
      errors: {},
      loading: false,
      quickMembership: false,
      section: "register",
    };
  }

  componentDidMount() {
    window.location.href = "https://tabula.psgarx.com/";
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    if (queryString.parse(window.location.search).quickMembership) {
      this.setState({ quickMembership: 1 });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    let current = e.target.id;
    this.setState({ [current]: e.target.value }, () => this.checkNpi(current));
  };
  setFromDate = (date) => {
    this.setState({ assigned_date: date });
  };

  checkNpi = (currentKey) => {
    if (currentKey === "npi" && this.state.npi.length === 10) {
      this.setState({ loading: true });
      axios
        .post("/api/call-npi", {
          url: `https://npiregistry.cms.hhs.gov/api/?number=${this.state.npi}&version=2.1`,
          npi: this.state.npi,
        })
        .then((res) => {
          if (res.data.message === "exists") {
            confirmAlert({
              title: "Error!",
              message: `There is an account associated with this NPI. Please try to get access to your account using "Forgot Password" from the login page. Thanks`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.setState({ npi: "", loading: false });
                  },
                },
              ],
              overlayClassName: "confirm-plan",
            });
          } else {
            let data = JSON.parse(res.data.data);
            if (data.Errors && data.Errors.length) {
              let { errors } = this.state;
              errors.npi = data.Errors[0].description;
              this.setState({
                errors: errors,
                loading: false,
                first_name: "",
                street: "",
                last_name: "",
                city: "",
                phone1: "",
                fax: "",
                zip: "",
                state: "",
                name: "",
              });
            } else if (data.results.length === 0) {
              let { errors } = this.state;
              errors.npi = "NPI not found";
              this.setState({
                errors: errors,
                loading: false,
                first_name: "",
                street: "",
                last_name: "",
                city: "",
                phone1: "",
                fax: "",
                zip: "",
                state: "",
                name: "",
              });
            } else {
              let data1 = data.results[0];
              let { errors } = this.state;
              delete errors.npi;
              let obj = {
                first_name: data1.basic.first_name,
                last_name: data1.basic.last_name,
                name: data1.basic.organization_name,
                loading: false,
                errors,
              };

              if (data1.addresses.length) {
                obj.street = data1.addresses[0].address_1;
                obj.city = data1.addresses[0].city;
                obj.state = data1.addresses[0].state;
                obj.zip =
                  data1.addresses[0].postal_code &&
                  data1.addresses[0].postal_code.substring(0, 5);
                obj.phone1 =
                  data1.addresses[0].telephone_number &&
                  data1.addresses[0].telephone_number.replace(/-/g, "");
                obj.fax =
                  data1.addresses[0].fax_number &&
                  data1.addresses[0].fax_number.replace(/-/g, "");
              }
              this.setState(obj);
            }
          }
        });
    }
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.email === "") {
      errors.email = "This field is required";
    } else {
      delete errors.email;
    }
    if (this.state.npi === "") {
      errors.npi = "This field is required";
    } else if (this.state.npi.length !== 10) {
      errors.npi = "Invalid NPI (10 Digits)";
    } else {
      delete errors.npi;
    }
    if (this.state.password === "") {
      errors.password = "This field is required";
    } else {
      delete errors.password;
    }
    if (this.state.software === "") {
      errors.software = "This field is required";
    } else if (
      this.state.software === "Others- Type Response" &&
      this.state.other_software === ""
    ) {
      errors.other_software = "This field is required";
    } else {
      delete errors.software;
      delete errors.other_software;
    }
    if (this.state.dea === "") {
      errors.dea = "This field is required";
    } else {
      delete errors.dea;
    }
    if (this.state.first_name === "") {
      errors.first_name = "This field is required";
    } else {
      delete errors.first_name;
    }
    if (this.state.last_name === "") {
      errors.last_name = "This field is required";
    } else {
      delete errors.last_name;
    }
    if (this.state.phone1 === "") {
      errors.phone1 = "This field is required";
    } else {
      delete errors.phone1;
    }
    if (this.state.phone === "") {
      errors.phone = "This field is required";
    } else {
      delete errors.phone;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else if (this.state.zip.length !== 5) {
      errors.zip = "Invalid Zip (5 Digits)";
    } else {
      delete errors.zip;
    }
    if (this.state.NABP === "") {
      errors.NABP = "This field is required";
    } else if (this.state.NABP.length !== 7) {
      errors.NABP = "Invalid NABP (7 Digits)";
    } else {
      delete errors.NABP;
    }

    if (this.state.password2 === "") {
      errors.password2 = "This field is required";
    } else if (this.state.password !== this.state.password2) {
      errors.password2 = "Password not match";
    } else {
      delete errors.password2;
    }
    if (isEmpty(errors)) {
      $("#term-condition").modal("show");
    } else {
      this.setState({
        errors,
      });
    }
  };

  register = () => {
    this.setState({ loading: true });
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      rx_bin: this.state.rx_bin,
      upline: this.state.upline,
      assigned_date: moment().format(),
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      website: this.state.website,
      phone: this.state.phone,
      fax: this.state.fax,
      dea: this.state.dea,
      npi: this.state.npi,
      software:
        this.state.software === "Others- Type Response"
          ? this.state.other_software
          : this.state.software,
      psao: this.state.psao,
      NABP: this.state.NABP,
      phone1: this.state.phone1,
      personal_email: this.state.personal_email,
      addressline_2: this.state.addressline_2,
      quickMembership: this.state.quickMembership,
      login_flow: true,
    };
    this.props.registerUser({
      userData: newUser,
      history: this.props.history,
      hideLoading: this.hideLoading,
    });
  };

  hideLoading = () => {
    this.setState({ loading: true });
  };

  sendOtp = () => {
    this.setState({ loading: true, allowResend: false });
    const userData = {
      //   email: "mhariri@smartsrx.com",
      email: this.state.email,
      type: this.state.section,
    };
    axios({
      method: "POST",
      url: "/api/send-otp-register",
      data: userData,
    }).then((res) => {
      if (res.data.success) {
        this.setState({ section: "verifyOtp", loading: false });
        $("#otp").focus();
        setTimeout(() => {
          this.setState({ allowResend: true });
        }, 10000);
      } else {
        confirmAlert({
          title: "Error",
          message: res.data.message,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.setState({ otp: "" });
              },
            },
            {
              label: "Cancel",
              onClick: () => {},
            },
          ],
        });
        this.setState({ loading: false });
      }
    });
  };

  verifyOtp = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.otp === "") {
      errors.otp = "This field is required";
    } else {
      delete errors.otp;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const userData = {
        // email: "mhariri@smartsrx.com",
        email: this.state.email,
        otp: this.state.otp,
      };
      axios({
        method: "POST",
        url: "/api/verify-otp-register",
        data: userData,
      }).then((res) => {
        if (res.data.success) {
          this.setState({ loading: false });
          this.register();
        } else {
          this.setState({ loading: false });
          confirmAlert({
            title: "Error",
            message: res.data.message,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({ otp: "" });
                },
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        }
      });
    } else {
      this.setState({ errors, loading: false });
    }
  };

  render() {
    const { errors, allowResend } = this.state;
    const iFrame =
      '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="308dab4c-21ef-414b-a2ca-db0d7684fa3d";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div class="nam register" style={{ backgroundImage: `url(${doctor})` }}>
          <div className="container" style={{ height: "100%" }}>
            <div className="row mt-6">
              <div className="col-md-9 mx-auto mt-5 mb-5 card shadow-lg">
                {this.state.section === "register" ? (
                  <div className="card-body p-1">
                    {/* <Link to="/" className="btn-flat waves-effect">
                            <i className="material-icons left">keyboard_backspace</i> Back to
                            home
                        </Link> */}
                    {/* <div className="col s12" style={{ paddingLeft: "11.250px" }}> */}
                    <h2
                      className="text-left text-primary mt-3 "
                      style={{ marginBottom: "20px" }}
                    >
                      <a href="https://www.psgarx.com/">
                        <img src={bona} height={50} />
                      </a>
                      <iframe
                        style={{
                          height: "117px",
                          width: "140px",
                          marginTop: "20px",
                          float: "right",
                          borderWidth: 0,
                        }}
                        title={"badge"}
                        srcDoc={iFrame}
                      />
                    </h2>

                    <h2 className="text-left text-primary mt-3">
                      Business Detail
                    </h2>
                    <br></br>
                    <form noValidate onSubmit={this.onSubmit}>
                      {/* <div className="input-field col s12"> */}
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="npi">Pharmacy NPI</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.npi}
                            id="npi"
                            type="text"
                            error={errors.npi}
                            className={classnames("form-control", {
                              invalid: errors.npi,
                            })}
                          />
                          <span className="text-danger">{errors.npi}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="name">Pharmacy Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.name}
                            id="name"
                            type="text"
                            required
                            error={errors.name}
                            className={classnames("form-control", {
                              invalid: errors.name,
                            })}
                          />
                          <span className="text-danger">{errors.name}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="dea">Pharmacy DEA - AB1234567</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.dea}
                            id="dea"
                            type="text"
                            error={errors.dea}
                            maxLength={9}
                            className={classnames("form-control", {
                              invalid: errors.dea,
                            })}
                          />
                          <span className="text-danger">{errors.dea}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="NABP">NABP #</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.NABP}
                            id="NABP"
                            type="text"
                            maxLength={7}
                            error={errors.NABP}
                            className={classnames("form-control", {
                              invalid: errors.NABP,
                            })}
                          />
                          <span className="text-danger">{errors.NABP}</span>
                        </div>
                      </div>

                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="psao">PSAO</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.psao}
                            id="psao"
                            type="text"
                            error={errors.psao}
                            className={classnames("form-control", {
                              invalid: errors.psao,
                            })}
                          />
                          <span className="text-danger">{errors.psao}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="software">Pharmacy Software</label>
                          <select
                            name="software"
                            id="software"
                            onChange={this.onChange}
                            className="form-control"
                          >
                            <option>Please Select</option>
                            {softwares.map((each) => {
                              return (
                                <option
                                  key={each}
                                  value={each}
                                  selected={this.state.software === each}
                                >
                                  {each}
                                </option>
                              );
                            })}
                          </select>
                          <span className="text-danger">{errors.software}</span>
                        </div>
                        {this.state.software === "Others- Type Response" ? (
                          <>
                            <div className="col-md-6 col-12"></div>
                            <div className="col-md-6 col-12">
                              <label htmlFor="software">Software Name</label>
                              <input
                                onChange={this.onChange}
                                value={this.state.other_software}
                                id="other_software"
                                type="text"
                                error={errors.other_software}
                                className={classnames("form-control", {
                                  invalid: errors.other_software,
                                })}
                              />
                              <span className="text-danger">
                                {errors.other_software}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="street">Address Line 1</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.street}
                            id="street"
                            type="text"
                            error={errors.street}
                            className={classnames("form-control", {
                              invalid: errors.street,
                            })}
                          />
                          <span className="text-danger">{errors.street}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="addressline_2">Address Line 2</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.addressline_2}
                            id="addressline_2"
                            type="text"
                            error={errors.addressline_2}
                            className={classnames("form-control", {
                              invalid: errors.addressline_2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.addressline_2}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.city}
                            id="city"
                            type="text"
                            error={errors.city}
                            className={classnames("form-control", {
                              invalid: errors.city,
                            })}
                          />
                          <span className="text-danger">{errors.city}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="state">State</label>
                          <select
                            name="state"
                            onChange={this.onChange}
                            className="form-control"
                            id="state"
                          >
                            <option value="">Please Select</option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                          <span className="text-danger">{errors.state}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmsuperadminlFor="zip">Zip</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.zip}
                            id="zip"
                            type="text"
                            error={errors.zip}
                            className={classnames("form-control", {
                              invalid: errors.zip,
                            })}
                          />
                          <span className="text-danger">{errors.zip}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="website">Website</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.website}
                            id="website"
                            type="text"
                            error={errors.website}
                            className={classnames("form-control", {
                              invalid: errors.website,
                            })}
                          />
                          <span className="text-danger">{errors.website}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="phone1">Pharmacy Phone</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.phone1}
                            id="phone1"
                            type="text"
                            error={errors.phone1}
                            className={classnames("form-control", {
                              invalid: errors.phone1,
                            })}
                          />
                          <span className="text-danger">{errors.phone1}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="fax">Pharmacy Fax</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.fax}
                            id="fax"
                            type="text"
                            error={errors.fax}
                            className={classnames("form-control", {
                              invalid: errors.fax,
                            })}
                          />
                          <span className="text-danger">{errors.fax}</span>
                        </div>
                      </div>
                      <div className="row mt-12"></div>
                      <h2 className="text-left text-primary mt-3">
                        Personal Detail
                      </h2>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="first_name">First Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.first_name}
                            id="first_name"
                            type="text"
                            error={errors.first_name}
                            className={classnames("form-control", {
                              invalid: errors.first_name,
                            })}
                          />
                          <span className="text-danger">
                            {errors.first_name}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="last_name">Last Name</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.last_name}
                            id="last_name"
                            type="text"
                            error={errors.last_name}
                            className={classnames("form-control", {
                              invalid: errors.last_name,
                            })}
                          />
                          <span className="text-danger">
                            {errors.last_name}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="phone">
                            Cell Phone - required for OTP
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.phone}
                            id="phone"
                            type="text"
                            error={errors.phone}
                            className={classnames("form-control", {
                              invalid: errors.phone,
                            })}
                          />
                          <span className="text-danger">{errors.phone}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="email">
                            Pharmacy email - required for login
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="email"
                            required
                            className={classnames("form-control", {
                              invalid: errors.email,
                            })}
                          />
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="password">Password</label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type="password"
                            className={classnames("form-control", {
                              invalid: errors.password,
                            })}
                          />
                          <span className="text-danger">{errors.password}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="password2">Confirm Password</label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.password2}
                            id="password2"
                            type="password"
                            className={classnames("form-control", {
                              invalid: errors.password2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.password2}
                          </span>
                        </div>
                      </div>
                      <p className="text-center pb-0 mt-2">
                        <button
                          type="submit"
                          // data-toggle="modal" data-target="#term-condition"
                          className="btn btn-large btn-primary mt-2 px-5"
                        >
                          Register
                        </button>
                      </p>
                      <h5 className="text-center pb-0 mt-2">
                        Already have an account? <Link to="/login">Log in</Link>
                      </h5>

                      {/* </div> */}
                    </form>
                  </div>
                ) : (
                  <div className="card-body p-1">
                    <h2
                      className="text-center text-primary mt-2 "
                      style={{ marginBottom: "10px" }}
                    >
                      <a href="https://www.psgarx.com/">
                        <img src={bona} height={50} />
                      </a>
                    </h2>
                    <h3>OTP Verification</h3>
                    <h5>OTP send on your Pharmacy email address</h5>
                    <h6></h6>
                    <form
                      noValidate
                      onSubmit={this.verifyOtp}
                      className="white"
                    >
                      <label htmlFor="email">Enter OTP </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.otp}
                        error={errors.otp}
                        id="otp"
                        type="otp"
                        className={classnames("form-control", {
                          invalid: errors.otp,
                        })}
                      />
                      <span className="text-danger">{errors.otp}</span>
                      {allowResend && (
                        <a
                          href="#"
                          className="float-right"
                          onClick={this.sendOtp}
                        >
                          Resend OTP
                        </a>
                      )}
                      <br></br>
                      <p className="text-center pb-0 mt-2 mb-0">
                        <button
                          type="submit"
                          className="btn btn-large btn-primary mt-2 px-5"
                        >
                          Verify OTP
                        </button>
                      </p>
                    </form>
                  </div>
                )}
                <TermRegister sendOtp={this.register} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
